import React from 'react';
import { Button, Stack, TextField, Typography } from '@mui/material';

//  utils
import { useTranslation } from 'react-i18next';

//  validations
import * as yup from 'yup';
import { useForm,  Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

const inputProps = {
  disableUnderline: true,
  sx: { borderRadius: 2.5, backgroundColor: 'transparent !important', border: '1px solid', borderColor: 'light.gray8' }
};

export const NameWorkspace = ({ setTab, detailsRef }): JSX.Element => {
  const { t } = useTranslation();

  const schema = yup.object().shape({
    name: yup.string().required(),
  });

  const { control, handleSubmit, formState: { errors } } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  });

  const next = (payload: any) => {
    detailsRef.current.display_name = payload.name;
    setTab(5);
  }

  return (
    <Stack alignItems="center">
      <Typography fontSize={{md:14, xs:12, sm:13}} fontWeight={500} textAlign="center" sx={{ color: 'text.secondary', mb: {md:4, xs:2} }}>{t('LOGIN.WORKSPACE_NAME_SUBTITLE')}</Typography>
      <Controller name='name' control={control} render={({ field: { value, onChange, onBlur } }) => (
        <TextField label={t('LOGIN.ENTER_NAME')} type="name" name='name' variant="filled" InputProps={inputProps} fullWidth value={value || ''} onChange={onChange} onBlur={onBlur} error={Boolean(errors.name)} helperText={errors.name?.message} />
      )} />
      <Button onClick={handleSubmit(next)} disableElevation variant="contained" sx={{ mt: 2, px: 3.5, borderRadius: 2, fontSize: 15, fontWeight: 600 }}>{t('COMMON.CONTINUE')}</Button>
    </Stack>
  );
}