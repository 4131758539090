import { createSlice } from '@reduxjs/toolkit';
import { Dispatch } from 'redux';

const slice = createSlice({
    name: 'meeting',
    initialState: {
      meetingToken: '' as string,
      callerName: '' as string,
      callerImg: '' as string,
      callStatus: false as boolean,
      firebaseToken: "" as string
    },
    reducers: {
      setMeetingToken: (state, action) => {
        state.meetingToken = action.payload;
      },
      setCallerData: (state, action) => {
        state.callerName = action.payload.callerName;
        state.callerImg = action.payload.callerImg;
      },
      setCallStatus: (state, action) => {
        state.callStatus = action.payload;
      },
      setFireBaseToken: (state ,action) => {
        state.firebaseToken = action.payload;
      }
    },
  });

export default slice.reducer;

// Actions
const { setMeetingToken, setCallerData, setCallStatus, setFireBaseToken } = slice.actions;


export const updateMeetingToken = (token: string): any => (dispatch: Dispatch): void => {
  dispatch(setMeetingToken(token));
}

export const updateCallerData = (callerData: {callerName: string, callerImg: string}): any => (dispatch: Dispatch): void => {
  dispatch(setCallerData(callerData));
}

export const updateCalleState = (callStatus: boolean): any => (dispatch: Dispatch): void => {
  dispatch(setCallStatus(callStatus));
}

export const updateFireBaseToken = (FireBaseToken: string): any => (dispatch: Dispatch): void => {
  dispatch(setFireBaseToken(FireBaseToken));
}