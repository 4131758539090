import { gql } from "@apollo/client";
import message from '../fragments/message.fragment';
import contact from '../fragments/contact.fragment'


export const CHAT_HISTORY = gql`
  subscription GetChatHistoryByContact($business_id: uuid!, $contact_id: Int!, $limit: Int = 15, $offset: Int = 0) {
    core_message(
      where: {
        business_id : { _eq: $business_id },
        _and: {
          contact_id: { _eq: $contact_id }
        }
      },
      order_by: { id: desc },
      limit: $limit,
      offset: $offset
    ) {
      ...message
    }
  }
  ${message}
`;

export const CHAT_LIST_BY_TEAM = gql`
  subscription GetChatListByTeam(
    $business_id: uuid!,
    $team_id: Int!,
    $pinned_chats: Boolean = false,
    $search: String = "",
    $sort_order: order_by = desc,
    $limit: Int = 15,
    $offset: Int = 0
  ) {
    response: core_contact_agent(
      where: {
        business_id: {_eq: $business_id}, 
        _and: {
          team_id: {_eq: $team_id}
          _and: {
            contact: {
              contact_meta: {
                is_chat_pinned: {_eq: $pinned_chats}
              },
              contact_profiles: {
                display_name: {_iregex: $search}
              }
            }          
          }
        }
      }
      limit: $limit,
      offset: $offset,
      order_by: {last_assigned_timestamp: $sort_order}
    ) {
      chat: contact {
        ...contact
      }
    }
  }
  ${contact}
`;

export const CHAT_LIST_BY_MENTIONS = gql`
  subscription GetChatListByMention(  
    $agent_id: Int!,
    $pinned_chats: Boolean = false,
    $search: String = "",
    $sort_order: order_by = desc,
    $limit: Int = 15,
    $offset: Int = 0
  ) {
    response: core_message_mention(
      where: {            
        message: {
          contact: {
            contact_meta: {
              is_chat_pinned: {_eq: $pinned_chats}
            },
            contact_profiles: {
              display_name: {_iregex: $search}
            }
          }
        }
        contact_id: {_eq: $agent_id}
      }
      limit: $limit,
      offset: $offset,
      order_by: {created_at: $sort_order}
    ) {
      mentions_message: message {
        chat: contact {
          ...contact
        }
        
        mentioned_by_agent: agent {
          agent_id: id
          platform_id
          details: contact_profiles {
            name
          }
        }
        
        body
      }
    }
  }
  ${contact}
`;

export const CHAT_LIST_BY_STATUS = gql`
  subscription GetChatListByStatus(
    $business_id: uuid!,
    $status: status_conversation!,
    $pinned_chats: Boolean = false,
    $search: String = "",
    $sort_order: order_by = desc,
    $limit: Int = 15,
    $offset: Int = 0
  ) {
    chat: core_contact(
      where: {
        business_id: {_eq: $business_id},       
        is_agent: {
          _eq: false
        },
        contact_meta: {
          is_chat_pinned: {_eq: $pinned_chats}
        },
        contact_profiles: {
          display_name: {_iregex: $search}
        },
        chat_status: {_eq: $status} 
      }
      limit: $limit,
      offset: $offset,
      order_by: {created_at: $sort_order}
    ) {
      ...contact
    }
  }
  ${contact}
`;

export const CHAT_LIST_BY_AGENT = gql`
  subscription GetChatListByAgent(
    $business_id: uuid!,
    $agent_id: bigint!,
    $pinned_chats: Boolean = false,
    $search: String = "",
    $sort_order: order_by = desc,
    $limit: Int = 15,
    $offset: Int = 0
  ) {
    response: core_contact_agent(
      where: {
        business_id: {_eq: $business_id}, 
        _and: {
          agent_id: {_eq: $agent_id}
          _and: {
            contact: {
              contact_meta: {
                is_chat_pinned: {_eq: $pinned_chats}
              },
              contact_profiles: {
                display_name: {_iregex: $search}
              }
            }          
          }
        }
      }
      limit: $limit,
      offset: $offset,
      order_by: {last_assigned_timestamp: $sort_order}
    ) {
      chat_by_agent: contact {
        ...contact
      }
    }
  }
  ${contact}
`;

export const CHAT_LIST_ALL = gql`
  subscription GetChatListQueue(
    $business_id: uuid!,
    $pinned_chats: Boolean = false,
    $search: String = "",
    $sort_order: order_by = desc,
    $limit: Int = 15,
    $offset: Int = 0
  ) {
    chat: core_contact(
      where: {
        business_id: {_eq: $business_id},
        is_agent: {_eq: false},
        contact_meta: {
          is_chat_pinned: {_eq: $pinned_chats}
        },
        contact_profiles: {
          display_name: {_iregex: $search}
        }
      },
      limit: $limit, offset: $offset, order_by: {created_at: $sort_order}) 
      {
        ...contact    
      }  
  }
  ${contact}
`;

export const NEW_MESSAGE_GLOBAL_CONTEXT = gql`
  subscription GetLatestMessageGlobal($business_id: uuid!)
  {
    new_message: core_message(limit: 1,
      order_by: {created_at: desc},
      where: {business_id: {_eq: $business_id}
        _and: {direction: {_eq: "in"}}}) {
      message_id: id    
      type
      body
      channel
      message_media {      
        filename_url
      }
      contact {
        contact_id: id
        platform_id
        chat_status
        assignation {
          agent_id
          team_id
        }
        details: contact_profiles {
          name
          display_name
          image
        }
      }
    }
  }
`;

export const NEW_MESSAGE_AGENT_CONTEXT = gql`
  subscription GetLatestMessageByAgent($business_id: uuid!, $agent_id: bigint!)
  {
    new_message: core_message(limit: 1,
      order_by: {created_at: desc},
      where: {business_id: {_eq: $business_id}
        _and: {direction: {_eq: "in"}        
          contact_agent_relationship: {agent_id: {_eq: $agent_id}}
        }
      }) {
      message_id: id    
      type
      body
      channel
      message_media {      
        filename_url
      }
      contact {
        contact_id: id
        platform_id
        chat_status
        assignation {
          agent_id
          team_id
        }
        details: contact_profiles {
          name
          display_name
          image
        }
      }
    }
  }
`;

export const NEW_MESSAGE_TEAM_CONTEXT = gql`
  subscription GetLatestMessageByTeam($business_id: uuid!, $team_id: Int!)
  {
    new_message: core_message(limit: 1,
      order_by: {created_at: desc},
      where: {business_id: {_eq: $business_id}
        _and: {direction: {_eq: "in"}        
          contact_agent_relationship: {team_id: {_eq: $team_id}}
        }
      }) {
      message_id: id    
      type
      body
      channel
      message_media {      
        filename_url
      }
      contact {
        contact_id: id
        platform_id
        chat_status
        assignation {
          agent_id
          team_id
        }
        details: contact_profiles {
          name
          display_name
          image
        }
      }
    }
  }
`;

export const NEW_ASSIGNATION = gql`
  subscription GetLatestAssignation($agent_id: Int!)
  {
    new_message: core_message(
      limit: 1,
      order_by: {last_assigned_timestamp: desc},
      where: {
        agent_id: {_eq: $agent_id}
      }
    ) {
      agent_id
      id
      contact_id
      is_team
      team_id
      last_assigned_timestamp
      valid
      contact {
        contact_profiles {
          display_name
          email
        }
        platform_id
      }
    }
  }
`;