import React, { useState } from 'react';
import { ButtonBase, Stack, Typography, Snackbar } from '@mui/material';

// icons
import PersonIcon from '@mui/icons-material/Person';
import GroupsIcon from '@mui/icons-material/Groups';
import WebhookRoundedIcon from '@mui/icons-material/WebhookRounded';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';

//  components
import { SettingButton } from '../../components/Settings/SettingButton';

//  translations
import { useTranslation } from 'react-i18next';

//  redux
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

//  helpers
import { getAssetUrl } from '../../helpers/assets';
import { logout } from '../../helpers/auth';

export const SettingButtons = (): JSX.Element => {
    const { t } = useTranslation();

    const path = useSelector((state: RootState) => state.sdk.currentRoute.path);

    const isActive = (route: string): boolean => {
        return path === route;
    }

    const [showSnackbar, setShowSnackbar] = useState(false);

    const handleLogout = () => {
        setShowSnackbar(true);
        setTimeout(() => {
            logout();
        }, 1000);
    }


    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    }

    return (
        <Stack data-testid="setting-buttons-container" alignItems='flex-start' flex={1} px={2} py={3} maxWidth='220px' minWidth='220px' sx={{ borderInlineEnd: '1px solid', borderColor: 'light.gray3' }}>
            <Typography data-testid="workspace-label" color='secondary' variant='body2' fontWeight='600' sx={{ py: 1 }}>{t('SETTINGS.WORKSPACE_SETTINGS')}</Typography>
            <Stack data-testid="workspace-list" width='100%' gap={0.5}>
                <SettingButton route='/settings/workspace/account-profile' text={t('SETTINGS.WORKSPACE_PROFILE')} icon={<PersonIcon />} isActive={isActive('/settings/workspace/account-profile')} />
                <SettingButton route='/settings/workspace/users' text={t('SETTINGS.USERS')} icon={<GroupsIcon />} isActive={isActive('/settings/workspace/users')} />
                <SettingButton route='/settings/workspace/teams' text={t('SETTINGS.TEAMS')} icon={<img alt='teams' width={22} src={getAssetUrl(isActive('/settings/workspace/teams') ? 'teams-active.svg' : 'teams-inactive.svg')} />} isActive={isActive('/settings/workspace/teams')} />
            </Stack>
            <Typography data-testid="devtools-label" color='secondary' variant='body2' fontWeight='600' sx={{ py: 1 }}>{t('SETTINGS.DEVTOOLS')}</Typography>
            <Stack data-testid="devtools-list" width='100%' gap={0.5}>
                <SettingButton route='/settings/devtools/channels' text={t('SETTINGS.CHANNELS')} icon={<img alt='channels' width={24} src={getAssetUrl(isActive('/settings/devtools/channels') ? 'channels-active.svg' : 'channels-inactive.svg')} />} isActive={isActive('/settings/devtools/channels')} />
                <SettingButton route='/settings/devtools/webhooks' text={t('SETTINGS.WEBHOOKS_APIS')} icon={<WebhookRoundedIcon />} isActive={isActive('/settings/devtools/webhooks')} />
                <SettingButton route='/settings/devtools/message-logs' text={t('SETTINGS.MESSAGE_LOGS')} icon={<ImportExportIcon />} isActive={isActive('/settings/devtools/message-logs')} />
            </Stack>
            <Typography data-testid="account-label" color='secondary' variant='body2' fontWeight='600' sx={{ py: 1 }}>{t('SETTINGS.ACCOUNT_SETTINGS')}</Typography>
            <Stack data-testid="account-list" width='100%' gap={0.5}>
                <SettingButton route='/settings/account/profile' text={t('SETTINGS.PROFILE')} icon={<PersonIcon />} isActive={isActive('/settings/account/profile')} />
                <SettingButton route='/settings/account/notifications' text={t('SETTINGS.NOTIFICATIONS')} icon={<NotificationsRoundedIcon />} isActive={isActive('/settings/account/notifications')} />
                <ButtonBase onClick={handleLogout} data-testid="logout-button" sx={{ p: 1.5, alignItems:'center', justifyContent: 'flex-start', gap: 1, color: 'error.main' }}>
                    <LogoutRoundedIcon />
                    <Typography variant='body1' fontWeight='500'>{t('SETTINGS.LOGOUT')}</Typography>
                </ButtonBase>
            </Stack>
            <Snackbar open={showSnackbar} message={t('SETTINGS.LOGGING_OUT')} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />
        </Stack>
    );
}