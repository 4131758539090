import { gql } from "@apollo/client";

const GET_CONTACTS = gql`
query GetContacts($filter: core_contact_bool_exp!, $offset: Int!, $limit: Int!) {
  core_contact(
    where: $filter,
    offset: $offset,
    limit: $limit,
    order_by: { updated_at: desc }
  ) {
    is_agent
    is_archived
    id
    platform_id
    platform
    chat_status
    created_at
    updated_at
    assignation {
      agent: contact {
        agent_id: id
        contact_profiles {
          name
        }        
      }
      team {
        id
        name
      }
    }
    contact_meta {
      last_message_at
    }
    contact_profiles {
      display_name
      address
      email
      country
      image
      language
    }
    tags_contacts {
      tag {
        id
        tag
        colour
      }
    }
  }

  core_contact_aggregate(
    where: $filter
  ) {
    aggregate {
      count
    }
  }
}`;

const GET_CONTACTS_BASIC_LENGTH = gql`
query GetContactsBasicLength($business_id: uuid!) {
  core_contact_aggregate( where: {
    business_id: { _eq: $business_id },
    is_agent: { _eq: false },
    chat_status: { _neq: "closed" },
  }) {
    aggregate {
      count
    }
  }
}
`;

const CREATE_CONTACT = gql`
mutation CreateContact(
    $platform_id: String!,
    $platform: type_platform!,
    $business_id: uuid!,
    $image_url: String,
    $profile_name: String,
    $profile_display_name: String,
    $profile_email: String
  ) {
    insert_core_contact_one(
      object: {
        platform_id: $platform_id,
        platform: $platform,
        business_id: $business_id,
         contact_meta: {
          data: {
            business_id: $business_id
          }
        },
        contact_profiles: {
          data:{
            name: $profile_name,
            display_name: $profile_display_name,
            email: $profile_email,   
            image: $image_url,
            business_id: $business_id
          }
        }
      }
    ) {
      id
      is_agent
      is_archived
      platform_id
      platform
      chat_status
      created_at
      updated_at
      contact_meta {
        last_message_at
      }
      contact_profiles {
        display_name
        address
        email
        country
        image
        language
      }
      tags_contacts {
        tag {
          id
          tag
          colour
        }
      }
    }
    
    insert_core_contact_business_one(
      object: {
        platform_id: $platform_id,
        platform: $platform,
        business_id: $business_id
      }
    ) {
      id
    }
  }`;

const UPDATE_CONTACT = gql`
mutation UpdateContact(
    $contact_id: Int!,
    $contact_data: core_contact_set_input!,
    $contact_profile_data: core_contact_profile_set_input!
  ) {
    update_core_contact_by_pk(
      pk_columns: { id: $contact_id },
      _set: $contact_data
    ) {
      id
      updated_at
      is_agent
      is_archived
      platform_id
      platform
      chat_status
      created_at
      updated_at
      contact_meta {
        last_message_at
      }
      contact_profiles {
        display_name
        address
        email
        country
        image
        language
      }
      tags_contacts {
        tag {
          id
          tag
          colour
        }
      }
    }

    update_core_contact_profile(
      where: {
        contact_id: { _eq: $contact_id }
      },
      _set: $contact_profile_data
    ) {
      returning {
        id
      }
      affected_rows
    }
  }`;

const ATTACH_TAGS_TO_CONTACT = gql`
mutation AttachTagsToContact($contact_id: Int!, $tags_data: [apps_tags_contacts_insert_input!]!) {
  delete_apps_tags_contacts(where: {contact_id: {_eq: $contact_id}}) {
    affected_rows
  }
  
  insert_apps_tags_contacts(objects: $tags_data, on_conflict: {constraint: tags_contacts_pkey, update_columns: id}) {
    affected_rows
    returning {
      tag {
        id
        tag
        colour
      }
    }
  }
}
`;
 
const GET_TAGS = gql`
query GetTags($business_id: uuid!, $search: String = "") {
  apps_tag(
    where: {
      business_id: { _eq: $business_id },
      tag: { _iregex: $search }
    },
    order_by: { id: desc }
  ) {
    id
    tag
    colour
    description
  }
  apps_tags_contacts(where: {tag: {business_id: {_eq: $business_id}}}, distinct_on: tags_id) {
    tags_id
    tag {
      tags_contacts_aggregate {
        aggregate {
          count
        }
      }
    }
  }
}`;

const GET_CONTACT_IMPORT_ACTIVITIES = gql`
query GetContactImportActivities($business_id: uuid = "", $offset: Int = 0, $limit: Int = 10) {
  core_contact_import(
    where: {
      business_id: { _eq: $business_id }
    },
    order_by: { created_at: desc },
    offset: $offset,
    limit: $limit
  ) {
    id
    created_at
    updated_at
    total_contacts_count: no_of_rows    
    failed_contacts_count: no_of_row_error
    warning_contacts_count: no_of_row_warning
    processed_contacts_count: no_of_row_processed
    task {
      status
      success      
    }
    media_asset {
      file_name      
      created_by: contact {
        agent: contact_profiles {
          name
        }
      }
    }
  }

  core_contact_import_aggregate(
    where: {
      business_id: { _eq: $business_id }
    }
  ) {
    aggregate {
      count
    }
  }
}`;

const GET_CONTACT_EXPORT_ACTIVITIES = gql`
query GetContactExportActivities($business_id: uuid!, $offset: Int = 0, $limit: Int = 10) {
  core_task(
    where: {
      business_id: { _eq: $business_id },
      purpose: { _eq: "export_contacts" }
    },
    order_by: { created_at: desc },
    offset: $offset,
    limit: $limit
  ) {        
    meta_data
    created_by: contact {
      agent: contact_profiles {
        name
      }
    }
    created_at  
    status
    success    
    error_message
  }

  core_task_aggregate(
    where: {
      business_id: { _eq: $business_id },
      purpose: { _eq: "export_contacts" }
    }
  ) {
    aggregate {
      count
    }
  }
}`;

const GET_CONTACT_IMPORT_REPORT = gql`
query GetContactImportReport($activity_id: bigint!) {
  report: core_contact_import_by_pk(id: $activity_id) {
    total_contacts: no_of_rows    
    failed_contacts: no_of_row_error
    warning_contacts: no_of_row_warning
    processed_contacts: no_of_row_processed
    failed_contacts_list: row_error(path: "error")
    warning_contacts_list: row_warning(path: "warning")
  }
}`;

export { GET_CONTACTS, GET_CONTACTS_BASIC_LENGTH, CREATE_CONTACT, UPDATE_CONTACT, ATTACH_TAGS_TO_CONTACT, GET_TAGS, GET_CONTACT_IMPORT_ACTIVITIES, GET_CONTACT_EXPORT_ACTIVITIES, GET_CONTACT_IMPORT_REPORT };