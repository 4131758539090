import { createSlice } from '@reduxjs/toolkit';
import { Dispatch } from 'redux';

// Slice
const slice = createSlice({
    name: 'analytics',
    initialState: {
        duration: ''
    },
    reducers: {
        setDuration: (state, action) => {
            state.duration = action.payload.duration;
        },
    },
});

export default slice.reducer;

const {setDuration} = slice.actions

export const changeDuration = (duration:string):any => (dispatch:Dispatch):void => {
    dispatch(setDuration({duration}))
}