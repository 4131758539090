import React, { useEffect, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Stack } from '@mui/material';

import { DyteProvider, useDyteClient } from '@dytesdk/react-web-core';
import { ResizablePIP } from "resizable-pip";

//  components
import { LeftSideNav } from '../../layouts/ChatsPage/LeftSideSideNav';
import { LazySearch } from '../../layouts/SearchPage/LazySearch';
import { BottomNavbar } from '../../layouts/ChatsPage/BottomNavbar';
import { UpperNavMoblie } from '../../layouts/ChatsPage/UpperNavMobile';
import { NewContentAvailable } from '../../components/sdk/NewContentAvailable';
import { NewMsgNotification } from '../../components/ChatComponents/NewMsgNotification';
import { AudioRoom } from '../../layouts/Meeting/AudioRoom';
import { IncomingCallPopup } from '../../layouts/Meeting/IncomingCallPopup';

//  redux
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { AccountBlocked } from './AccountBlocked';

export const MainRoutesWrapper = ({ routes }): JSX.Element => {
  const isMobile = useMediaQuery({ maxWidth: 786 });
  
  const mode = useSelector((state: RootState) => state.sdk.mode);
  const meetingToken = useSelector((state: RootState) => state.meeting.meetingToken);
  const callStatus = useSelector((state: RootState) => state.meeting.callStatus);
  const accountBlocked = useSelector((state: RootState) => state.billing.accountBlocked);
  const authenticated = useSelector((state: RootState) => state.sdk.authenticated);

  const [meeting, initMeeting] = useDyteClient();

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!meetingToken) {
      return;
    } else if (meetingToken && callStatus) {
      console.log('true? ', meetingToken && callStatus);

      initMeeting({
        authToken: meetingToken,
        defaults: {
          audio: false,
          video: false,
        },
      });
    }
  }, [meetingToken, callStatus]);

  return (
    <Stack sx={{ maxHeight: '100%' }}>
      { isMobile && <UpperNavMoblie /> }
      <Stack flexDirection="row" width="100%" height={`calc(100vh - ${isMobile ? 113 : 0}px)`} maxHeight='100%' sx={{ backgroundColor: 'light.main' }}>
        { !isMobile && mode === 'host' && <LeftSideNav />}
        <Stack sx={{ position: 'relative', flex: 1, zIndex:'100', maxWidth: (isMobile || mode === 'sdk') ? '100%' : 'calc(100% - 72px)', overflowY: mode === 'host' ? 'auto' : '' }}>
          <LazySearch />
          { (meetingToken && callStatus) &&
            <ResizablePIP height={349} width={840}>
              <DyteProvider value={meeting} fallback={<></>}>
                <Stack data-testid='meeting-container' ref={containerRef} flexDirection={isMobile ? 'column' : 'row'} sx={{ overflowY: 'auto', height: '100%', width: "100%", alignItems: 'center', justifyContent: 'center' }} >
                  <Stack data-testid='meeting' pb={{ xs: 0, md: 1 }} pt={isMobile ? 1 : 3} minWidth={isMobile ? 'auto' : '730px'} sx={{ overflowY: 'auto', boxSizing: 'border-box', m: '10px', borderRadius: 2.5, height: `calc(100% - 20px)`, minHeight: `calc(100% - 20px)`, width: isMobile ? 'calc(100% - 20px)' : '60%' }}>
                    <AudioRoom />
                  </Stack>
                </Stack>
              </DyteProvider>
            </ResizablePIP >
          }
          { accountBlocked && authenticated && <AccountBlocked /> }
          { authenticated ? routes : <></> }
        </Stack>
      </Stack>
      { isMobile && <BottomNavbar /> }
      <NewContentAvailable />
      <NewMsgNotification />
      <IncomingCallPopup />
    </Stack>
  )
}
