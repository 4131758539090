import { createSlice } from '@reduxjs/toolkit';
import { Dispatch } from 'redux';

const slice = createSlice({
    name: 'customAttributes',
    initialState: {
      showCreatePopup: false,
      editCustomAttribute: null as any,
    },
    reducers: {
      popupStatusChanged: (state, action) => {
        state.showCreatePopup = action.payload;
      },
      editCustomAttributeChanged: (state, action) => {
        state.editCustomAttribute = action.payload;
      },
    },
  });

export default slice.reducer;

// Actions
const { popupStatusChanged, editCustomAttributeChanged } = slice.actions;


export const changePopupStatus = (status: boolean): any => (dispatch: Dispatch): void => {
  dispatch(popupStatusChanged(status));
}

export const setEditCustomAttribute = (data: any): any => (dispatch: Dispatch): void => {
  dispatch(editCustomAttributeChanged(data));
}
