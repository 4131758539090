import React from 'react';
import { ButtonBase, Drawer, IconButton, Stack, Typography } from '@mui/material';

//icons 
import WebAssetIcon from '@mui/icons-material/WebAsset';
import PersonIcon from '@mui/icons-material/Person';
import GroupsIcon from '@mui/icons-material/Groups';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';

//  components
import { SettingButton } from '../../components/Settings/SettingButton';

//  utils
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

//  redux
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

//  helpers
import { getAssetUrl } from '../../helpers/assets';
import { logout } from '../../helpers/auth';

export const SettingsButtonDrawer = ({ show, toggleShow }): JSX.Element => {
  const { t } = useTranslation();

  const path = useSelector((state: RootState) => state.sdk.currentRoute.path);

  const isActive = (route: string): boolean => path === route;

  return (
    <Drawer
      anchor={i18next.language === 'ar' ? 'right' : 'left'}
      open={show}
      onClose={toggleShow}
      sx={{ zIndex: 100, '.MuiPaper-root': { boxShadow: 6 }, '.MuiBackdrop-root': { backgroundColor: 'light.gray14' } }}
    >
      <Stack role="presentation" sx={{ width: 260, p: 1.5, gap: 1, pt: 9 }}>
        <Stack flexDirection="row" alignItems="flex-start" justifyContent="space-between" gap={1}>
          <SettingButton onClick={toggleShow} style={{ p: 1 }} route='/settings/workspace/account-profile' text={t('SETTINGS.WORKSPACE_PROFILE')} icon={<PersonIcon />} isActive={isActive('/settings/workspace/account-profile')} />
          <IconButton sx={{ color: 'primary.main', transform: 'rotate(-90deg)' }} onClick={toggleShow}>
            <WebAssetIcon data-testid='web-asst-icon'/>
          </IconButton>
        </Stack>
        <SettingButton onClick={toggleShow} style={{ p: 1 }} route='/settings/workspace/users' text={t('SETTINGS.USERS')} icon={<GroupsIcon />} isActive={isActive('/settings/workspace/users')}/>
        <SettingButton onClick={toggleShow} style={{ p: 1 }} route='/settings/workspace/teams' text={t('SETTINGS.TEAMS')} icon={<img width={22} src={getAssetUrl(isActive('/settings/workspace/teams') ? 'teams-active.svg' : 'teams-inactive.svg')} />} isActive={isActive('/settings/workspace/teams')} />
        <ButtonBase onClick={() => logout()} data-testid="logout-button" sx={{ p: 1, alignItems:'center', justifyContent: 'flex-start', gap: 1, color: 'error.main' }}>
          <LogoutRoundedIcon />
          <Typography fontWeight='500' fontSize="14px">{t('SETTINGS.LOGOUT')}</Typography>
        </ButtonBase>
      </Stack>
    </Drawer>
  );
}
