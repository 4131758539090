import React, { useEffect, useState } from 'react';
import { Snackbar, IconButton } from '@mui/material';

//  icons
import CloseIcon from '@mui/icons-material/Close';

//  utils
import { useTranslation } from 'react-i18next';

//  helpers
import { isTokenExpired } from '../../helpers/auth';
import { subscribe } from '../../helpers/events';

export const NotAuthNotification = () => {
  const { t } = useTranslation();
  
  const [message, setMessage] = useState('');

  const handleClose = (_: any, reason?: string) => {
    if (reason === 'clickaway') return;

    setMessage('');
  }

  useEffect(() => {
    const token = localStorage.getItem('avc-token') as string;
    if ((!token || isTokenExpired(token.split(' ')[1])) && !window.location.pathname.startsWith('/login')) {
      setMessage(t('LOGIN.UNAUTHORIZED_MESSAGE').toString());
    }

    subscribe('SESSION_TIMED_OUT', () => setMessage(t('LOGIN.SESSION_TIMED_OUT').toString()))
  }, []);
  
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={message !== ''}
      onClose={handleClose}
      autoHideDuration={3000}
      message={message}
      action={
        <IconButton
          size="small"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      }
    />
  );
}
