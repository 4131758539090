import React from 'react';
import { Route, Routes } from 'react-router-dom';

//  components
import { Lazy } from '../../components/Common/Lazy';

//  pages
const Channels = React.lazy(() => import('../../layouts/Settings/DevTools/Channels'));
const MessageLogs = React.lazy(() => import('../../layouts/Settings/DevTools/MessageLogs'));
const Webhooks = React.lazy(() => import('../../layouts/Settings/DevTools/Webhooks_APIs'));
const Error = React.lazy(() => import('../../pages/Error'));

export const DevToolsSettings = (): JSX.Element => {    
    return (
        <Routes>
            <Route path='/channels' element={<Lazy component={<Channels />} />} />
            <Route path='/webhooks' element={<Lazy component={<Webhooks />} />} />
            <Route path='/message-logs' element={<Lazy component={<MessageLogs />} />} />
            <Route path="*" element={<Lazy component={<Error type={404} />} />} />
        </Routes>
    );
}