import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

//  components
import { BackButton } from '../../components/Common/BackButton';

//  models
import { HeaderProps } from '../../models/Common';
import { Box } from '@mui/material';

export const Header = ({ hideBackButton, title, description, actions, style, confirmBack, customBtns }: HeaderProps): JSX.Element => {
  const isMobile = useMediaQuery({ maxWidth: 786 });

  return (
    <Box component="header" data-testid="page-header" sx={{ display: 'flex', alignItems: 'center', borderBottom: '1px solid', borderBottomColor: 'light.gray3', padding: (isMobile ? '16px' : '24px') }} style={style}>
      { !hideBackButton && <BackButton confirmBack={confirmBack} /> }
      { customBtns }
      <Stack data-testid="title-description-container" sx={{ marginInlineEnd: 'auto' }}>
        <Typography data-testid="title" fontSize={{ xs: 19, sm: 21, md: 22 }} fontWeight='bold'>{title}</Typography>
        <Typography data-testid="description" color='text.secondary' fontSize={{ xs: 11, sm: 12, md: 14 }}>{description}</Typography>
      </Stack>
      { actions }
    </Box>
  );
}
