import { ApolloClient, ApolloLink, InMemoryCache, split } from "@apollo/client";
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition, Observable } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { logout } from "../helpers/auth";
import environment from "../environment";

const graphqlURI = environment.REACT_APP_HASURA_ENDPOINT as string + '/graphql';

// Define a custom link for response interception
const responseInterceptorLink = new ApolloLink((operation, forward) => {
  return new Observable(observer => {
    forward(operation).subscribe({
      next: response => {
        // Here, you can intercept and modify the response if needed
        // For example, you can log the response or make changes to the data
        if (response.errors?.[0].extensions.code === 'invalid-jwt') {
          logout('SESSION_TIMED_OUT');
        }
        // Then pass the response along to the next link
        observer.next(response);
      },
      error: error => {
        // Handle errors if necessary
        if (error.message.startsWith('server returned results with length 1, expected length of ')) {
          logout('SESSION_TIMED_OUT');
        }
        console.log(error);
        
        observer.error(error);
      },
      complete: () => {
        observer.complete();
      }
    });
  });
});

const cache = new InMemoryCache();

export const client_factory = () => {
  const headers = {
    'x-hasura-role': 'admin',
    authorization: localStorage.getItem('avc-token') as string,
  }
  
  //  for subscriptions
  const wsLink = new GraphQLWsLink(createClient({
    url: graphqlURI.replace('https://', 'wss://'),
    connectionParams: {
      headers
    },
  }));

  // for batch queries
  const batchHttpLink = new BatchHttpLink({
    uri: graphqlURI,
    batchMax: 5,
    batchInterval: 30,
    batchDebounce: true,
    headers,
    credentials: 'include',
  });

  //  split based on operation type (subscription, query, mutation)
  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    batchHttpLink,
  );

  const finalLink = ApolloLink.from([responseInterceptorLink, splitLink]);
  
  return new ApolloClient({
    link: finalLink,
    cache
  });
}