import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "../App";

// redux
import { Provider } from "react-redux";
import store from "../redux/store";

// graphql
import { NEW_ASSIGNATION, NEW_MESSAGE_GLOBAL_CONTEXT } from "../graphql/subscriptions/chats";

// models
import { client_factory } from "../graphql/client";
import Chat from "./chat";
import { logout } from "../helpers/auth";
import { AvcAuth, InitOptions, LoginOpts, SDKEvent, SdkProperties, Theme } from "../models/sdk";
import { publish } from "../helpers/events";
import { setAuthenticated, setCurrentRoute, setTheme } from "../redux/sdk";
import { changeInsideChat, changeOutsideChat, changeSoundNotification } from "../redux/settings";

declare global {
  interface Window {
    business_id: string;
    contact_id: number;
    sdk: SDK;
  }
}

/**
 * SDK class for Avocado Core messageing app
 */
class SDK {
  private isInitialized: boolean = false;
  private isLoggedin: boolean = false;
  private isReady: boolean = false;
  private isDestroyed: boolean = false;
  private isMobile: boolean = false;
  private host_id: string;
  private locale: 'ar' | 'en';
  private theme: Theme;
  private modules_enabled: string[];

  private chat: Chat | null = null;

  /**
   * Constructor for SDK class takes public key as parameter
   * @param publicKey
   * @returns
   */
  constructor() {
    console.log('SDK initialized');
  }

  /**
   * Initializes the SDK with the provided configuration.
   * @param config 
   */
  init(options: InitOptions): void {
    
    this.authorize();

    this.chat = new Chat();
    
    if (options.onReady) this.onReady(options.onReady);
    if (options.onError) this.onError(options.onError);
    this.host_id = options.host_id;
    this.locale = options.locale || 'en';
    if (options.theme) {
      this.theme = options.theme;
      store.dispatch(setTheme(this.theme));
    }
    this.isMobile = options.isMobile || false;

    this.isInitialized = true;
  }

  authorize(): void {
    window.sdk = this;
    
    const business_id = localStorage.getItem('avc-business_id');
    const contact_id = localStorage.getItem('avc-user_id');

    if (!business_id) return;
    
    window.business_id = business_id;
    window.contact_id = Number(contact_id);

    const apolloClient = client_factory();
    
    apolloClient.subscribe({
      query: NEW_MESSAGE_GLOBAL_CONTEXT,
      variables: { business_id },
    }).subscribe({
      next: (result) => {
        this.onNewMessage?.(result.data);
      },
      error: (error) => {
        this.errorCallback?.(error);
      },
    });

    apolloClient.subscribe({
      query: NEW_ASSIGNATION,
      variables: { agent_id: contact_id },
    }).subscribe({
      next: (result) => {
        this.onChatAssigned?.(result.data);
      },
      error: (error) => {
        this.errorCallback?.(error);
      },
    });
  }

  /**
   * The function logs in a user with a provided token if the user is authenticated.
   * @param {AvcAuth} avcAuth
   * @param {LoginOpts} opts
   */
  login(avcAuth: AvcAuth, opts: LoginOpts) {
    localStorage.setItem('avc-token', avcAuth.headers.Authorization);
    localStorage.setItem('avc-business_id', avcAuth.headers["x-hasura-business-id"]);
    localStorage.setItem('avc-user_id', avcAuth.headers["x-hasura-user-id"].toString());
    localStorage.setItem('avc-user_email', avcAuth.user_details.email);
    localStorage.setItem('avc-user_details', JSON.stringify(avcAuth.user_details));

    this.authorize();
    publish('LOGIN', {});
    store.dispatch(setAuthenticated(true));

    this.loginCallback?.(avcAuth);
    this.isLoggedin = true;
    this.modules_enabled = avcAuth.headers["x-hasura-allowed-roles"];

    // const home = window.screen.width <= 786 ? '/chat' : '/dashboard/overview';
    const home = '/chat';
    store.dispatch(setCurrentRoute(home));
    
    store.dispatch(changeSoundNotification(opts.soundNotification, 1));
    store.dispatch(changeSoundNotification(opts.desktopNotification, 2));
    store.dispatch(changeOutsideChat(opts.newMessageNotificationStrategy.outsideChatScreen.toUpperCase()));
    store.dispatch(changeInsideChat(opts.newMessageNotificationStrategy.insideChatScreen.toUpperCase()));
  }

  /**
   * Clears the user session from the SDK
   */
  logout(): void {
    logout();
    this.isLoggedin = false;
  }
  
  /**
   * Terminates the SDK's operations
   */
  destroy(): void {
    logout();
    this.isDestroyed = true;
    this.isLoggedin = false;
  }

  get(property: SdkProperties): any {
    switch (property) {
      case SdkProperties.user:
        return JSON.parse(localStorage.getItem('avc-user_details') as string);
      case SdkProperties.token:
        return JSON.parse(localStorage.getItem('avc-token') as string);
      case SdkProperties.host_id:
        return this.host_id;
      case SdkProperties.locale:
        return this.locale;
      case SdkProperties.theme:
        return this.theme;
      case SdkProperties.modules_enabled:
        return this.modules_enabled;
      case SdkProperties.isInitialized:
        return this.isInitialized;
      case SdkProperties.isLoggedin:
        return this.isLoggedin;
      case SdkProperties.isReady:
        return this.isReady;
      case SdkProperties.isDestroyed:
        return this.isDestroyed;
      case SdkProperties.isMobile:
        return this.isMobile;
      default:
        break;
    }
  }

  set(property: SdkProperties, value: any): any {
    switch (property) {
      case SdkProperties.locale:
        this.locale = value;
        break;
      case SdkProperties.theme:
        this.theme = value;
        store.dispatch(setTheme(this.theme));
        break;
      case SdkProperties.isMobile:
        this.isMobile = value;
        break;
      default:
        break;
    }
  }

  /**
   * This function attaches a React app to a specified HTML element if the user is authenticated.
   * @param {string} element - A string representing the id of the HTML element to which the React
   * application will be attached.
   * @returns If the user is not authenticated or if the element with the specified ID is not found, the
   * function returns nothing (undefined). Otherwise, it renders the React application using
   * ReactDOM.render().
   */
  attachToElement(element: string): void {
    const appContainer = document.getElementById(element);
    if (!appContainer) {
      console.error(`Element with id '${element}' not found.`);
      return;
    }
    ReactDOM.render(
      <Provider store={store}>
        <BrowserRouter>
          <App currentScreen="/dashboard/overview" mode="sdk" sdk={this} />
        </BrowserRouter>
      </Provider>,
      appContainer
    );
  }
  
  // Loads a specific page in the app: chats, contacts, apps, etc.
  loadPage(pageName: string): void {
    store.dispatch(setCurrentRoute(pageName));
  }

  //////////////////////
  // Realtime Callbacks
  //////////////////////

  on(event: SDKEvent, callback: (data: any) => void) {
    switch (event) {
      case SDKEvent.NewMessage:
        this.onNewMessage = callback;
        break;
      case SDKEvent.ChatAssigned:
        this.onChatAssigned = callback;
        break;
      case SDKEvent.ChatSelected:
        this.onChatSelected = callback;
        break;
      default:
        break;
    }
  }

  off(event: SDKEvent) {
    switch (event) {
      case SDKEvent.NewMessage:
        this.onNewMessage = null;
        break;
      case SDKEvent.ChatAssigned:
        this.onChatAssigned = null;
        break;
      case SDKEvent.ChatSelected:
        this.onChatSelected = null;
        break;
      default:
        break;
    }
  }
 
  onReady(callback: (modules_enabled: string[]) => void): void {
    this.readyCallback = callback;
  }

  onError(callback: (error: Error) => void): void {
    this.errorCallback = callback;
    window.onerror = (e: string | Event) => {
      callback(Error(e.toString()))
    }
  }

  onLogin(callback: (avcAuth: AvcAuth) => void): void {
    this.loginCallback = callback;
  }

  onLogout(callback: () => void): void {
    this.logoutCallback = callback;
  }

  /**
   * Callback function when new message is received
   * @param message - received message object
   */
  onNewMessage: ((message: any) => void) | null = null;

  /**
   * Callback function when a customer is selected
   * @param chat - selected chat object
   */
  onChatSelected: ((chat: any) => void) | null = null

  /**
   * Callback function when there is a new mention
   * @param mention - mention object
   */
  onMention: ((mention: any) => void) | null = null

  /**
   * Callback function when chat is assigned to an agent
   * @param assignation - assignation object
   */
  onChatAssigned: ((assignation: any) => void) | null = null;

  /**
   * Callback function when chat is closed
   * @param chat - chat object
   */
  onChatClosed: ((chat: any) => void) | null = null;

  /**
   * Callback function when message isn't sent successfully
   * @param error - error object
   */
  onMsgSendFailure: ((error: any) => void) | null = null;

  /**
   * Callback function when template is approved
   * @param template - template object
   */
  onTemplateApproved: ((template: any) => void) | null = null;

  /**
   * Callback function when template is rejected
   * @param template - template object
   * @param reason - reason object
   */
  onTemplateRejected: ((template: any, reason: any) => void) | null = null;

  /**
   * Callback function when there is a new broadcast
   * @param broadcast - broadcast object
   */
  broadcastTriggered: ((broadcast: any) => void) | null = null;

  /**
   * Callback function when the user logout
   */
  logoutCallback: (() => void) | null = null;

  /**
   * Callback function when the user login
   * @param {AvcAuth} avcAuth
   */
  loginCallback: ((avcAuth: AvcAuth) => void) | null = null;

  /**
   * Callback function when there is an error
   * @param {Error} error
   */
  errorCallback: ((error: Error) => void) | null = null;

  /**
   * Callback function when the sdk is initialized
   * @param {string[]} modules_enabled
   */
  readyCallback: ((modules_enabled: string[]) => void) | null = null;
}

export default SDK;
