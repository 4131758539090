const AR_TRANSLATIONS = {
  NAVBAR_TOOLTIP: {
    REPORTS: "التقارير",
    MEDIA_LIBRARY: "مكتبة الوسائط",
    SEARCH: "بحث",
    CHAT: "محادثة",
    CONTACTS: "جهات الاتصال",
    APPS: "تطبيقات",
    SETTINGS: "إعدادات",
    NOTIFICATIONS: "إشعارات",
    PROFILE: "حساب تعريفي",
    COPILOT: "مساعد الطيار",
  },
  CHAT: {
    PAYLOAD: "الحمولة",
    TITLE: "عنوان",
    UPLOAD: "رفع ",
    LISTS_ADD_BODY: "1024 حرف كحد أقصى",
    LISTS_ADD_FOOTER: "60 حرف كحد أقصى",
    LISTS_ADD_BTN_TITLE: "20 حرف كحد أقصى",
    LISTS_ADD_BTN_PAYLOAD: "256 حرف كحد أقصى",
    LISTS_MENU_TITLE: "20 حرف كحد أقصى",
    LISTS_SECTION_TITLE: "24 حرف كحد أقصى",
    MAX_200_CHAR: "200 حرق كحد أقصى",
    ADD_SECTION: "إضافة قسم",
    SECTION: "سيشن",
    MENU_TITLE: "عنوان القائمة",
    MENU_LIST_ITEMS: "عناصر قائمة القائمة",
    HEADER_TEXT: "نص الرأس",
    FOOTER: "نص التذييل",
    NO_PREVIEW_AVAILABLE: "لا تتوفر معاينة",
    SHARE_IMAGE: "مشاركة الصورة",
    SHARE_VIDEO: "شارك الفيديو",
    SHARE_AUDIO: "مشاركة الصوت",
    SHARE_FILE: "مشاركة الملفات",
    MESSAGE: "رسالة",
    CALL: "يتصل",
    COPY: "نسخ",
    FAST_REPLY: "رد سريع",
    SEND: "إرسال",
    ADD_TO_CART: "أضف إلى السلة",
    SEND_TO_CHAT: "أرسل للمحادثة",
    GALLERY: "صالة عرض",
    SAR: "ريال سعودي",
    ATTACHEMENTS: "المرفقات",
    TAGS: "العلامات",
    NOTE: "ملحوظة",
    ID: "بطاقة تعريف",
    VIEW_AS: "العرض كـ",
    INBOX: "صندوق الوارد",
    UNANSWERED: "لم تتم الإجابة عليه",
    CHATBOT: "روبوت المحادثة",
    ASSIGNED_TO_ME: "المحادثات المُعينة لي",
    MENTIONS: "الإشارات",
    UNASSIGNED: "غير معين",
    ASSIGN_TO_ME: "تعيين لى",
    SALES_TEAM: "فريق المبيعات",
    MARKETING_TEAM: "فريق التسويق",
    NEW_CHAT: "محادثة جديدة",
    SOMETHING_WENT_WRONG: "هناك خطأ ما",
    PINNED_CHATS: "المحادثات المثبتة",
    ALL_CHATS: "جميع المحادثات",
    START_NEW_CONVERSATION: "ابدأ محادثة جديدة",
    CREATE_CONTACT: "إنشاء جهة اتصال",
    TO: "لـ",
    SEARCH_FOR_NAME_NUMBER: "ابحث عن اسم أو رقم",
    VIA_WHATSAPP: "عبر الواتساب",
    SELECT_CHANNEL: "اختر قناة",
    PIN_CHAT: "تثبيت المحادثة",
    UNPIN_CHAT: "ازالة التثبيت",
    MARK_AS_UNREAD: "وضع علامة كغير مقروءة",
    MARK_AS_READ: "وضع علامة كمقروءة",
    EXPORT_CHAT: "تصدير المحادثة",
    CLOSE_CHAT: "إغلاق المحادثة",
    OPEN_CHAT: "فتح المحادثة",
    PREVIEW_CHAT: "معاينة المحادثة",
    ASSIGN_CHAT: "تعيين المحادثة",
    READ_CHAT: "تمييز المحادثة كمقروء",
    UNREAD_CHAT: "تمييز المحادثة كغير مقروء",
    START_CONVERSATION: "بدء محادثة",
    MESSAGE_INFO: "معلومات الرسالة:",
    SENT: "مرسل",
    DELIVERED: "تم التوصيل",
    READ: "يقرأ",
    FAILED: "فشل",
    ATTACHMENTS: "المرفقات",
    PRIVATE_NOTE: "ملاحظة خاصة",
    NO_MSG_SELECTED: "لم يتم اختيار محادثة بعد",
    NO_MSG_SELECTED_SUBTITLE: "من فضلك اختر محادثة عميل من المحادثات فى الجانب",
    NO_PINNED_MESSAGES: "لا توجد رسائل مثبتة",
    NO_PINNED_MESSAGES_SUBTITLE: "سيتم عرض جميع رسائلك المثبتة هنا",
    NO_MESSAGES_AVAILABLE: "لا توجد رسائل متاحة",
    NO_MESSAGES_AVAILABLE_SUBTITLE: "سيتم عرض جميع رسائلك هنا",
    WHATSAPP_NAME: "اسم الواتساب",
    PROFILE_NAME: "اسم البروفايل",
    PHONE_NUMBER: "رقم الهاتف",
    EMAIL_ADDRESS: "عنوان الايميل",
    CREATE_SEARCH_PLACEHOLDER: "اصنع او ابحث",
    IMAGES: "صور",
    VIDEOS: "فيديوهات",
    DOCUMENTS: "ملفات",
    CHAT_CLOSED: "تم غلق المحادثة",
    CHAT_REOPENED: "تم إعادة فتح المحادثة",
    CHAT_CLOSED_BODY: "تم إغلاق المحادثة بواسطة <0>'{{email}}'</0>. ",
    RESTART: "إعادة الفتح",
    MESSAGE_BOX_PLACEHOLDER: 'أدخل الرسالة أو اكتب "/" لاستخدام الرد المحفوظ',
    QUICK_REPLIES_PLACEHOLDER: "ابحث عن الردود السريعة...",
    TEMPLATES_PLACEHOLDER: "ابحث عن القوالب...",
    ENTER_TEMPLATE_VARIABLES: "أدخل متغيرات القالب",
    PREVIEW: "مراجعة: ",
    CLICK_TO_SEND_TEMPLATE: "نافذة محادثة العميل مغلقة",
    _24_HOURS_WINDOW_TITLE: "انتهت صلاحية نافذة خدمة العملاء",
    _24_HOURS_WINDOW_BODY:
      "يمكنك الرد على رسالة واتساب خلال 24 ساعة من استلامها. بعد مرور 24 ساعة، يمكن استخدام القوالب المسبقة الاعتماد فقط لبدء المحادثة.  <0>اطلع على المزيد</0>",
    TIME_AGO_FROM_WHATSAPP: "{{time}} عن طريق واتساب",
    SENT_BY_AGO: "تم الإرسال بواسطة {{platform_id}} {{time}}",
    PRIVATE_NOTE_BY:
      "تمت إضافة ملاحظة خاصة بواسطة {{platform_id}} · {{time}} · مرئي للوكلاء فقط",
    TEAMS: "الفرق",
    SYSTEM: "النظام",
    SEARCH_CHATS: "بحث المحادثات",
    UNAVAILABLE_MESSAGE: "الرسالة غير متاحة",
    TEXT_MESSAGE: "رسالة نصية",
    IMAGE_MESSAGE: "رسالة الصورة",
    VIDEO_MESSAGE: "رسالة فيديو",
    AUDIO_MESSAGE: "رسالة صوتية",
    VOICE_MESSAGE: "رسالة صوتية",
    STICKER_MESSAGE: "رسالة الملصق",
    DOCUMENT_MESSAGE: "رسالة الوثيقة",
    LOCATION_MESSAGE: "رسالة الموقع",
    INTERACTIVE_MESSAGE: "رسالة تفاعلية",
    TEMPLATE_MESSAGE: "رسالة القالب",
    CONTACTS_MESSAGE: "رسالة جهات الاتصال",
    MENTIONS_MESSAGE: "يذكر رسالة",
    SYSTEM_MESSAGE: "رسالة النظام",
    UNKNOWN_MESSAGE: "رسالة غير معروفة",
    ASSIGNED_TO_AGENT: "تم تعيين المحادثة لـ {{email}}",
    CHAT_PINNED: "تم تثبيت المحادثة",
    CHAT_UNPINNED: "تم الغاء تثبيت المحادثة",
    QUICK_REPLIES: "ردود سريعة",
    TEMPLATES: "قوالب",
    CHAT_EXPORTED:
      "تم تقديم طلب تصدير المحادثة. سوف تستقبل ايميل قريبا يحتوى لينك التحميل.",
    CHAT_QUEUE: "صف المحادثات",
    PHOTO: "صورة",
    VIDEO: "فيديو",
    INTERACTIVE: "تفاعلى",
    TEMPLATE: "قالب",
    AUDIO: "مقطع صوتى",
    VOICE: "رسالة صوتية",
    YOU: "أنت",
    CONTACT: "جهة اتصال: {{name}}",
    MARK_AS_RESOLVED: "تمييز ك تم حله",
    MARK_AS_UNRESOLVED: "تمييز ك لم يتم حله",
    LAST_SEEN: "اخر نشاط: {{time}}",
    LONG_TIME_AGO: "منذ وقت طويل",
    SEARCH_CONTACTS: "بحث جهات الاتصال",
    REPLY: "رد",
    MESSAGE_INFO_BUTTON: "معلومات الرسالة",
    ADD_QUICK_REPLY: "إضافة رد سريع",
    COPY_MESSAGE: "نسخ الرسالة",
    LOCATION: "عنوان: {{location}}",
    CHAT_MARKED_AS_READ: "تم تمييز الشات كمقروء",
    CHAT_MARKED_AS_UNREAD: "تم تمييز الشات كغير مقروء",
  },
  TEMPLATE: {
    CREATE_TEMPLATE: "إنشاء قالب",
    SUBMIT_TEMPLATE: "تقديم قالب",
    SYNC_TEMPLATE: "مزامنة قالب",
    TEMPLATE_ID_ERR: "تم تقديم هذا القالب بالفعل بنفس اللغة",
    TEMPLATE_SUB_TITLE: "قم بإدارة قوالب واتساب من هنا",
    TEMPLATES: "القوالب",
    OPT_PLACEHOLDER: "كلمة المرور لمرة واحدة (OTP)",
    ZERO_TEMPLATES: "لا يوجد أي نماذج",
    ZERO_TEMPLATES_MATCH: "لا يوجد نماذج تطابق المعلومات التي أدخلتها",
    ONLY_PDF: "يسمح فقط بملفات pdf",
    ONLY_SUPPORTED_IMG: "يُسمح فقط بالصور JPG وPNG",
    ONLY_SUPPORTED_VIDEOS: "يُسمح فقط بمقاطع فيديو MP4",
    VIEW_TEMPLATE: "عرض القالب",
    BUTTON_TITLE: "عنوان الزر",
    COPY_CODE: "نسخ الكود",
    COPY_CODE_BUTTON_TITLE: "نسخ الكود",
    AUTOFILL: "الملء التلقائي",
    PHONE_NUMBER_ERR_MSG: "يرجى إدخال رقم هاتف صالح!",
    COPY_CODE_DESCRIBTION:
      "يقوم زر نسخ الرمز بنسخ كلمة المرور أو الرمز لمرة واحدة. يمكن للمستخدم بعد ذلك التبديل يدويًا إلى تطبيقك ولصق كلمة المرور أو الرمز في واجهة تطبيقك.",
    AUTOFILL_DESCRIBTION: `هذا مدعوم فقط على نظام التشغيل Android، ويتطلب إجراء تغييرات على رمز تطبيقك من أجل إجراء "المصافحة" وتجزئة مفتاح توقيع تطبيقك.`,
    AUTOFILL_BUTTON_TITLE: "الملء التلقائي",
    PACKAGE_NAME: "com.example.app",
    SIGNATURE_HASH: "على سبيل المثال: h83m0^",
    ADD_LANGUAGE_TO_TEMPLATE: "إضافة لغة جديدة",
    CREATE_TEMPLATE_DESCRIPTION:
      "قم بإرسال نماذج الرسائل الخاصة بك للموافقة عليها إلى WhatsApp هنا.",
    VIEW_TEMPLATE_DESCRIPTION: "عرض قوالب WhatsApp الخاصة بك هنا.",
    TEMPLATE_ID: "معرف القالب",
    CATEGORY: "فئة",
    SELECT_CATEGORY: "اختر الفئة",
    DESCRIPTION: "وصف",
    DESCRIPTION_PLACEHOLDER: "وصف (اختياري)",
    LANGUAGE: "لغة",
    SELECT_LANGUAGE: "اختر لغة",
    MEDIA: "وسائط",
    NONE: "بدون",
    IMAGE: "صورة",
    VIDEO: "فيديو",
    DOCS: "المستندات",
    BODY_TEXT: "نص الرسالة",
    INSERT_VAR: "إدراج متغير {{#}}",
    INSERT_OPT_OUT_LINK: "أدخل رابط إلغاء الاشتراك",
    FOOTER: "تذييل (اختياري)",
    FORMAT_TEXT_OPTION: "هل تريد تنسيق النص؟",
    FORMATTING_WHATSAPP_MESSAGES: "انظر تنسيق رسائل واتساب ",
    HERE: "هنا",
    BUTTONS: "أزرار",
    CTA_BUTTONS: "أزرار CTA",
    QUICK_REPLIES: "ردود سريعة",
    NEXT: "التالي",
    SUBMIT: "يُقدِّم",
    SAVING: "إنقاذ...",
    SAVED: "أنقذ",
    ERROR: "خطأ",
    WHATSAPP_ALERT_MESSAGE:
      "يجب إرسال رسائل قالب WhatsApp والموافقة عليها قبل إرسالها إلى جهات الاتصال الخاصة بك.",
    CREATE_MOBILE_MESSAGE:
      "يعد تقديم النماذج أمرًا معقدًا من شاشة الهاتف المحمول. ",
    SUBMITTED: "مُقَدَّم",
    SELECT_STATUS: "حدد الحالة",
    CLEAR_FILTER: "إلغاء المرشحات",
    SEARH_TEMPLATES: "بحث",
    STATUS: "حالة",
    SHOW_CHAT: "عرض في المحادثة",
    ACTION: "إجراءات",
    ADD_BUTTON: "إضافة زر",
    BUTTON: "زر",
    TYPE_OF_ACTION: "نوع العمل",
    PHONE_NUMBER: "رقم التليفون",
    STATIC_LINK: "رابط ثابت",
    ENTER_URL_HERE: "أدخل عنوان URL هنا",
    ENTER_PHONE_HERE: "أدخل رقم الهاتف",
    BUTTON_DISPLAY_TEXT: "نص عرض الزر",
    APPROVED: "موافقة",
    REJECTED: "مرفوض",
    DELETED: "تم الحذف",
    ALLOW_CATEGORY_CHANGE: "السماح بتغيير الفئة",
    SECURITY_DISCLAIMER: "إخلاء المسؤولية الأمنية",
    EXPIRATION_WARNING: "تحذير انتهاء الصلاحية",
    DELETE_DIALOG_HEADER: "حذف القالب",
    DELETE_DIALOG_SUB_HEADER: "هل أنت متأكد أنك تريد حذف هذا القالب؟ ",
    CONFIRM_DELETE: "تأكيد الحذف",
    ALL_TEMPLATES: "جميع القوالب",
    DELETED_TEMPLATES: "القوالب المحذوفة",
    FILTERS: {
      STATUS: {
        PLACEHOLDER: "حدد الحالة",
        OPTIONS: {
          APPROVED: "موافقة",
          SUBMITTED: "مُقَدَّم",
          REJECTED: "مرفوض",
          DELETED: "تم الحذف",
          PAUSED: "متوقف مؤقتًا",
        },
      },
      LANGUAGE: {
        PLACEHOLDER: "اختر لغة",
        OPTIONS: {
          AR: "AR",
          EN: "إن",
        },
      },
      CATEGORY: {
        PLACEHOLDER: "اختر تصنيف",
        OPTIONS: {
          MARKETING: "تسويق",
          OTP: "كلمة السر لمرة واحدة",
          TRANSACTIONAL: "المعاملات",
        },
      },
    },
    ACTIONS_BTN: {
      VIEW: "عرض",
      PREVIEW: "معاينة",
      ADD_LANGUAGE: "إضافة لغة",
      DUPLICATE: "نسخ النموذج",
      DELETE: "حذف القالب",
    },
    SELECT_CATEGORY_OPTIONS: {
      AUTHENTICATION: "المصادقة",
      MARKETING: "تسويق",
      UTILITY: "المنفعة",
    },
    VARIABLES_PREVIEW: {
      UPLOAD_SAMPLE_IMAGE: "تحميل صورة عينة",
      UPLOAD_SAMPLE_VIDEO: "تحميل عينة الفيديو",
      UPLOAD_SAMPLE_DOCUMENT: "تحميل نموذج documnet",
      UPLOAD_IMAGE: "تحميل الصور",
      UPLOAD_VIDEO: "رفع فيديو",
      UPLOAD_DOCUMENT: "تحميل الوثيقة",
      ENTER_VARIABLE: "أدخل المتغير",
      ADVANCED_OPTIONS: "الخيارات المتقدمة (الأزرار)",
      BACK: "خلف",
      SUBMIT: "تقديم للمراجعة",
    },
    LANGUAGE_WARNING: "اللغة المحددة لا تتطابق مع المحتوى الذي أدخلته. ",
  },
  SETTINGS: {
    SETTINGS: "إعدادات",
    SUBTITLE: "قم بإدارة إعدادات ملفك الشخصي ومساحة العمل هنا",
    WORKSPACE_SETTINGS: "إعدادات مساحة العمل",
    CUSTOM_ATTRIBUTES: "السمات المخصصة",
    WORKSPACE_PROFILE: "بيانات لمساحة العمل",
    USERS: "الوكلاء",
    TEAMS: "فِرق",
    CONTACTS: "جهات الاتصال",
    DEVTOOLS: "أدوات التطوير",
    CHANNELS: "القنوات",
    WEBHOOKS_APIS: "خطافات الويب",
    MESSAGE_LOGS: "سجلات الرسائل",
    ACCOUNT_SETTINGS: "إعدادت الحساب",
    PROFILE: "البروفايل",
    NOTIFICATIONS: "إشعارات",
    LOGOUT: "تسجيل خروج",
    LOGGING_OUT: "تسجيل الخروج...",
    COPILOT_INITIAL_MSG:
      "مرحبًا، أنا كوبيلوت، مساعدك الشخصي. يمكنني مساعدتك في أي شيء تحتاجه. فقط اسألني!",
    DEV_TOOLS: {
      CREATE_API_KEY: "إنشاء مفتاح API",
      API_NAME: "اسم واجهة برمجة التطبيقات",
      ENTER_API_NAME: "أدخل اسم واجهة برمجة التطبيقات",
      ACCESS_MODE: "وضع وصول",
      SELECT_MODE: "حدد الوضع",
      READ: "يقرأ",
      READ_WRITE: "قراءة و كتابة",
      CANCEL: "إلغاء",
      SAVE: "حفظ",
      SAVING: "إنقاذ...",
      SAVED: "أنقذ",
      ERROR: "خطأ",
      VIEW_DETAILS: "عرض التفاصيل",
      CONNECTED: "متصل",
      AVAILABLE_CHANNELS: "القنوات المتاحة",
      CONNECT: "يتصل",
      CONNECT_VIA: "توصيل عن طريق",
      CONNECT_VIA_360: "توصيل عن طريق 360",
      CONNECT_VIA_META: "توصيل عن طريق ميتا",
      CONNECT_WHATSAPP_TITLE: "اتصل بالواتساب عن طريق ميتا",
      CONNECT_WHATSAPP_SUBTITLE: "وصل الواتساب بصندوق الرسائل الخاص بك",
      CONNECT_WHATSAPP_CLOUD: "اتصل بالواتساب السحابى",
      ACCESS_TOKEN: "توكن الوصول*",
      WHATSAPP_BUSINESS_ID: "رقم حساب الواتساب بزنس",
      COPY_FIELDS_TO_WHATSAPP: "انسخ هذه المدخلات إلى الواتساب",
      CALLBACK_URL: "رابط نداء العودة",
      VERIFY_TOKEN: "تأكيد التوكن",
      WAITING_FOR_VERIFICATION: "بانتظار التاكيد",
      VERIFICATION_SUCCESSFUL: "تم التأكيد بنجاح",
      SUCCESSFULLY_CONNECTED_WHATSAPP:
        "لقد قمت بالاتصال بالواتساب السحابى بنجاح",
      NOW_ACTIVE: "الان سوف تبدء استقبال رسائل من جهات الاتصال الخاصة بك",
      ADD_WHATSAPP_CHANNEL: "اضافة قناة واتساب جديدة",
      CONNECT_WHATSAPP_INBOX: "ربط الواتس اب بصندوق الوارد الخاص بك",
      ADD_WHATSAPP_ACCOUNT: "إضافة حساب واتساب جديد",
      ADD_WHATSAPP_360: "إضافة حساب WhatsApp جديد (مع 360Dialog كموفر)",
      SIGNUP_NEW_ACCOUNT: "سجل لحساب جديد",
      DIALOG_INFO_360:
        "سيُطلب منك التسجيل للحصول على حساب من خلال 360dialog في نافذة منبثقة.",
      DATE: "تاريخ",
      FROM: "من (المرسل)",
      TO: "إلى (المتلقي)",
      SRC: "المصدر",
      MESSAGE_SOURCE: {
        CHANNEL_CONTACT: "الاتصال بالقناة",
        AGENT: "وكيل",
        BOT: "بوت",
        EXTERNAL: "خارجي",
        BUSINESS: "عمل",
        HOST: "يستضيف",
      },
      CREATE_WEBHOOK: "إنشاء خطاف ويب",
      EDIT_WEBHOOK: "تحرير خطاف الويب",
      NAME: "اسم",
      ENTER_WEBHOOK_NAME: "أدخل اسم خطاف الويب",
      DESCRIPTION: "وصف",
      DESCRIPTION_WEBHOOK: "وصف لخطاف الويب الخاص بك",
      EVENT: "حدث",
      ACTIONS_TRIGGER_INFO_WEBHOOK:
        "هذه هي الأحداث في الوقت الفعلي التي ستؤدي إلى الاتصال بعنوان URL للخطاف على الويب الخاص بك",
      SELECT_EVENT: "حدد الحدث",
      INCOMING_MESSAGE: "رسالة واردة",
      OUTGOING_MESSAGE: "رسالة صادرة",
      CHAT_ASSIGNED: "تم تعيين المحادثة",
      CHAT_CLOSED: "المحادثة مغلقة",
      CHAT_MENTIONED: "المحادثة المذكورة",
      CONTACT_CREATED: "تم إنشاء جهة الاتصال",
      TEMPLATE_APPROVED: "تمت الموافقة على القالب",
      BROADCAST_COMPLETED: "اكتمل البث",
      BILLING_ALERT: "تنبيه الفواتير",
      CONTACT: "اتصال",
      POLICY: "سياسة",
      WEBHOOK_POLICY_INFO: "السياسة المستخدمة أثناء تشغيل خطاف الويب",
      SIMPLE: "بسيط",
      SELECT_POLICY: "حدد السياسة",
      STREAMING_MODE: "وضع الدفق",
      SELF_HEALING: "الشفاء الذاتي",
      SAMPLING_30: "أخذ العينات - 30%",
      BATCH_MODE: "دفعة واسطة",
      BATCH_HIGH_FREQUENCY: "دفعة - عالية التردد",
      ENDPOINT: "نقطة النهاية",
      ENDPOINT_INFO:
        "سيتم إرسال طلب HTTP POST إلى عنوان URL هذا للحدث الذي قمت بإعداده",
      SECURITY_TOKEN: "رمز الأمان",
      SECURITY_TOKEN_INFO:
        "رمز التحدي الأمني ​​للتعرف على خطاف الويب الخاص بك. ",
      CREATED_BY: "انشأ من قبل",
      CREATED_DATE: "تاريخ الإنشاء",
      URL: "عنوان URL",
      API_KEY: "مفتاح API",
      WEBHOOKS: "خطافات الويب",
      WEBHOOK_INFO:
        "استمع للأحداث على حسابك حتى تتمكن عمليات التكامل من إثارة التفاعلات تلقائيًا",
      API_INFO: "قم بإنشاء مفاتيح API للوصول إلى حسابك برمجياً",
      CREATE_API: "إنشاء واجهة برمجة التطبيقات",
      EDIT: "تحرير",
      DELETE: "حذف",
      REVOKE: "إلغاء",
      CONNECT_WORKSPACE: "قم بتوصيل مساحة العمل الخاصة بك بقنوات الطرف الثالث.",
      PHONE: "هاتف",
      PHONE_DESCRIPTION: "التواصل مع المستخدمين من خلال الهاتف",
      WHATSAPP: "واتساب",
      WHATSAPP_DESCRIPTION: "تواصل مع المستخدمين من خلال WhatsApp Business API",
      FB: "فيسبوك",
      FB_DESCRIPTION: "التواصل مع المستخدمين من خلال قناة الفيسبوك عبر المنصة",
      IG: "انستغرام",
      IG_DESCRIPTION:
        "قم بدمج متجر Instagram الخاص بك دون التبديل إلى تطبيق الهاتف المحمول",
      SMS: "رسالة قصيرة",
      SMS_DESCRIPION: "دمج إمكانيات الرسائل القصيرة في النظام الأساسي الخاص بك",
      TELEGRAM: "برقية",
      TELEGRAM_DESCRIPION:
        "قم بتوصيل Telegram كقناة والرد على المستخدمين بحرية",
      RCS: "RCS",
      RCS_DESCRIPTION:
        "دمج RCS كقناة واستهداف العملاء على نطاق الرسائل القصيرة",
      CHANNEL_UNAVAILABLE: "القناة غير متاحة",
      CHANNEL_UNAVAILABLE_INFO: "هذه القناة قيد التطوير حاليًا. ",
      MESSAGES_LOGS: "سجلات الرسائل",
      MESSAGES_LOGS_SERVICE: "عرض وتنزيل سجلات رسائل العملاء",
      ALL_CHANNELS: "جميع القنوات",
      EXPORT_RESULTS: "نتائج التصدير",
      MESSAGE_TYPE: "نوع الرسالة",
      MESSAGE_TYPE_OPTIONS: {
        in: "وارد",
        out: "صادر",
      },
      CHANNEL_OPTIONS: {
        facebook: "فيسبوك",
        whatsapp: "واتساب",
        instagram: "انستغرام",
      },
      STATUS_OPTIONS: {
        ACCEPTED: "قبلت",
        DELETED: "تم الحذف",
        DELIVERED: "تم التوصيل",
        FAILED: "فشل",
        RECEIVED: "تلقى",
        SEEN: "مرئي",
        SENT: "مرسل",
      },
      CHANNEL: "قناة",
      DATE_OPTIONS: {
        LAST_7_DAYS: "اخر 7 ايام",
        LAST_30_DAYS: "آخر 30 يومًا",
        LAST_3_MONTHS: "آخر 3 أشهر",
        LAST_6_MONTHS: "آخر 6 أشهر",
        LAST_YEAR: "العام الماضي",
        CUSTOM_DATE_RANGE: "النطاق الزمني المخصص",
      },
      SELECT_BY_DATE: "اختر حسب التاريخ",
      WEBHOOKS_API: "خطافات الويب",
      MANAGE_WEBHOOKS_API: "إدارة Webhooks لمساحة العمل الخاصة بك",
      NO_WEBHOOKS: "ليس لديك أي خطافات على الويب حتى الآن",
      CREATE_FIRST_WEBHOOK: "أنشئ خطاف الويب الأول الخاص بك",
      NO_API_KEYS: "ليس لديك مفاتيح API حتى الآن",
      CREATE_FIRST_API: "قم بإنشاء مفتاحك الأول",
      CREATE_WEBHOOK_FORM: {
        ERRORS: {
          NAME: "مطلوب اسم",
          NAME_LENGTH: "يجب أن لا يقل الاسم عن 50 حرفًا",
          DESCRIPTION: "الوصف مطلوب",
          DESCRIPTION_LENGTH: "يجب أن يكون الوصف 1024 حرفًا على الأكثر",
          EVENT: "الحدث مطلوب",
          POLICY: "السياسة مطلوبة",
          ENDPOINT: "نقطة النهاية مطلوبة",
          ENDPOINT_VALID: "يجب أن تكون نقطة النهاية عنوان URL صالحًا",
          SECURE_TOKEN: "مطلوب رمز آمن",
          SECURE_TOKEN_VALID:
            "يجب أن يتطابق الرمز المميز الآمن مع ما يلي: '/^[^a-zA-Z0-9 -_] $/g'",
        },
      },
      CREATE_API_KEY_FORM: {
        ERRORS: {
          NAME: "اسم API مطلوب",
          NAME_LENGTH:
            "يجب أن يكون اسم واجهة برمجة التطبيقات (API) 50 حرفًا على الأكثر",
          ACCESS_MODE: "وضع الوصول مطلوب",
        },
      },
    },
    ACCOUNT: {
      NOTIFICATIONS: "إشعارات",
      NOTIFICATIONS_PREFRENCES: "إدارة تفضيلات الإخطارات",
      NEW_MESSAGE_NOTIFICATION: "إشعارات الرسائل الجديدة",
      INSIDE_CHAT_SCREEN: "داخل صفحة الشات",
      INSIDE_CHAT_SCREEN_SUBTITLE: "استقبال اشعارات الرسائل الجديدة ك",
      INSIDE_CHAT_SCREEN_FILTER: {
        CONTEXT: "سياق",
        AGENT: "عميل",
        TEAM: "فريق",
        ALL: "الكل",
      },
      OUTSIDE_CHAT_SCREEN: "خارج صفحة الشات",
      OUTSIDE_CHAT_SCREEN_SUBTITLE: "استقبال اشعارات الرسائل الجديدة ك",
      OUTSIDE_CHAT_SCREEN_FILTER: {
        ALL: "الكل",
        AGENT: "عميل",
        TEAM: "فريق",
      },
      SOUND_NOTIFICATION: "إشعار الصوت",
      PLAY_SOUND_NOTIFICATION:
        "تشغيل الإشعارات الصوتية لجهات الاتصال المعينة لي أو جهات الاتصال غير المعينة",
      DESKTOP_NOTIFICATIONS: "إعلام سطح المكتب",
      UNBLOCK_DESKTOP_NOTIFICATION_HELP: "👆 تم حظر إشعارات سطح المكتب. يرجى تمكينها من خلال النقر على الرمز الموجود على الجانب الأيسر من شريط العناوين > الإشعارات > السماح",
      ENABLE_DESKTOP_NOTIFICATION:
        "تم حظر إشعارات سطح المكتب بواسطة متصفحك. <0>أضغط هنا</0> للسماح باشعارات سطح المكتب",
      SOUND_NOTIFICATION_1:
        "تشغيل الإشعارات الصوتية للمستخدمين المخصصين لى او المستخدمين الغير مخصصين",
      SOUND_NOTIFICATION_2: "تشغيل الﻹشعارات الصوتية للرسائل الجديدة",
      EMAIL_NOTIFICATION: "اشعارات البريد الالكتروني",
      ENABLE_EMAIL_NOTIFICATION:
        "أرسل لي إشعارًا بجهات الاتصال المخصصة لي والإشارات",
      IMPORTANT_NOTIFICATIONS: "إشعارات مهمة حول أمان حسابك.",
      USER_PROFILE: "ملف تعريفي للمستخدم",
      USER_PROFILE_DESCRIPTION:
        "إدارة ملف التعريف الخاص بك وتفاصيل تسجيل الدخول.",
      PROFILE_PHOTO: "صورة الملف الشخصي",
      UPLOAD_IMAGE: "تحميل الصور",
      CONTACT_NAME: "اسم جهة الاتصال",
      PASSWORD: "كلمة المرور",
      UPDATE: "تحديث",
      UPDATING: "جارٍ التحديث...",
      UPDATED: "محدث",
      ERROR: "خطأ",
      CONTACT_EMAIL: "البريد الاكتروني",
      SELECT_LANGUAGE: "اختار اللغة",
      COUNTRY: "دولة",
      PHONE: "رقم التليفون",
      DOB: "تاريخ الميلاد",
      ADDRESS: "عنوان",
      EN: "إنجليزي",
      AR: "عربي",
      HI: "الهندية",
      CHANGE_PASSWORD: "تغيير كلمة المرور",
      CURRENT_PASSWORD: "كلمة السر الحالية",
      NEW_PASSWORD: "كلمة المرور الجديدة",
    },
  },
  GREETINGS: {
    TITLE: "رسائل الترحيب وخارج الدوام",
    DESCRIPTION:
      "قم بتعيين رسائل الترحيب ورسائل خارج الدوام لإرسالها إلى العملاء داخل/خارج ساعات العمل.",
    SET_WORKING_HOURS: "ضبط ساعات العمل الخاصة بك",
    SET_BUSINESS_HOURS: "قم بإعداد ساعات العمل الخاصة بشركتك",
    GREETING_MSG: "رسالة تحية",
    GREETING_MESSAGE_DESCRIPTION:
      "قم بالترحيب بالعملاء الجدد تلقائيًا عندما يقومون بمراسلتك خلال ساعات العمل.",
    TIME_INTERVAL: "الفاصل الزمني",
    AWAY_MSG: "رسالة خارج الدوام",
    AWAY_MSG_DESCRIPTION: "أخبر العملاء أنهم وصلوا إليك خارج ساعات العمل.",
    GREEING_AWAY: "رسائل الترحيب وخارج الدوام",
    SET_GREETING_AWAY:
      "قم بتعيين رسائل الترحيب ورسائل خارج الدوام لإرسالها إلى العملاء داخل/خارج ساعات العمل.",
    SELECT_TIME_ZONE: "اختر المجال الزمني",
    TIME_ERROR: "لا يمكن أن يكون وقت البدء بعد وقت قريب",
    INTERVAL_MIN_ERROR: "يجب أن يكون الفاصل الزمني أكبر من أو يساوي 1",
    INTERVAL_MAX_ERROR: "لا يمكن أن يكون الفاصل الزمني أكبر من ",
    INTERVAL_VALIDATION_ERROR: "يجب أن يكون الفاصل الزمني رقمًا",
    AWAY_TYPE_ERROR:
      "يجب أن يكون الفاصل الزمني البعيد من النوع \"رقم\"، ولكن القيمة النهائية كانت: `NaN` (مشتقة من القيمة `''`).",
    GREETING_TYPE_ERROR:
      "يجب أن يكون الفاصل الزمني للترحيب من النوع \"رقم\"، ولكن القيمة النهائية كانت: `NaN` (مشتقة من القيمة `''`).",
    DELAY_DESCRIBTION: "رسالة التأخير (بالثواني):",
    MSG_PLACE_HOLDER: "أكتب رسالتك...",
    SAVE: "حفظ",
    SAVING: "يتم...",
    SAVED: "تم",
    ERROR: "خطأ",
    SUNDAY: "الأحد",
    MONDAY: "الاثنين",
    TUESDAY: "يوم الثلاثاء",
    WEDNESDAY: "الأربعاء",
    THURSDAY: "يوم الخميس",
    FRIDAY: "جمعة",
    SATURDAY: "السبت",
  },
  CONTACTS: {
    CONTACT: "اتصال",
    HEADER: "جهات الاتصال",
    SUBTITLE: "إدارة جهات الاتصال الخاصة بك",
    EMPTY_CONTACTS: "قائمة جهات الاتصال فارغة. ",
    IMPORT_EXPORT_CONTACTS_BTN: "إجراءات جملة",
    IMPORT_CONTACTS_BTN: "استيراد جهات الاتصال",
    EXPORT_CONTACTS_BTN: "تصدير جهات الاتصال",
    IMPORTS: "الواردات",
    EXPORTS: "صادرات",
    CREATE_CONTACT_BTN: "إنشاء اتصال",
    NO_CONTACTS: "ليس لديك أي اتصالات حتى الآن",
    CREATE_FIRST_CONTACT_BTN: "إنشاء جهة الاتصال الأولى الخاصة بي",
    SHOW_LIST: "عرض قائمة",
    EXPAND_TO_VIEW: "توسيع للعرض: ",
    IMPORT_POPUP: {
      TITLE: "استيراد جهات الاتصال",
      SUBTITLE: "قم بإعداد ملفك:",
      P1: "قم بتحسين عملية الاستيراد الخاصة بك عن طريق إعداد ملفك. ",
      P2: "لن يتم تكرار جهات الاتصال. ",
      DOWNLOAD_SAMPLE_BTN: "تنزيل نموذج XLSX",
      UPLOAD_FILE_BTN: "تحميل ملف .xlsx",
    },
    EXPORT_POPUP: {
      TITLE: "تصدير جهات الاتصال",
      SUBTITLE_1: "تنزيل جهات الاتصال الخاصة بك:",
      SUBTITLE_2: "يمكنك تنزيل جهات الاتصال بتنسيق CSV أو Excel",
      DOWNLOAD_CSV: "قم بالتنزيل كملف CSV",
      DOWNLOAD_EXCEL: "تنزيل بصيغة Excel",
    },
    TABLE: {
      NORMAL_TITLE: "جهات الاتصال",
      NORMAL_SUBTITLE: "إنشاء وإدارة جهات الاتصال الخاصة بك",
      IMPORT_TITLE: "استيراد جهات الاتصال",
      ACTIVITIES: "أنشطة",
      MOST_USED_TAGS: "الأكثر استخدام",
      ALL_TAGS: "كل العلامات",
      SEARCH_TAGS: "بحث العلامات",
      HEADERS: {
        NAME: "اسم",
        DISPLAY_NAME: "اسم العرض",
        EMAIL: "بريد إلكتروني",
        PLATFORM_ID: "معرف المنصة",
        COUNTRY: "دولة",
        LANGUAGE: "لغة",
        ASSIGNED_TO: "مخصص ل",
        USER: "جهة الإتصال",
        TAGS: "العلامات",
        CHANNEL: "قناة",
        CONVERSATION: "محادثة",
        ASSIGNEE: "المكلف",
        LAST_MESSAGE: "اخر رسالة",
        ACTION: "إجراءات",
        FILE_NAME: "اسم الملف",
        NO_CONTACTS: "عدد جهات الاتصال",
        CREATED_BY: "انشأ من قبل",
        CREATED_AT: "أنشئت في",
        STATUS: "حالة",
        REPORT: "تقرير",
        URL: "عنوان URL",
        DOWNLOAD: "تحميل",
        REASON: "سبب",
      },
      FOOTER: "يستورد {{total}} جهات الاتصال",
    },
    NOTIFICATIONS: {
      SUCCESS: "نجاح",
      IMPORT_SUCCESS: "تم استيراد كافة السجلات بنجاح",
    },
    CREATE_POPUP: {
      TITLE: "إنشاء جهة اتصال جديدة",
      UPDATE_TITLE: "تحديث جهة الاتصال",
      TITLES: {
        PROFILE_PHOTO: "صورة الملف الشخصي",
        CONTACT_NAME: "اسم جهة الاتصال",
        CONTACT_EMAIL: "البريد الاكتروني",
        CONTACT_PHONE: "رقم الجوال",
        CHANNEL: "قناة",
        TAGS: "العلامات",
      },
      BUTTONS: {
        UPLOAD_IMAGE: "تحميل الصور",
        CANCEL: "إلغاء",
        SAVE: "حفظ",
        SAVING: "إنقاذ...",
        SAVED: "أنقذ",
        EDIT: "تحرير",
        UPDATE: "تحديث",
        UPDATING: "جارٍ التحديث...",
        UPDATED: "محدث",
        ERROR: "خطأ",
        ARCHIVE: "أرشفة جهة الاتصال",
      },
      TAGS_OPTIONS: {
        TAG_1: "العلامة 1",
        TAG_2: "العلامة 2",
        TAG_3: "العلامة 3",
      },
    },
    ACTIVITIES_IMPORT_POPUP: {
      TOTAL_CONTACTS: "إجمالي جهات الاتصال",
      FAILED_CONTACTS: "اتصالات فاشلة",
      WARNING_CONTACTS: "اتصالات التحذير",
      PROCESSED_CONTACTS: "جهات الاتصال المعالجة",
      NO_CONTACTS: "لا توجد بيانات جهات اتصال فاشلة لعرضها",
    },
  },
  SEARCH: {
    HEADER: "البحث",
    DIRECTION: "اتجاه",
    SEARCH_CONTACTS_PLACEHOLDER: "البحث عن جهات الاتصال",
    SEARCH_MESSAGES_PLACEHOLDER: "البحث عن الرسائل",
    NO_MSGS_FOUND: "لم يتم إيجاد أي رسائل.",
    TRY_AGAIN_WITH_OTHER_WORDS: "حاول البحث بكلمات أخرى.",
    CATEGORIES: {
      ALL: "الجميع",
      MESSAGES: "رسائل",
      CONTACTS: "جهات الاتصال",
    },
    FILTERS: {
      MESSAGE_TYPE: {
        PLACEHOLDER: "نوع الرسالة",
        OPTIONS: {
          TEXT: "نص",
          IMAGE: "صورة",
          VIDEO: "فيديو",
          DOCUMENT: "وثيقة",
        },
      },
      READ_STATUS: {
        PLACE_HOLDER: "قراءة الحالة",
        OPTIONS: {
          READ: "يقرأ",
          UNREAD: "غير مقروءة",
        },
      },
      CHAT_STATUS: {
        PLACE_HOLDER: "حالة المحادثة",
        OPTIONS: {
          unanswered: "لم تتم الإجابة عليه",
          assigned: "مُكَلَّف",
          unassigned: "غير معين",
          closed: "مغلق",
          snoozed: "تم التأجيل",
          archived: "مؤرشف",
        },
      },
      ASSIGNED_TO: {
        PLACEHOLDER: "مخصص ل",
      },
      CHANNEL: {
        PLACEHOLDER: "قناة",
        OPTIONS: {
          WHATSAPP: "واتساب",
          FACEBOOK: "فيسبوك",
          INSTAGRAM: "انستغرام",
        },
      },
      LAST_ACTIVITY: {
        PLACEHOLDER: "النشاط الاخير",
        OPTIONS: {
          LAST_7_DAYS: "اخر 7 ايام",
          LAST_30_DAYS: "آخر 30 يومًا",
          LAST_6_MONTHS: "آخر 6 أشهر",
          LAST_3_MONTHS: "آخر 3 أشهر",
          LAST_YEAR: "العام الماضي",
          CUSTOM_DATE_RANGE: "النطاق الزمني المخصص",
        },
      },
      DATE_ADDED: "تم إضافة التاريخ",
      TAGS: {
        PLACEHOLDER: "العلامات",
        OPTIONS: {
          TAG_1: "العلامة 1",
          TAG_2: "العلامة 2",
          TAG_3: "العلامة 3",
        },
      },
    },
    TOOLTIPS: {
      GROUP: "تعيين المحادثة",
      TAG: "إضافة علامة",
      CHAT: "ضع إشارة مقروء",
      UNREAD_CHAT: "وضع علامة كغير مقروءة",
      CANCEL: "إغلاق المحادثة",
    },
    START_SEARCHING: "ابدأ البحث أو استخدم الفلتر للحصول على النتائج",
    SELECT_ALL: "اختر الكل",
    GO_TO_CHAT: "اذهب إلى المحادثة",
  },
  APPS: {
    HEADER: "تطبيقات",
    SUBTITLE:
      "أطلق العنان للإمكانات الكاملة لشركتك من خلال التطبيقات وعمليات التكامل القوية",
    BROADCAST: {
      TITLE: "الحملات الإعلانية",
      DESCRIPTION:
        "نحن نقوم باستخدام الحملات الإعلانية الخاصة بالواتساب لإرسال رسائل إلي جهات اتصال متعددة - كلا من الرسائل المعاملية والرسائل التسويقية",
    },
    TEMPLATES: {
      TITLE: "قوالب",
      DESCRIPTION:
        " نماذج أو قوالب تُمكن المستخدمين من بدء المحادثات عن طريق إرسال رسائل ( تم الموافقة عليها عن طريق الواتساب ) وتكون لغرض المنفعة والمصادقة والتسويق ",
    },
    QUICK_REPLIES: {
      TITLE: "ردود سريعة",
      DESCRIPTION:
        "إختصارات من الرسائل التي تُرسل بشكل متكرر و الوسائط مثل الصور أو الفيديوهات للرد علي العملاء بشكل أوسع عبر استخدام الردود السريعة",
    },
    RULES: {
      TITLE: "قواعد",
      DESCRIPTION:
        "وضع قاعد لكلا من الرسائل الوارد والصادرة , اسمح لمحرك القواعد الخاص بنا أن يقوم بتبسي حياتك, وأن يقوم بعمل أتمتة للردود الألية لكي تستطيع أن تحدد الوقت المهم حقا بالنسبة لك",
    },
    GREETINGS_AWAY: {
      TITLE: "رسائل الترحيب وخارج الدوام",
      DESCRIPTION:
        "مصممة لكي تعزز من تفاعل العملاء فرسائل الترحيب تقوم بتحية العملاء بينما رسائل خارج أوقات العمل تقوم بإبلاغهم متي تكون متاحا",
    },
    TAGS: {
      TITLE: "العلامات",
      DESCRIPTION:
        "تقوم بتصنيف العملاء وربطهم بناءً على مشاركتهم باستخدام 'العلامات'. تقوم بفصل وتنظيم الفئات المختلفة لقاعدة عملائك بشكل فعال",
    },
    CHATGPT: {
      TITLE: "ChatGPT",
      DESCRIPTION:
        "قم بإحداث ثورة في مشاركة عملائك من خلال توفير حلول سياقية دقيقة وذات صلة وسريعة باستخدام ChatGPT",
    },
    DIALOG_FLOW: {
      TITLE: "تدفق الحوار",
      DESCRIPTION:
        "قم بإنشاء روبوتات محادثة ذكية تتعامل مع الاستعلامات بكفاءة، مما يحسن تجربة المستخدم من خلال تكامل Dialogflow",
    },
    GOOGLE_TRANSLATE: {
      TITLE: "جوجل المترجم",
      DESCRIPTION: "توفير ترجمة فورية لتحسين الرضا، دون الحاجة إلى ترجمة فورية",
    },
    HUBSPOT: {
      TITLE: "هوبسبوت",
      DESCRIPTION: "قم بتحسين عمليات عملك من خلال إدارة علاقات العملاء المبسطة",
    },
    ZAPIER: {
      TITLE: "زابير",
      DESCRIPTION: "قم بزيادة إنتاجيتك إلى أقصى حد مع Zapier. ",
    },
    CSML: {
      TITLE: "CSML",
      DESCRIPTION:
        "قم بدمج روبوتات المحادثة CSML في النظام الأساسي الخاص بك لأتمتة دعم العملاء وعمليات المبيعات",
    },
    GOOGLE_SHEETS: {
      TITLE: "جداول بيانات جوجل",
      DESCRIPTION:
        "قم بمزامنة بياناتك تلقائيًا مع جداول بيانات Google لإبقاء فريقك على اطلاع بأحدث المعلومات",
    },
    KNOWLEDGE_BASE: {
      TITLE: "قاعدة المعرفة",
      DESCRIPTION:
        "مركز معلومات يحتوي على مقالات وأدلة وأسئلة شائعة. فهو يمكّن المستخدمين من إيجاد الحلول والرؤى، وتشجيع المساعدة الذاتية وتقليل الحاجة إلى الدعم المباشر.",
    },
    MEDIA_LIBRARY: {
      TITLE: "مكتبة الوسائط",
      DESCRIPTION:
        "مصدر موحد للتعامل مع الوسائط المتنوعة مثل الصور ومقاطع الفيديو والصوت والمستندات وما إلى ذلك. تعزيز سهولة الاكتشاف والمشاركة والاستخدام في المشاريع والاتصالات ",
    },
    WHATSAPP_FLOW: {
      TITLE: "الرد الآلي الخاص بالواتساب",
      DESCRIPTION:
        "يقوم بتمكين الشركات من صياغة تجارب شاملة للعملاء من علي الواتساب وتقوم بإثراء تفاعلهم",
    },
  },
  BROADCAST: {
    HEADER: "الحملات الإعلانية",
    SUBTITLE:
      "أرسل رسالة قالب WhatsApp إلى كل أو جزء من قائمة جهات الاتصال الخاصة بك مرة واحدة.",
    CREATE_BROADCAST_BTN: "بث جديد",
    ZERO_BROADCASTS: "أنت لا تملك أي حملات إعلانية",
    STATUS_BOX: {
      MESSAGING_TIER: {
        KEY: "طبقة الرسائل:",
        VALUE: "الطبقة {{tier}}",
        DESCRIPTION: "(بحد أقصى 100.000 عميل خلال 24 ساعة)",
      },
      ACCOUNT_HEALTH: {
        KEY: "صحة الحساب:",
        VALUE: {
          GREEN: "أخضر",
        },
      },
      ACCOUNT_STATUS: {
        KEY: "حالة الحساب:",
        VALUE: {
          CONNECTED: "متصل",
        },
      },
    },
    FILTERS: {
      STATUS: {
        PLACEHOLDER: "حدد الحالة",
        OPTIONS: {
          DRAFT: "مسودة",
          SCHEDULED: "المقرر",
          SUBMITTED: "مُقَدَّم",
          IN_REVIEW: "في مراجعة",
          PROCESSING: "يعالج",
          COMPLETED: "مكتمل",
          FAILED: "فشل",
          HALTED: "توقف",
          INVALIDATED: "غير صالح",
        },
      },
      DATE: "اختر حسب التاريخ",
      SEARCH: "بحث",
    },
    BROADCASTS_TABLE: {
      HEADERS: {
        BROADCAST_NAME: "اسم الحملة",
        CHANNEL: "قناة",
        RECIPIENTS: "المستلمون",
        STATUS: "حالة",
        SENT: "تاريخ الإرسال",
        ACTION: "إجراءات",
      },
      N_DAYS_AGO: "{{days}} أيام مضت",
      RECIPIENTS: "المستلمون: {{count}}",
      TEMPLATE: "نموذج: {{template_id}}",
    },
    DETAILS: {
      SENT_ON: "أرسلت في {{date}}",
      BROADCAST_STATUS: "حالة الحملة الإعلانية",
      TARGET_SUMMARY: "ملخص الحملة الإعلانية",
      LAST_UPDATED: "آخر تحديث {{date}}",
      VIEW_DETAILS: "عرض التفاصيل",
      FILTERS: {
        STAGE: {
          PLACEHOLDER: "مرحلة واجهة برمجة التطبيقات",
          OPTIONS: {
            ACCEPTED: "مقبول (مرسل)",
            READ: "تمت القراءة",
            FAILED: "فشل",
            DELIVERED: "تم التوصيل",
          },
        },
        RESULTS: {
          PLACEHOLDER: "نتائج",
          OPTIONS: {
            RESPONDED: "استجاب",
            CLICKED: "تم النقر عليه",
            CONVERTED: "تحويلها",
            OPT_OUT: "انسحب",
            BLANKS: "الفراغات",
          },
        },
        DOWNLOAD_CONTACTS: "تحميل جهات الاتصال",
      },
      TABLE_HEADERS: {
        USERS: "الوكلاء",
        STATUS: "حالة",
        RESULTS: "نتائج",
        DATE: "تاريخ",
      },
      STATUS: {
        FAILED: "فشل",
        SENT: "مرسل",
        DELIVERED: "تم التوصيل",
        READ: "تمت القراءة",
      },
      RESULTS: {
        RESPONDED: "تم الرد",
        CLICKED: "تم النقر عليه",
        CONVERTED: "تم تحويله",
      },
      MOBILE_MESSAGE: {
        HEADER: "تم تحسين هذه الميزة لموقع سطح المكتب.",
        DESCRIPTION:
          "يعد عرض رسائل البث وتنزيلها أسهل بكثير على جهاز الكمبيوتر. ",
        VISIT: {
          1: "يزور ",
          2: "مٌتَّصِل",
          3: " على سطح المكتب الخاص بك",
        },
        SELECT: "قم بالإختيار",
        NAVIGATE: "التنقل",
        DONE: "منتهي",
      },
      STATUS_DESCRIPTION: {
        draft: "",
        scheduled: "تمت جدولة البث بنجاح",
        submitted: "تم إرسال البث بنجاح",
        in_review: "تتم مراجعة البث",
        processing: "تتم معالجة البث بنجاح",
        completed: "تم إرسال البث بنجاح",
        failed: "فشلت مراجعة البث",
        halted: "",
        invalidated: "",
      },
      SCHEDULED_IN: "تمت جدولة هذا البث في",
      SEND_DELETE: "يمكنك إما إرسال البث الآن أو حذفه من هنا",
      CANCEL_BROADCAST: "إلغاء البث",
      SEND_BROADCAST: "أرسل البث الآن",
      SCHEDULED_FOR: "المقررلـ{{date}}",
      BROADCAST: "إذاعة",
      HOURS_AGO: "{{hours}} منذ ساعات",
      GO_TO_CHAT: "اذهب للمحادثة",
    },
    CREATE_POPUP: {
      TITLE: "إنشاء البث",
      SUBTITLE: "أنشئ بثًا جديدًا لبدء استخدامه",
      EDIT_TITLE: "تحرير مسودة البث",
      EDIT_SUBTITLE: "قم بتحرير مسودة البث الخاصة بك لبدء استخدامها",
      BROADCAST_DETAILS: "تفاصيل البث",
      BROADCAST_DETAILS_SUBTITLE: "قم بتسمية البث الخاص بك وحدد اللغة والقالب.",
      RECIPIENT_SELECTION: "اختيار المستلم",
      RECIPIENT_SELECTION_SUBTITLE: "حدد أهداف البث الخاصة بك هنا",
      SCHEDULE_OR_SEND_BROADCAST: "جدولة أو إرسال البث",
      SCHEDULE_OR_SEND_BROADCAST_SUBTITLE:
        "أرسل البث الخاص بك أو قم بجدولته هنا",
      TEMPLATE_VARIABLES: "متغيرات القالب",
      NO_TEMPLATES_FOR_LANGUAGE: "لا توجد قوالب متاحةلـ{{language}} لغة",
      TITLES: {
        BROADCAST_NAME: "اسم البث",
        LANGUAGE: "لغة",
        SELECT_TEMPLATE: "حدد قالب",
        PREPARE_DRAFT: "هل تريد إعداد البث كمسودة؟",
      },
      SEARCH_TEMPLATES: "البحث عن طريق معرف القالب",
      SELECT_TARGET: "حدد الهدف",
      RESET_TARGETS: "إعادة تعيين الأهداف",
      CLEAR_WARNING:
        "سيؤدي حذف نوع الهدف المحدد إلى إزالة كافة الأهداف المحددة منه. ",
      ALL_CONTACTS: "كل الاتصالات",
      CONTACTS: "جهات الاتصال",
      LAST_ASSIGNED_TO_AGENT: "آخر تعيين للوكيل",
      CUSTOMER_CREATED: "تم إنشاء العميل",
      ALL_CLOSED_CHATS: "جميع المحادثات المغلقة",
      TAGS: "العلامات",
      LAST_ASSIGNED_TO_TEAMS: "آخر تعيين للفرق",
      COUNTRY: "دولة",
      ALL_UNREAD_CHATS: "جميع المحادثات غير المقروءة",
      CUSTOM_FIELDS: "الحقول المخصصة",
      LAST_ACTIVITY: "النشاط الاخير",
      WHATSAPP: "واتساب",
      FILTER_OPTED_OUT: "تم إلغاء الاشتراك في التصفية",
      FILTER_OPTED_OUT_TOOLTIP:
        "ما إذا كان سيتم تصفية العملاء الذين تم إلغاء اشتراكهم من البث",
      INFO_TEMPLATE_VARIABLES:
        "يمكنك استخدام المتغيرات التالية في القالب الخاص بك",
      SELECT_DO_NOT_TARGET: "حدد عدم الاستهداف",
      SELECT_CONTACTS: "حدد جهات الاتصال",
      SELECT_TAGS: "حدد العلامات",
      SELECT_CUSTOM_FIELD: "حدد حقل مخصص",
      SELECT_AGENT: "حدد الوكيل",
      SELECT_TEAMS: "حدد الفرق",
      SELECT_COUNTRIES: "اختر البلدان",
      SEARCH: "بحث",
      YES: "نعم",
      NO: "لا",
      IF: "لو",
      IS: "يكون",
      ARE: "نكون",
      TEXT: "نص",
      URL: "عنوان URL",
      CHECKED: "التحقق",
      UNCHECKED: "غير محدد",
      TEAM_MEMBERS: "أعضاء الفريق",
      SELECT_TIME: "حدد الوقت",
      SELECT_DATE: "حدد تاريخ",
      ON: "على",
      AT: "في",
      BETWEEN_DATES: "بين {{startDate}} و {{endDate}}",
      COUNTRIES: "بلدان",
      ALL_COUNTRIES: "كل البلدان",
      SELECTED_COUNTRIES: "بلدان مختارة",
      SEARCH_COUNTRIES: "بحث في البلدان",
      SEND_TO_TARGET: "إرسال إلى الهدف",
      NOT_SEND_TO_TARGET: "لا ترسل إلى الهدف",
      SCHEDULE_OR_SEND: "جدولة أو إرسال",
      SCHEDULE_TO_SENT_AT: "الجدول الزمني للإرسال في {{time}}",
      BROADCAST_SUMMARY: "ملخص البث",
      TAGED_CONTACTS: "{{tagged}} تم وضع علامة على جهات الاتصال",
      TOTAL_N_CONTACTS: "المجموع {{count}} جهات الاتصال",
      N_CONTACTS: "{{count}} جهات الاتصال",
      UPLOAD_IMAGE: "تحميل الصور",
      UPLOAD_VIDEO: "رفع فيديو",
      UPLOAD_DOCUMENT: "تحميل الوثيقة",
      CHANGE_IMAGE: "تغيير الصورة",
      CHANGE_VIDEO: "تغيير الفيديو",
      CHANGE_DOCUMENT: "تغيير المستند",
      CONFIRM_TITLE: "تأكيد إرسال البث",
      CONFIRM_BODY:
        "هل أنت متأكد أنك تريد إرسال القالب <0>{{template_name}}</0>لـ<0>{{count}} عملاء</0>؟",
      DELETED_TEMPLATE: "لم يتم العثور على القالب",
      DELETED_TEMPLATE_BODY:
        'القالب الذي حددته سابقًا إما تمت إزالته أو رفضه.  <0>"تفاصيل البث"</0> للمضي قدمًا.',
      ALERT_MOBILE: "يعد إنشاء البث أمرًا معقدًا من شاشة الهاتف المحمول. ",
      SELECT_FUTURE_DATE: "يجب عليك تحديد وقت في المستقبل",
      SUMMARY: {
        BROADCAST_NAME: "اسم البث: ",
        LANGUAGE: "لغة: ",
        BROADCAST_SCHEDULE: "من المقرر أن ترسل في ",
        TEMPLATE_NAME: "اسم القالب: ",
      },
      BUTTONS: {
        VIEW: "عرض",
        CONTINUE_DRAFT: "متابعة المسودة",
        CANCEL: "إلغاء",
        BACK: "خلف",
        NEXT: "التالي",
        SEND_NOW: "ارسل الان",
        SENDING: "إرسال...",
        SENT: "مرسل",
        CREATE: "إنشاء",
        CREATING: "يتم...",
        CREATED: "تم",
        SAVING: "يتم...",
        SAVED: "تم",
        ERROR: "خطأ",
        SCHEDULE: "جدول",
        RESCHEDULE: "إعادة جدولة",
        CONFIRM_SCHEDULE: "يتأكد",
        UNSCHEDULE: "إلغاء الجدول الزمني",
        ADD_TO_FILTERS: "أضف إلى المرشحات",
        SELECT_TEMPLATE: "حدد قالب",
      },
    },
  },
  QUICK_REPLIES: {
    HEADER: "ردود سريعة",
    SUBTITLE:
      "قم بالرد على العملاء بشكل أسرع من خلال إعداد الرسائل المعدة مسبقًا.",
    CREATE_BTN: "إنشاء الرد السريع",
    NO_TEMPLATES: "ليس لديك قوالب حتى الآن",
    TYPE_PLACEHOLDER: "جميع أنواع الردود السريعة",
    NO_QR_MATCH: "لا يوجد ردود تطابق المعلومات اليت قمت بإدخالها",
    TYPE_OPTIONS: {
      TEXT: "نص",
      VIDEO: "فيديو",
      IMAGE: "صورة",
      // AUDIO: "ملف صوتى",
      DOCUMENT: "وثيقة",
    },
    SEARCH_PLACEHOLDER: "بحث",
    TABLE_HEADERS: {
      SHORT_CODE: "رمز قصير",
      MESSAGE: "رسالة",
      KEYWORDS: "الكلمات الدالة",
      AVAILABLE_TO: "متاحة لل",
      ACTION: "إجراءات",
    },
    AVAILABLE_TO: {
      ALL: "الجميع",
      TEAMS: "فرق",
      CREATOR: "مٌنشئها فقط",
      AVAILABLE_TO_ALL: "متاحة للجميع",
      AVAILABLE_TO_CREATOR: "متاحة لمٌنشئها فقط",
      AVAILABLE_TO_TEAMS: "متاحة لفريق مٌحدد",
    },
    CREATE_POPUP: {
      MOBILE_TITLE: "إنشاء الرد السريع",
      TITLE: "إنشاء رد سريع جديد",
      EDIT_TITLE: "تحرير الرد السريع",
      LABELS: {
        SHORT_CODE: "رمز قصير",
        KEYWORDS: "الكلمات الدالة",
        BODY_TEXT: "نص الرسالة",
        AVAILABLE_TO: "متاحة لل",
      },
      SELECT_TEAMS: "حدد الفرق",
      ERRORS: {
        SHORT_CODE: "مطلوب رمز قصير",
        SHORT_CODE_LENGTH: "يجب أن يكون الرمز القصير 50 ​​حرفًا على الأكثر",
        BODY_TEXT: "النص الأساسي مطلوب",
        BODY_TEXT_LENGTH: "يجب أن يكون النص الأساسي 1024 حرفًا على الأكثر",
        KEYWORDS: "الكلمات الرئيسية مطلوبة",
      },
      BUTTONS: {
        CANCEL: "إلغاء",
        SAVE: "حفظ",
        SAVING: "إنقاذ...",
        SAVED: "أنقذ",
        UPDATE: "تحديث",
        UPDATING: "جارٍ التحديث...",
        UPDATED: "محدث",
        ERROR: "خطأ",
      },
    },
  },
  TAGS: {
    HEADER: "العلامات",
    SUBTITLE: "إنشاء وإدارة العلامات هنا",
    CREATE_BTN: "إضافة علامة جديدة",
    ZERO_TAGS: "لا يوجد أي علامات",
    SEARCH_PLACEHOLDER: "البحث عن العلامات",
    TABLE_HEADERS: {
      NAME: "اسم",
      DESCRIPTION: "وصف",
      CREATED_BY: "انشأ من جانب",
      NO_OF_CONTACTS: "عدد جهات الاتصال",
      CREATED_ON: "تم إنشاؤها في",
      ACTION: "إجراءات",
    },
    CREATE_POPUP: {
      TITLE: "إضافة علامة",
      EDIT_TITLE: "تحديث العلامة",
      LABELS: {
        TAG_NAME: "اسم العلامة",
        DESCRIPTION: "وصف",
        COLOR: "لون",
      },
      PLACEHOLDERS: {
        TAG_NAME: "أدخل اسم العلامة هنا",
        DESCRIPTION: "أدخل وصف العلامة هنا",
        COLOR: "العلامة1",
      },
      ERRORS: {
        TAG_NAME: "اسم العلامة مطلوب",
        DESCRIPTION: "وصف العلامة مطلوب",
        COLOR: "اللون مطلوب",
      },
      BUTTONS: {
        CANCEL: "إلغاء",
        ADD_TAG: "إضافة علامة",
        ADDING: "جارٍ الإضافة...",
        ADDED: "تمت الإضافة",
        UPDATE_TAG: "تحديث العلامة",
        UPDATING: "جارٍ التحديث...",
        UPDATED: "محدث",
        ERROR: "خطأ",
        VIEW_CONTACT: "عرض جهات الاتصال",
      },
    },
  },
  RULES: {
    HEADER: "قواعد",
    SUBTITLE: "اتخاذ الإجراءات تلقائيًا بشأن الأحداث في مساحة العمل الخاصة بك",
    CREATE_BTN: "قاعدة جديدة",
    ZERO_RULES: "لا يوجد قواعد",
    ZERO_DISABLED_RULES: "لا يوجد قواعد معطلة",
    ACTIVE: "نشط",
    DISABLED: "غير نشط",
    SEARCH: "بحث",
    RULE_NAME: "اسم القاعدة",
    DESCRIPTION: "وصف",
    STATUS: "حالة",
    ACTION: "إجراءات",
    RULE: "قاعدة",
    CREATE_POPUP: {
      CREATE_TITLE: "إنشاء قاعدة جديدة",
      EDIT_TITLE: "تحديث القاعدة",
      VIEW_TITLE: "عرض القاعدة",
      SUBTITLE:
        "إدارة الفريق من خلال إعادة تسميته وتغيير وصفه وإضافة أعضاء الفريق أو إزالتهم.",
      LABELS: {
        RULE_NAME: "اسم القاعدة",
        RULE_DESCRIPTION: "وصف القاعدة",
        CHANNEL: "قناة",
        TRIGGERS: "محفزات",
        WHEN: "متى",
        IF: "لو",
        THEN: "ثم"
      },
      PLACEHOLDERS: {
        RULE_NAME: "أضف اسم القاعدة",
        RULE_DESCRIPTION: "إضافة وصف القاعدة",
        SELECT: "قم بالإختيار",
        ENTER_TEXT: "أدخل النص هنا...",
        SELECT_AGENT: "حدد الوكيل",
        ENTER_URL: "اختر ويب هوك",
        ENTER_REASON: "أدخل السبب",
        SELECT_PLATFORMS: "حدد المنصات",
        SELECT_TYPES: "حدد الأنواع",
      },
      CHANNELS: {
        WHATSAPP: "واتساب",
        INSTAGRAM: "انستغرام",
        FACEBOOK: "فيسبوك",
      },
      MESSAGE_TYPE: {
        INCOMING: "رسالة واردة",
        OUTGOING: "رسالة صادرة",
      },
      CHAT_TYPE: {
        OPENED: "فتح المحادثة",
        ASSIGNED: "المحادثة المخصصة",
        CLOSED: "محادثة مغلقة",
        SNOOZED: "المحادثة المؤجلة",
      },
      CONDITION_NAME_OPTIONS: {
        TEXT_IN_MESSAGE: "النص في الرسالة",
        MESSAGE_TYPE: "نوع الرسالة",
        MESSAGE_TIME: "وقت الرسالة",
        CUSTOMER_CHANNEL_ID: "معرف قناة العميل",
        CUSTOMER_PLATFORM: "منصة العملاء",
        CUSTOMER_DISPLAY_NAME: "الاسم المعروض للعميل",
        DATE_TIME: "تاريخ الرسالة"
      },
      PLATFORM_OPTIONS: {
        WHATSAPP: "واتساب",
        INSTAGRAM: "انستغرام",
        SMS: "رسالة قصيرة",
        TELEGRAM: "برقية",
        RCS: "RCS",
        FACEBOOK: "فيسبوك",
        PHONE: "هاتف",
        AVOCADO: "مٌتَّصِل",
      },
      TYPE_OPTIONS: {
        TEXT: "نص",
        IMAGE: "صورة",
        AUDIO: "صوتي",
        VOICE: "صوت",
        VIDEO: "فيديو",
        STICKER: "ملصق",
        DOCUMENT: "وثيقة",
        LOCATION: "موقع",
        INTERACTIVE: "تفاعلية",
        TEMPLATE: "نموذج",
        CONTACTS: "جهات الاتصال",
      },
      CONDITION_OPERATOR_OPTIONS: {
        EQ: "يساوي",
        NE: "لا يساوي",
        "!EQ": "لا يساوي",
        GT: "أكثر من",
        LT: "أقل من",
        GTE: "أكبر من أو يساوي",
        LTE: "اصغر من او يساوي",
        SS: "يتضمن",
        "!SS": "لا يحتوي على",
        SW: "تبدأ بـ",
        "!SW": "لا يبدأ ب",
      },
      ACTION_OPTIONS: {
        ASSIGN_CHAT: "تعيين المحادثة",
        ADD_TAG: "إضافة علامة",
        REMOVE_TAG: "إزالة العلامة",
        MENTION: "أُذكر",
        WEBHOOK: "خطاف ويب",
        SNOOZE_CONVERSATION: "أجِل المحادثة",
        CLOSE_CONVERSATION: "أغلق المحادثة",
        ASSIGN_LAST_AGENT_TEAM: "تعيين لآخر عميل/فريق",
      },
      SNOOZE_CONVERSATION_OPTIONS: {
        NEXT_15_MINUTES: "الـ 15 دقيقة القادمة",
        NEXT_HOUR: "الساعة القادمة",
        NEXT_3_HOURS: "3 ساعات القادمة",
        NEXT_6_HOURS: "الـ 6 ساعات القادمة",
        NEXT_DAY: "اليوم التالي",
      },
      IN: "في",
      AND: "و",
      OR: "أو",
      BUTTONS: {
        ADD_TRIGGER: "إضافة أحداث الزناد",
        ADD_CONDITION: "إضافة شرط",
        ADD_ACTION: "أضف الإجراء",
        CANCEL: "إلغاء",
        CREATE: "إنشاء قاعدة جديدة",
        CREATING: "يتم...",
        CREATED: "تم",
        ERROR: "خطأ",
      },
      INVALID_URL: "URL غير صالح",
      EXISTING_WEBHOOK: "الرد التلقائي على الويب موجود بالفعل",
    },
  },
  CSAT: {
    HEADER: "تقارير رضا العملاء",
    DURATION: "مدة",
    DURATION_OPTIONS: {
      LAST_7_DAYS: "اخر 7 ايام",
      LAST_30_DAYS: "آخر 30 يومًا",
      LAST_6_MONTHS: "آخر 6 أشهر",
      LAST_3_MONTHS: "آخر 3 أشهر",
      LAST_YEAR: "العام الماضي",
      CUSTOM_DATE_RANGE: "النطاق الزمني المخصص",
    },
    SELECT_BY_DATE: "اختر حسب التاريخ",
    SELECT_AGENTS: "حدد الوكلاء",
    AGENTS: {
      AGENT_1: "الوكيل 1",
      AGENT_2: "الوكيل 2",
      AGENT_3: "الوكيل 3",
      AGENT_4: "الوكيل 4",
      AGENT_5: "الوكيل 5",
    },
    DOWNLOAD_REPORTS: "تحميل التقارير",
    TOTAL_RESPONSES: "مجموع الردود",
    SATISFACTION_SCORE: "درجة الرضا",
    RESPONSE_RATE: "معدل الاستجابة",
    FEEDBACK: "تعليق",
    VERY_SAD: "حزين جدا",
    SAD: "حزين",
    NEUTRAL: "حيادي",
    GOOD: "جيد",
    HAPPY: "سعيد",
    CONVERSATION_TRAFFIC: "حركة المحادثة",
    CONTACT: "اتصال",
    ASSIGNED_AGENT: "الوكيل المعين",
    RATING: "تقييم",
    LAST_MESSAGE: "اخر رسالة",
    SATISFIED: "راضي",
    AWESOME: "مذهل",
    EXCELLENT: "ممتاز",
  },
  AGENTS_TEAMS: {
    AGENTS_HEADER: "نظرة عامة على الوكلاء",
    TEAMS_HEADER: "نظرة عامة على الفرق",
    SELECT_TEAM: "اختر فريق",
    TEAMS: {
      TEAM_1: "الفريق 1",
      TEAM_2: "الفريق 2",
      TEAM_3: "الفريق 3",
      TEAM_4: "الفريق 4",
      TEAM_5: "الفريق 5",
    },
    CONVERSATIONS: "المحادثات",
    INCOMING_MESSAGES: "الرسائل الواردة",
    OUTGOING_MESSAGES: "الرسائل الصادرة",
    FIRST_RESPONSE_TIME: "أول وقت للاستجابة",
    RESOLUTION_TIME: "وفر الوقت",
    RESOLUTION_COUNT: "عدد التقارير",
    TOTAL: "في المجموع",
    AVG: "في المتوسط",
    CHART_SUBTITLE: "على أساس الفترة المختارة",
    JAN: "يناير",
    FEB: "فبراير",
    MAR: "مارس",
    APR: "أبريل",
    MAY: "مايو",
    JUN: "يونيو",
    JUL: "يوليو",
    AUG: "أغسطس",
    SEP: "سبتمبر",
    OCT: "أكتوبر",
    NOV: "نوفمبر",
    DEC: "ديسمبر",
    COUNT: "عدد:",
  },
  WORKSPACE_SETTINGS: {
    WORKSPACE_PROFILE: "بروفايل مساحة العمل",
    SUBTITLE: "إدارة إعدادات مساحة العمل الخاصة بك",
    PROFILE_PHOTO: "صورة الملف الشخصي",
    UPLOAD_IMAGE: "تحميل الصور",
    ORGANIZE_NAME: "اسم المنظمة",
    LEGAL_NAME: "الاسم القانوني",
    UPDATE: "تحديث",
  },
  USERS_SETTINGS: {
    USERS: "الوكلاء",
    SUBTITLE: "بإمكانك تعديل كل ما يخص أعضاء فريقك من هنا.",
    ADD_AGENT: "إضافة مستخدم جديد",
    ROLES: "الأدوار",
    TEAMS: "فرق",
    AVAILABLE: "متاح",
    ROLES_OPTIONS: {
      VIEW: "مشاهد فقط",
      AGENT: "وكيل",
      SUPERAGENT: "وكيل ثاني",
      FINANCE: "محاسب",
      DEVELOPER: "مطور",
      ADMIN: "مدير",
    },
    TEAMS_OPTIONS: {
      SALES: "فريق المبيعات",
      MARKETING: "فريق التسويق",
      SUPPORT: "يدعم",
    },
    AVAILABLE_OPTIONS: {
      ACTIVE: "نشيط",
      INACTIVE: "غير نشط",
      OFFLINE: "غير متصل بالإنترنت",
    },
    SEARCH_AGENTS: "بحث..",
    ROLE: "دور",
    LAST_SEEN_ON: "آخر ظهور في",
    LAST_SEEN: "{{date}} منذ",
    STATUS: "حالة",
    ACTION: "الإجراءات",
    EDIT: "تحرير",
    REVOKE: "إلغاء",
    ADD_USER: "إضافة مستخدم",
    ADDING: "جارٍ الإضافة...",
    ADDED: "تمت الإضافة",
    EDIT_USER: "تحرير العضو",
    ADD_USER_SUBTITLE: "إدارة ودعوة أعضاء الفريق إلى مساحة العمل الخاصة بك.",
    EDIT_USER_SUBTITLE: "تحرير أعضاء الفريق من مساحة العمل الخاصة بك.",
    USER_NAME: "اسم المستخدم",
    USER_EMAIL: "البريد الالكتروني للمستخدم",
    ACCESS_LEVEL: "دور العميل",
    OWNER_NOTE:
      "يمكن للمالك الوصول إلى كافة الوحدات داخل مساحة العمل ولديه التحكم الكامل في الإعدادات دون أي قيود.",
    MANAGER_NOTE:
      "يمكن للمديرين الوصول إلى جميع الوحدات المختلفة داخل مساحة العمل بما في ذلك الإعدادات. ",
    AGENT_NOTE:
      "يمكن للمديرين الوصول إلى جميع الوحدات المختلفة داخل مساحة العمل بما في ذلك الإعدادات. ",
    ADVANCED_RESTRUCTION: "إعادة الإعمار المتقدمة",
    RESTRICT_CONTACT_VISIBILITY: {
      TITLE: "تقييد رؤية جهة الاتصال",
      SUBTITLE: "يمكن لهذا المستخدم فقط عرض جهات الاتصال المخصصة له",
      OPTIONS: {
        ANYONE_IN_THE_USER_TEAM: "أي شخص في فريق المستخدم",
        USER: "مستخدم",
      },
    },
    RESTRICT_SHORTCUT_BUTTON: {
      TITLE: "تقييد زر الاختصار",
      SUBTITLE: "سيتم تعطيل زر الاختصارات في وحدة المراسلة لهذا المستخدم",
    },
    RESTRICT_DATA_EXPORT: {
      TITLE: "تقييد تصدير البيانات",
      SUBTITLE: "لن يتمكن هذا المستخدم من تصدير البيانات من مساحة العمل.",
    },
    RESTRICT_CONTACT_DELETION: {
      TITLE: "تقييد حذف جهة الاتصال",
      SUBTITLE: "لن يتمكن هذا المستخدم من حذف أية جهات اتصال من مساحة العمل.",
    },
    RESTRICT_WORKSPACE_SETTINGS_ACCESS: {
      TITLE: "تقييد الوصول إلى إعدادات مساحة العمل",
      SUBTITLE: "لن يتمكن هذا المستخدم من الوصول إلى إعدادات مساحة العمل.",
    },
    RESTRICT_INTEGRATION_SETTINGS_ACCESS: {
      TITLE: "تقييد الوصول إلى إعدادات التكامل",
      SUBTITLE: "لن يتمكن هذا المستخدم من الوصول إلى إعدادات التكامل.",
    },
    REVOKE_POPUP: {
      TITLE: "إبطال الوصول",
      SUBTITLE: "هل أنت متأكد أنك تريد إلغاء وصول هذا المستخدم؟",
      ASSIGN_TO_USER: "تعيين جهات الاتصال لمستخدم آخر",
      NOTE: "إذا قمت بإلغاء هذا المستخدم، فسيتم وضع علامة على جميع جهات الاتصال المخصصة لهذا المستخدم على أنها غير مخصصة.",
      REVOKE: "إلغاء",
      REVOKING: "يتم الإلغاء",
      REVOKED: "تم اﻹلغاء",
    },
    CANCEL: "إلغاء",
    UPDATE: "تحديث",
    UPDATING: "جارٍ التحديث...",
    UPDATED: "محدث",
    ERROR: "خطأ",
    DELETE: "حذف",
  },
  TEAMS_SETTINGS: {
    TEAMS: "فِرق",
    SUBTITLE:
      "قم بإنشاء فِرق وإدارتها لتجميع المستخدمين في مساحة العمل الخاصة بك.",
    ADD_TEAM: "إضافة فريق",
    ADDING: "جاري الإضافة...",
    ADDED: "تمت الإضافة",
    ERROR: "خطأ",
    SEARCH_TEAMS: "بحث الفِرق",
    TEAM_NAME: "اسم الفريق",
    TEAM_EMAIL: "البريد الإلكتروني",
    DESCRIPTION: "وصف",
    MEMBERS: "أعضاء",
    ACTION: "إجراءات",
    ADD_TEAM_SUBTITLE:
      "إضافة فريق من خلال تسميته وتغيير وصفه وإضافة أعضاء الفريق.",
    TEAM_NAME_PLACEHOLDER: "فريق المبيعات",
    TEAM_DESCRIPTION: "وصف الفريق",
    TEAM_DESCRIPTION_PLACEHOLDER: "أضف وصف الفريق",
    TEAM_MEMBERS: "أعضاء الفريق",
    TEAM_MEMBERS_PLACEHOLDER: "إضافة أعضاء الفريق",
    MANAGE_TEAM: "إدارة الفريق",
    MANAGE_TEAM_SUBTITLE:
      "إدارة الفريق من خلال إعادة تسميته وتغيير وصفه وإضافة أعضاء الفريق أو إزالتهم.",
    CANCEL: "إلغاء",
    SAVE: "حفظ",
    SAVING: "يتم...",
    SAVED: "تم",
  },
  CONTACTS_SETTINGS: {
    CONTACTS: "جهات الاتصال",
    SUBTITLE:
      "قم بإنشاء وإدارة حقول جهات الاتصال لحفظ معلومات إضافية حول جهات الاتصال الخاصة بك.",
    ADD_CONTACT: "إضافة جهة اتصال",
    ALL_CONTACTS: "كل جهات الاتصال",
    ARCHIVED: "مؤرشف",
    SEARCH_CONTACTS: "بحث",
    CHANNEL: {
      PLACEHOLDER: "قناة",
      OPTIONS: {
        WHATSAPP: "واتس اب",
        FACEBOOK: "فيسبوك",
        INSTAGRAM: "انستغرام",
      },
    },
    TAGS: {
      PLACEHOLDER: "العلامات",
      OPTIONS: {
        PREMIUM: "غالي",
        ASSIGNED: "مُكَلَّف",
        UNASSIGNED: "غير معين",
        TAG_3: "العلامة 3",
        TAG_4: "العلامة 4",
        TAG_5: "العلامة 5",
      },
    },
    DATE: {
      PLACEHOLDER: "قم بإختيار فترة زمينة",
      OPTIONS: {
        LAST_7_DAYS: "اخر 7 ايام",
        LAST_30_DAYS: "آخر 30 يومًا",
        LAST_6_MONTHS: "آخر 6 أشهر",
        LAST_3_MONTHS: "آخر 3 أشهر",
        LAST_YEAR: "العام الماضي",
        CUSTOM_DATE_RANGE: "النطاق الزمني المخصص",
      },
    },
    SELECT_BY_DATE: "اختر حسب التاريخ",
    UESRS: "المستخدمين",
    EMAIL: "بريد إلكتروني",
    ACTION: "إجراءات",
    UNARCHIVE: "إلغاء الأرشفة",
    DELETE: "حذف",
  },
  CUSTOM_ATTRIBUTES: {
    HEADER: "السمات المخصصة",
    SUBTITLE:
      "إنشاء واصفات فريدة والإشراف عليها لتعزيز المعلومات المرتبطة بالأصول، وتوسيع معناها إلى ما هو أبعد من السمات القياسية",
    CONCERSATION: "محادثة",
    ZERO_CUSTOMS: 'لا يوجد سمات مميزة لعرضها',
    CONTACT: "اتصال",
    TYPE: "النوع",
    KEY: "مفتاح",
    ADD_CUSTOM_ATTR: "إضافة سمة مخصصة",
    SEARCH_PLACEHOLDER: "بحث",
    CREATE_POPUP: {
      TITLE: "إضافة سمة مخصصة",
      UPDATE_TITLE: "تحرير السمة المخصصة",
      APPLY_TO: "ينطبق على",
      DISPLAY_NAME: "اسم العرض",
      PLACEHOLDER_KEY: "أدخل مفتاح السمة المخصصة",
      PLACEHOLDER_DNAME: "أدخل اسم عرض السمة المخصصة",
      PLACEHOLDER_TYPE: "حدد نوع السمة المخصصة",
      PLACEHOLDER_APPLIES_TO: "حدد سمة مخصصة تنطبق على",
      DISCRIPTION_PH: "أدخل وصف السمة المخصصة",
      TEXT: "نص",
      URL: "عنوان URL",
      SWITCH: "يُحوّل",
      CHECKBOX: "خانة الاختيار",
      CHIPS: "رقائق",
      DATE: "تاريخ",
      TIME: "وقت",
      TOOLTIP: {
        KEY: "لا يمكن تحديث المفتاح",
        TYPE: "لا يمكن تحديث النوع",
      },
      SUBMIT: {
        ADD: "إضافة سمة مخصصة",
        UPDATE: "تحرير",
        ADDING: "إضافة سمة مخصصة...",
        UPDATING: "جارٍ التحرير...",
        ADDED: "تمت إضافة السمة المخصصة",
        UPDATED: "تم التحرير",
        ERROR: "خطأ",
      },
    },
    TABLE: {
      URL: "رابط",
      TEXT: "نص",
      SWITCH: "مٌبدل",
      CHECKBOX: "خانة إختيار",
      CHIPS: "رقائق",
      DATE: "تاريخ",
      TIME: "وقت",
    },
  },
  CONVERSATIONS: {
    CONVERSATIONS: "المحادثات",
    DURATION: "مدة",
    DURATION_OPTIONS: {
      LAST_7_DAYS: "اخر 7 ايام",
      LAST_30_DAYS: "آخر 30 يومًا",
      LAST_6_MONTHS: "آخر 6 أشهر",
      LAST_3_MONTHS: "آخر 3 أشهر",
      LAST_YEAR: "العام الماضي",
      CUSTOM_DATE_RANGE: "النطاق الزمني المخصص",
    },
    SELECT_BY_DATE: "اختر حسب التاريخ",
    GROUP_BY: "",
    GROUP_BY_OPTIONS: {
      DAY: "يوم",
      WEEK: "أسبوع",
      MONTH: "شهر",
    },
    DOWNLOAD_REPORTS: "تحميل التقارير",
  },
  OVERVIEW: {
    OVERVIEW: "ملخص",
    CONVERSATIONS_STATS: "إحصائيات المحادثات",
    AGENTS_STATUS: "حالة الوكلاء",
    LIVE: "مباشر",
    DOWNLOAD_REPORTS: "تحميل التقارير",
    OPEN: "المحادثات المفتوحة",
    UNATTENDED: "غير المراقب",
    UNASSIGNED: "غير معين",
    ONLINE: "متصل",
    CONVERSATION_TRAFFIC: "حركة المحادثة",
    CONVERSATIONS_BY_AGENTS: "المحادثات من قبل الوكلاء",
    USERS: "الوكلاء",
    ROLE: "دور",
    TEAM: "فريق",
    ROLES: {
      OWNER: "مالك",
      MANAGER: "مدير",
      AGENT: "وكيل",
    },
    TEAMS: {
      SALES: "فريق المبيعات",
      SUPPORT: "فريق الدعم",
      MARKETING: "فريق التسويق",
    },
  },
  DASHBOARD: {
    ANALYTICS: "التحليلات",
    SUBTITLE: "عرض تقارير النظام الأساسي الخاص بك هنا",
    OVERVIEW: "ملخص",
    CSAT_REPORTS: "تقارير رضا العملاء",
    AGENTS: "الوكلاء",
    TEAMS: "فرق",
    CONVERSATIONS: "المحادثات",
  },
  MEDIA_LIBRARY: {
    HEADER: "مكتبة الوسائط",
    ASSET: "أصل",
    SUBTITLE: "قم بتحميل وعرض جميع ملفات الوسائط في وجهة واحدة",
    UPLOAD_1: "انقر للتحميل ",
    UPLOAD_2: "أو قم بسحب وإسقاط ملف هنا",
    UPLOAD_2_MOBILE: "ملف هنا",
    UPLOAD_SUBTITLE:
      "لا يزيد حجم ملف الصور والصوت والفيديو والمستندات عن 10 ميجابايت",
    SORT_BY: "ترتيب حسب",
    OLDEST: "الأقدم",
    LATEST: "أحدث",
    MEDIA_TYPES: {
      ALL: "الجميع",
      IMAGES: "الصور",
      AUDIO: "صوتيات",
      VIDEO: "فيديو",
      DOCUMENTS: "وثائق",
    },
    INFO: "معلومات",
    VIEW: "عرض",
    COPY_URL: "نسخ الرابط",
    DELETE: "حذف",
    B: "{{size}} ب",
    KB: "{{size}} كيلو بايت",
    MB: "{{size}} ميغابايت",
    GB: "{{size}} غيغابايت",
    CREATED_AT: "أنشئت في",
    CREATED_BY: "انشأ من قبل",
    UPLOAD_FILE: "رفع ملف",
    MEDIA_TITLE: "عنوان الوسائط",
    CAPTION: "التسمية التوضيحية",
    UPLOADING: " • جارٍ التحميل...",
    TITLE: "عنوان",
    SIZE: "الحجم",
    MODIFIED_AT: "أخر تعديل",
    ACTIONS: "أجراءات",
    CANT_UPLOAD: "لا يمكن رفع هذا الملف",
    UPLOAD_WARNING: "لا يمكن رفع ذلك الملف نظرًا لزيادة حجمه عن 10 ميجا بايتس",
  },
  WHATSAPP_FLOW: {
    HEADER: "تدفق الواتساب",
    SUBTITLE: "قم بإنشاء وإدارة تدفق WhatsApp هنا",
    SEARCH_WA_FLOWS: "أبحث عن الردود",
    FILTERS: {
      STATUS: {
        OPTIONS: {
          DRAFT: "Draft",
          PUBLISHED: "Published",
        },
      },
    },
    TABLE: {
      FLOW_NAME: "إسم الرد",
      FLOW_ID: "مُعرف الرد",
      STATUS: "الحالة",
      LAST_UPDATE: "آخر تحديث",
    },
    CREATE_FLOW: "إنشاء الرد",
    CREATE_WHATSAPP_FLOW: "إنشاء رد WhatsApp",
    CREATE_WHATSAPP_FLOW_SUBTITLE: "قم بإنشاء رد WhatsApp هنا",
    NO_FLOW_YET: "ليس لديك ردود بعد",
    NO_FLOW_MATCH: "ليس لديك ردود توافق البيانات التي أدخلتها",
    SAMPLE_FLOW: "رد العينة",
    DRAFT: "مسودة",
    SELECT_CATEGORIES: "حدد الفئات",
    SELECT_TEMPLATE: "حدد القالب (اختياري)",
    CANCEL: "إلغاء",
    CREATE: "إنشاء",
    EDITOR: "محرر",
    RUN: "يجري",
    SAVE: "حفظ",
    DONE: "منتهي",
    CONTINUE: "متابعة",
    PREVIEW: "معاينة",
    FIRSTNAME: "الاسم الأول",
    LASTNAME: "اسم العائلة",
    PHONE_NUMBER: "رقم التليفون",
    SIGNUP_TITLE: "انضم إلى قائمة الانتظار الآن!",
    SIGNUP_SUBTITLE: "الرجاء إدخال التفاصيل أدناه",
    SIGNUP_TITLE_1: "قبل ان تذهب",
    SIGNUP_SUBTITLE_1: "كيف سمعت عنا؟",
    TEMPLATES: {
      NONE: "بدون",
      BOOK_TABLE: "طاولة كتب",
      COMPLETE_OUR_QUIZ: "أكمل اختبارنا",
      COMPLETE_SIGNUP: "الاشتراك الكامل",
      GET_FEEDBACK: "احصل على تعليقات",
      GET_QUOTE: "الحصول على الاقتباس",
      GET_SUPPORT: "احصل على الدعم",
      REGISTER_ACCOUNT: "سجل للحصول على حساب",
      REGISTER_EVENT: "سجل للحدث",
      SIGNIN: "تسجيل الدخول",
      UPDATE_PREFERENCES: "تحديث التفضيلات",
    },
    CHANGES_SAVED: "تم حفظ التعديلات",
  },
  COMMON: {
    ACTIONS_BTN: {
      VIEW: "عرض",
      EDIT: "تحرير",
      NEXT: "التالى",
      DONE: "تم",
      YES: "حذف",
      NO: "إلغاء",
      DELETE: "حذف",
    },
    DELETE_CONFIRMATION_DIALOGUE: {
      TITLE: 'حذف <0>"{{name}}"</0>',
      BODY: 'هل أنت متأكد أنك تريد حذف <0>"{{name}}"</0> ؟ ',
    },
    DELETE__DIALOG_2: {
      TITLE: "هل أنت متأكد؟",
      BODY: " أنت على وشك حذف هذا {{item}}. ",
      CANCEL: "إلغاء",
      DELETE: "حذف",
    },
    PAGINATION: {
      TITLE: "عرض من {{start}}لـ{{end}} من أصل {{total}} من البيانات",
      ZERO_ENTERIES: "عرض 0 من 0 إدخالات",
      TITLE_MOBILE: "{{start}}-{{end}} من {{total}} عنصر",
      ZERO_ENTERIES_MOBILE: ". من . إدخالات",
    },
    WARNING: {
      TITLE: "تحذير",
      CANCEL: "إلغاء",
      CONTINUE: "متابعة",
    },
    MSB: {
      SEARCH: "بحث...",
      SELECT: "قم بالإختيار",
    },
    STATUS: {
      COMPLETED: "مكتمل",
      FAILED: "فشل",
      IN_PROGRESS: "في تَقَدم",
    },
    FILTER: "بحث",
    CLOSE: "إغلاق",
    SEARCH: "بحث",
    RESET: "اعادة ضبط",
    SECONDS: "ثواني",
    MINUTES: "دقائق",
    HOURS: "ساعات",
    SECONDS_PLACEHOLDER: "ثانية = 60",
    MINUTES_PLACEHOLDER: "الدقائق=60",
    HOURS_PLACEHOLDER: "الساعات = 12",
    SUBMIT: "يُقدِّم",
    SHARE: "يشارك",
    CANCEL: "إلغاء",
    UPLOAD: "رفع",
    SUBMITTING: "تقديم...",
    SUBMITTED: "مُقَدَّم",
    ERROR: "خطأ",
    CLEAR: "واضح",
    CLOSE_WARNING:
      "أنت على وشك إغلاق النافذة الحالية، وسيتم فقدان كافة التغييرات. ",
    CONTINUE: "متابعة",
    SKIP: "يتخطى",
    CONFIRM: "يتأكد",
    CREATED_AT_DATE: "أنشئت في {{date}}",
    CHANGE: "يتغير",
    UNAVAILABLE: "غير متوفره",
    CREATED_AT: "أنشئت في {{date}}",
    EMAIL: "بريد إلكتروني",
    NAME: "اسم",
    SEND: "إرسال",
    BACK: "العودة",
    ADD: "إضافة",
    SAVE: "حفظ",
    ALL: "الكل",
    AGENTS: "الوكلاء",
    TEAMS: "الفرق",
    TODAY: "اليوم",
    YESTERDAY: "الأمس",
    NO_RESULTS_FOUND: "لا يوجد نتائج لما تبحث عنه",
    KINDLY_TRY_AGAIN: "جرب مرة أخرى ببحث مختلف",
  },
  VALIDATIONS: {
    CONFIRM_PASSWORD:
      "كلمة المرور الخاصة بك وكلمة المرور التأكيدية غير متطابقتين.",
    INVALID_EMAIL_PASSWORD: "البريد الإلكتروني أو كلمة السر خاطئة",
    EMAIL_EXISTS: "البريد الالكتروني موجود مسبقا",
    TOKEN_EXPIRED_RESET_PASSWORD: "الرمز منتهي الصلاحية/غير صالح. ",
    NOT_REGISTERED: "لا توجد حسابات تطابق هذا البريد الإلكتروني. ",
    INVALID_EMAIL: "هذا بريد إلكتروني غير صالح",
    INVALID_PASSWORD: "كلمة السر يجب أن تتكون من 8 أحرف على الأقل",
    EMPTY_FIELD: "لا يمكن ترك هذا الحقل فارغًا",
    CONTACTS: {
      MIN_NAME: "يجب أن يتكون الإسم من 5 حروف فأكثر",
      INVALID_PHONE_NUM: "رقم هاتف غير صالح",
    },
    TEMPLATES: {
      EMPTY_MEDIA_TYPE: "يرجى إختيار نوع الوسائط.",
      EMPTY_TOGGLE_BTNS: "يرجى إختيار من بين الخيارات المتاحة.",
      EMPTY_BTN_TYPE: "يرجى إختيار نوع الزر.",
      INVALID_TEMPLATE_ID:
        "مُعرِّف النموذج يجب أن يتكون من الحروف والأرقام فقط",
      TEMP_ID_LESS_THAN_5: "يجب أن لا يقل إسم مٌعرّف النموذج عن 5 أحرف",
    },
  },
  CHATGPT: {
    CREATE_POPUP: {
      TITLE: "ChatGPT",
      SUBTITLE:
        "يتيح لك ChatGPT اقتراح ردود سريعة بناءً على آخر 20 رسالة من المحادثات.",
      SUBTITLE1:
        "ويتم تدريبه أيضًا على قاعدة المعرفة لتقديم المزيد من الاستجابات السياقية للتعلم من بيانات المنظمة.",
      OPEN_API_KEY: "افتح واجهة برمجة التطبيقات",
      PLACEHOLDER_OPEN_API_KEY: "حامل yikk3k-4sdfhh-34fwef-XXXXXX-XXX",
      CHATGPT_CANT_BE_ENABLED: "* لا يمكن تفعيل ChatGPT بعد",
      OPTION_AGENT: {
        TITLE: "وكيل",
        PLACEHOLDER_AVAILABLE: " متاحة لل",
        AGENT: "عملاء",
        SUPERAGENT: "وكيل أعلى",
        ADMIN: "مدير",
      },
      BUTTONS: {
        SAVE: "حفظ",
        CANCEL: "إلغاء",
      },
    },
  },
  DIALOGFLOW: {
    CREATE_POPUP: {
      TITLE: "تدفق الحوار",
      SUBTITLE:
        "يتيح لك Dialogflow التكامل مع برنامج المحادثة الآلي المخصص الخاص بك. ",
      CONNECT: "روبوت المحادثة المخصص",
      DIALOGFLOW_CANT_BE_ENABLED: "* لا يمكن تمكين Dialogflow بعد",
      OPTION_CONNECT: {
        PLACEHOLDER_CONNECT: "كل الشات بوت",
        UNANSWERED: "المحادثات التي لم يتم الرد عليها",
        ASSIGNED_CHAT: "المحادثات المخصصة",
        UNASSIGNED_CONTACTS: "جهات الاتصال غير المعينة",
        CLOSED_CHATS: "المحادثات المغلقة",
        SNOOZED_CHATS: "المحادثات المؤجلة",
        ARCHIVED_CONTACTS: "جهات الاتصال المؤرشفة",
      },
      DRAG_DROP: {
        HEADER: "",
        TITLE: "قم بإسقاط تكوين حساب الخدمة الخاص بك JSON هنا",
        BUTTONS: {
          SELECTED_FILES: "اختر الملفات",
        },
      },
      BUTTONS: {
        SAVE: "حفظ",
        CANCEL: "إلغاء",
      },
    },
  },
  GOOGLE_TRANSLATE: {
    CREATE_POPUP: {
      TITLE: "جوجل المترجم",
      SUBTITLE: "قم بترجمة رسائل عملائك بسرعة بمساعدة ترجمة Google.",
      GOOGLE_API_KEY: "مفتاح واجهة برمجة تطبيقات جوجل",
      PLACEHOLDER_GOOGLE_API_KEY: "ayjkk3k-4sdfhh-34fwef-XXXXXX-XXX",
      TRANSLATE_CANT_BE_ENABLED: "* لا يمكن تمكين الترجمة بعد",
      BUTTONS: {
        SAVE: "حفظ",
        CANCEL: "إلغاء",
      },
    },
  },
  CSML: {
    HEADER: "CSML",
    SUBTITLE:
      "قم بدمج روبوتات المحادثة CSML في النظام الأساسي الخاص بك لأتمتة دعم العملاء وعمليات المبيعات",
    CSML_CANOT_BE_ENABLED: "* لا يمكن تفعيل CSML بعد",
    CSML_TABLE: {
      TITLE: "اسم البوت",
      BOT_DESCRIPTION: "وصف البوت",
    },
    BUTTONS: {
      SAVE: "التحقق من صحة وحفظ",
    },
  },
  WHATSAPP_INTEGRATION: {
    HEADER: "التكامل واتس اب",
    SUBTITLE: "إدارة واجهة برمجة تطبيقات المراسلة الخاصة بـ WhatsApp",
    WHATSAPP_INFO: {
      HEADER: "معلومات واتس اب",
      SUBTITLE: "عرض وتكوين حساب WhatsApp Business الخاص بك",
      ACCOUNT_CONFIG: {
        HEADER: "تكوين الحساب",
        LEGAL_BUSINESS_NAME: "اسم العمل القانوني:",
        BUSINESS_MANAGER_ID: "معرف مدير الأعمال:",
      },
      WHATSAPP_BUSINESS_PROFILE: {
        HEADER: "الملف الشخصي واتساب للأعمال",
        USER_PROFILE: {
          HEADER: "ملف تعريفي للمستخدم",
          PROFILE_PHOTO: "صور بفوريلي",
          ABOUT_TEXT: "حول النص",
        },
        BUSINESS_PROFILE: {
          HEADER: "الملف التجاري",
          ADDRESS: "عنوان",
          DESCRIPTION: "وصف",
          EMAIL: "بريد إلكتروني",
          WEBSITE_PRIMARY: "الموقع الإلكتروني (أساسي)",
          WEBSITE_SECONDARY: "موقع الكتروني (ثانوي)",
          BUSINESS_VERTICAL: "الأعمال العمودية",
        },
        PROFILE_REVIEW: {
          HEADER: "الملف الشخصي",
          NAME: "اسم",
          WARN: "هذا ليس اسم المستخدم أو الرقم السري الخاص بك. ",
          ABOUT: "عن",
          PHONE: "هاتف",
        },
        REVIEW_BUTTON: "إرسال للمراجعة",
      },
      CHANNEL_ACCOUNT_EVENTS: {
        HEADER: "أحداث حساب القناة",
        EVENT_TYPE: "نوع الحدث",
        EVENT_ID: "معرف الحدث",
        DATE: "تاريخ",
        DETAILS: "تفاصيل",
        EVENT_LOG: "سجل الأحداث",
        VIEW_JSON_BTN: "عرض جسون",
        VIEW_JOSN_POPUP: {
          HEADER: "عرض جسون",
          CANCEL: "إلغاء",
          COPY_JSON: "انسخ ملف JSON",
          COPIED: "نسخ",
        },
      },
    },
    ACCOUNT_EVENTS: {
      HEADER: "أحداث الحساب",
      SUBTITLE: "أحداث في الوقت الفعلي على مستوى WhatsApp Business API لحسابك",
      REGISTRATION_PROGRSS: {
        HEADER: "تقدم التسجيل",
        CREATED: "مخلوق",
        SUBMITTED: "مُقَدَّم",
        REVOKED: "إلغاء - فسخ",
        LIVE: "مباشر",
      },
      CHART: {
        ALL_CONVERSATION: "جميع المحادثات",
        BUSINESS_INITIATED: "بدأ العمل",
        USER_INITIATED: "بدأ المستخدم",
        MARKETING: "تسويق",
        SERVICE: "خدمة",
        AUTHENTICATION: "المصادقة",
        UTILITY: "جدوى",
      },
    },
    CUSTOMIZE: "يعدل أو يكيف",
  },
  HUBSPOT: {
    HEADER: "HubSpot",
    SUBTITLE:
      "أرسل جهات الاتصال والمحادثات إلى HubSpot واستخدم HubSpot لإرسال الرسائل",
    HUBSPOT_INTEGRATION_TITLE: "كيف يعمل؟",
    HUBSPOT_INTEGRATION_SUBTITLE:
      "سيسمح لك تكامل Hubspot بربط محادثات Avocado بجهات اتصال HubSpot الخاصة بك. ",
    READ_MORE: "اقرأ أكثر",
    HUBSPOT_CONNECTION_GUIDE_TITLE:
      "فيما يلي دليل خطوة حول كيفية دمج مساحة عمل HubSpot الخاصة بك مع Avocoda",
    HUBSPOT_CONNECTION_GUIDE_BUTTON: "دليل اتصال HubSpot",
    HUBSPOT_WORKSPACE: "قم بتوصيل مساحة عمل HubSpot الخاصة بك",
    HUBSPOT_WORKSPACE_SUBTITLE: "انقر فوق الزر لتوصيل مساحة العمل الخاصة بك",
    CONNECT_BUTTON: "قم بتوصيل HubSpot",
  },
  LOGIN: {
    AVOCADO: "مٌتَّصِل ٣.٠",
    TWERLO_PRODUCT: "منتج تويرلو",
    SIGNUP_TITLE: "إنشاء حساب",
    LOGIN_TITLE: "تسجيل الدخول إلى حسابك",
    CHECK_EMAIL_TITLE: "تفقد بريدك الالكتروني من فضلك",
    FORGOT_PASSWORD_TITLE: "هل نسيت كلمة السر",
    WORKSPACE_NAME_TITLE: "قم بتسمية مساحة العمل الخاصة بك",
    WORKSPACE_NAME_SUBTITLE: "قم بتسمية مساحة العمل الخاصة بك للبدء",
    PHONE_NUMBER_TITLE: "احصل على إجابات الخبراء لأسئلتك",
    PHONE_NUMBER_SUBTITLE:
      "سوف نقوم بتوصيلك مرة أخرى على الواتساب.<0></0>(يمكنك إلغاء الاشتراك في أي وقت)",
    ROLE_TITLE: "ما هو دورك؟",
    ROLE_SUBTITLE: "اسمحوا لنا أن نعرف ما هو دورك في شركتك",
    TEAM_SIZE_TITLE: "ما هو حجم فريقك؟",
    TEAM_SIZE_SUBTITLE: "اسمحوا لنا أن نعرف ما هو دورك في شركتك",
    PURPOSE_TITLE: "لماذا تحتاج مٌتَّصِل",
    PURPOSE_SUBTITLE: "حدد خيارًا واحدًا أو أكثر",
    NEW_PASSWORD_TITLE: "إنشاء كلمة مرور جديدة",
    NEW_PASSWORD: "كلمة المرور الجديدة",
    CONFIRM_PASSWORD: "تأكيد كلمة المرور",
    NAME: "اسم",
    EMAIL_ADDRESS: "عنوان البريد الإلكتروني",
    PASSWORD: "كلمة المرور",
    SELECT_WORKSPACE: "حدد مساحة العمل",
    ENTER_NAME: "أدخل الاسم هنا",
    ROLE_PLACEHOLDER: "حدد الدور",
    REMEMBER_ME: "تذكرنى",
    CREATE_ACCOUNT: "إنشاء حساب",
    LOGIN: "تسجيل الدخول",
    REQUIRED_NAME: "يجب إدخال الإسم",
    REQUIRED_EMAIL: "يجب إدخال بريدك الإلكتروني",
    REQUIRED_PASSWORD: "يجب إدخال كلمة السر",
    REQUIRED_WORKSPACE: "يجب إختيار مساحة العمل",
    SEND_RESET_LINK: "إرسال رابط إعادة الضبط",
    FORGOT_PASSWORD: "هل نسيت كلمة السر؟",
    FOOTER_SIGNUP: "هل لديك حساب؟ <0>تسجيل الدخول</0>",
    FOOTER_LOGIN: "ليس لديك حساب؟ <0>تسجيل</0>",
    FOOTER_CHECK_EMAIL: "هل لديك حساب؟ <0>تسجيل الدخول</0>",
    FOOTER_FORGOT_PASSWORD: "ارجع إلى <0>تسجيل الدخول</0>",
    CONFIRMATION_SENT: "لقد أرسلنا رابط تأكيد إلى عنوان بريدك الإلكتروني",
    SOMETHING_ELSE: "شيء آخر",
    RESET_PASSWORD_NOTIFICATION:
      "تم إرسال رابط تعيين كلمة السر! يرجى التحقق من البريد الإلكتروني.",
    ROLES: {
      FOUNDER_OWNER: "المؤسس / المالك",
      MARKETING_MANAGER: "مدير تسويق",
      SALES_BUSINESS_DEVELOPMENT: "المبيعات أو تطوير الأعمال",
      CUSTOMER_SUPPORT: "دعم العملاء",
    },
    SIZES: {
      FROM_1_5: "1-5 موظفين",
      FROM_6_15: "6-15 موظف",
      FROM_15_50: "15-50 موظف",
      FROM_50: "> 50 موظفًا",
    },
    PURPOSES: {
      SALES: "مبيعات",
      SUPPORT: "يدعم",
      MARKETING: "تسويق",
    },
    UNAUTHORIZED_MESSAGE: "401 غير مصرح به، إعادة التوجيه لتسجيل الدخول.",
    SESSION_TIMED_OUT: "انتهت مهلة الجلسة، جاري إعادة التوجيه لتسجيل الدخول..",
  },
  BILLING_SUBSCRIPTION: {
    TITLE: "الاشتراك",
    SUBTITLE: "رؤية كل اشتراكك",
    ACCOUNT_BLOCKED: "الحساب محظور: يرجى التواصل مع فريقنا على الفور لحل المشكلة. ",
    ACCOUNT_RESTRICTED: "الحساب مقيد: رصيد حسابك على وشك النفاد. ",
    BILLING_DASHBOARD: "لوحة تحكم الفواتير",
    USAGE_REPORT: "تقرير الاستخدام",
    INVOICE: "فاتورة",
    BILLING_SETUP: "إعداد الفواتير",
    SUBSCRIPTION: "الاشتراك",
    SUBSCRIPTION_DETAILS: "تفاصيل الاشتراك",
    CURRENT_PLAN: "الخطة الحالية:",
    CURRENT_BILLING_DATE: "تاريخ الفاتورة الحالية:",
    NEXT_BILLING_DATE: "موعد المحاسبة التالي:",
    PREMIUM_PLUS: "بريميوم بلس",
    AVAILABLE_WA_CREDIT: "رصيد WA المتاح",
    AVAILABLE_CONVERSATION_TARIFF: "تعرفة المحادثة المتاحة",
    ADD_FUNDS: "إضافة الأموال",
    CREDIT_BALANCE: "الرصيد الدائن:",
    ADD_FUNDS_TITLE: "إضافة الأموال",
    ADD_FUNDS_SUBTITLE: "حدد المبلغ وأضف الأموال إلى محفظتك.",
    CHOOSE_PAYMENT_METHOD: "اختر وسيلة الدفع",
    ADD_FUNDS_TO: "إضافة أموال إلى",
    BANK_TRANSFER: "التحويل المصرفي",
    BANK_TRANSFER_DETAILS: "قم بتمكين عمليات عملك من خلال منصتنا، التي يمكن الوصول إليها بسهولة من خلال التحويلات المصرفية عبر الإنترنت. ",
    ALL_ACCEPTED: "(جميع التحويلات البنكية مقبولة)",
    UPLOAD_TRANSACTION: "قم بتحميل تفاصيل معاملتك",
    UPLOAD_TRANSACTION_DETAILS: "قم بإجراء دفعاتك من خلال التحويل المصرفي وقم بتأكيد الدفع عن طريق تحميل إيصال المعاملة أو لقطة الشاشة هنا.",
    UPLOAD_BTN: "تحميل jpg/pdf",
    THANKS_TITLE: "شكرا لتسديدك",
    THANKS_BODY: "سنؤكد دفعتك ونضيف الأموال إلى حسابك قريبًا.<0></0>يرجى إعادة زيارة صفحة الفواتير الخاصة بك في وقت ما.",
    USAGE_REPORTS: "تقارير الاستخدام",
    STARTING_BALANCE: "التوازن بدءا",
    TOP_UPS: "أعلى شكا",
    KNOW_TOP_UP_REPORT: "اعرف تقارير ال توب ابس الخاصة بك من هنا",
    TOP_UP_LOGS: "لوجز التوب ابس",
    PAID: "مدفوعة",
    USAGE: "الاستخدام",
    ENDING_BALANCE: "الرصيد النهائي",
    USAGE_DETAILS: "تفاصيل الاستخدام",
    USAGE_DETAILS_SUBTITLE: "تعرف على تقرير الاستخدام الكامل الخاص بك هنا",
    CONVERSATION_CHARGES: "رسوم المحادثة",
    WHATSAPP_CHARGES: "رسوم محادثة الواتساب",
    PLATFORM_CHARGES: "رسوم محادثة المنصة",
    MONTHLY_FAIR_LIMIT: "حد الاستخدام الشهرى العادل",
    ADD_ON: "اضافة",
    TOTAL_CONSUMPTION: "الاستهلاك الكلى",
    BALANCE: "التوازن",
    ADD_ON_LOGS: "لوجز الاضافة",
    VIEW_ADD_ON_LOGS: "رؤية لوجز الاضافة هنا",
    RATE_FOR_ADDITIONAL: "تقييم للاضافات",
    TOTAL_AMOUNT: "الكمية الكلية",
    CONVERSATION: "محادثة",
    TOTAL_QTY: "إجمالي الكمية",
    CHARGABLE_QTY: "الكمية القابلة للشحن",
    CHANNEL_RATE: "معدل القناة",
    CHARGES: "رسوم",
    SEARCH_INVOICES: "بحث في الفواتير",
    NEWEST_FIRST: "الأحدث أولاً",
    OLDEST_FIRST: "الأقدم أولا",
    STATUS: "حالة",
    ALL: "الجميع",
    DATE: "تاريخ",
    TYPE: "يكتب",
    AMOUNT: "كمية",
    FUNDS_ADDED: "تمت إضافة الأموال",
    DOWNLOAD: "تحميل",    
    PENDING: "قيد الانتظار",
    CANCELLED: "ألغيت",
    OPEN: "يفتح",
    NOT_SETUP: "لم تقم بإعداد حسابك",
    SUBSCRIBE_PLAN: "اشترك في خطة وأضف الأموال إلى محفظتك لبدء استخدام Camel",
    SETUP_BILLING: "إعداد الفواتير",
    SELECT_PLAN_ADD_FUNDS: "حدد خطة",
    CHOOSE_SUBSCRIPTION: "اختر اشتراكًا لك بناءً على احتياجاتك وأضف الأموال.",
    SELECT_PLAN: "حدد خطة",
    MONTHLY: "شهريا",
    QUARTERLY: "ربعي",
    HALF_YEARLY: "نصف سنوي",
    YEARLY: "سنوي",
    STARTER: "بداية",
    PER_MONTH: "${{price}}/شهر",
    PER_CONVERSATION: "${{price}}/محادثة",
    WA_CHARGES: "رسوم الواتساب",
    STARTER_DESCRIPTION: "للفرق الصغيرة التي ترغب في اختبار مياه البريد الوارد المشتركة",
    CHOOSE_PLAN: "اختر الخطة",
    TEAM: "فريق",
    TEAM_DESCRIPTION: "للفرق المتنامية التي بدأت في التوسع إلى آفاق جديدة مع Zoko",
    PRO: "طليعة",
    PRO_DESCRIPTION: "ارتقِ بمستوى هذه الخطة الاحترافية وإمكانية الوصول المتقدمة إليها",
    BUSINESS: "عمل",
    BUSINESS_DESCRIPTION: "عندما تحتاج إلى ضوابط مبسطة ومركزية",
    STILL_UNDECIDED: "لا تزال مترددة؟ <0>قارن خططنا بالتفصيل</0>",
    SELECTED: "المحدد",
    SELECT_AMOUNT: "حدد المبلغ",
    MINIMUM: "الحد الأدنى",
    AUTO_TOP_UP: "التعبئة التلقائية",
    AUTO_TOP_UP_DESCRIPTION: "اسمح لك بتعبئة رصيد محفظتك تلقائيًا عند انخفاضه.",
    WHEN_BALANCE_BELOW: "عندما يكون الرصيد أدناه:",
    AUTOMATICALLY_RECHARGE: "إعادة الشحن تلقائيًا:",
    DIRECT_PAYMENT: "دفع مباشر",
    DIRECT_PAYMENT_DESCRIPTION: "استمتع بمعاملات سلسة وآمنة وسريعة من خلال خيار الدفع المباشر الخاص بنا في منصتنا. ",
    ENTER_CARD_DETAILS: "أدخل تفاصيل البطاقة",
    CARD_NUMBER: "رقم البطاقة",
    EXPIRY_DATE: "تاريخ الانتهاء",
    CVV: "CVV",
    BILLING_DETAILS: "تفاصيل الفاتورة",
    COMPANY_NAME: "اسم الشركة",
    ADDRESS_LINE: "خط عنوان {{line}}",
    COUNTRY_REGION: "البلد/المنطقة",
    STATE_PROVINCE: "الولاية/المقاطعة",
    ZIP_CODE: "الرمز البريدي",
    CITY: "مدينة",
    TAX_ID: "الرقم الضريبي",
    EMAIL: "بريد إلكتروني"
  },
  ERROR: {
    "404_TITLE": "404: الصفحة التي تبحث عنها ليست هنا",
    "404_DESCRIPTION":
      "إما أنك حاولت الدخول على مسار بالخطأ أو أتيت إلى هنا عن طريق الخطأ. ",
    "500_TITLE": "500: خطأ داخلي في الخادم",
    "500_DESCRIPTION":
      "إما أنك حاولت الدخول على مسار بالخطأ أو أتيت إلى هنا عن طريق الخطأ. ",
    "401_TITLE": "401 التفويض مطلوب",
    "401_DESCRIPTION":
      "إما أنك حاولت الدخول على مسار بالخطأ أو أتيت إلى هنا عن طريق الخطأ. ",
    RELOAD_TITLE: "أُووبس! ",
    RELOAD_DESCRIPTION: "لا تقلق، فقط قم بإعادة تحميل الصفحة وسوف تعود.",
    BACK_BTN: "رجوع",
    RELOAD_BTN: "إعادة تحميل الصفحة",
  },
  KNOWLEDGE_BASE: {
    HEADER: "قاعدة المعرفة",
    SUBTITLE: "إنشاء وإدارة قاعدة المعرفة هنا",
    BUTTON: {
      TRAIN: "يدرب",
      CREATE_KNOWLEDGE_BASE: "إنشاء قاعدة المعرفة",
    },
    TABLE_HEADERS: {
      NAME: "اسم",
      DESCRIPTION: "وصف",
      CREATED_BY: "انشأ من قبل",
      CREATED_ON: "تم إنشاؤها على",
      STATUS: "حالة",
      ACTION: "إجراءات",
    },
    CREATE_POPUP: {
      HEADER_TITLE: "إنشاء مستند جديد",
      DOCUMENT_NAME: "اسم الملف",
      DESCRIPTION: "وصف",
      KNOWLEDGE_TYPE: "نوع المعرفة",
      UPLOAD: "رفع",
      WEBSITE: "موقع إلكتروني",
      CONTENT_BODY: "نص المحتوى",
      SELECT_FILE_HERE: "حدد ملفًا أو قم بالسحب والإسقاط هنا",
      SELECT_FILE_PLACEHOLDER: "مستند أو PDF، حجم الملف لا يزيد عن 10 ميغابايت",
      SELECT_BUTTON: {
        BUTTON: "حدد الملف",
      },
      DOCUMENT_PLACEHOLDER: "عنوان URL للمستند",
      CONTENT_BODY_TEXT: "هيئة المحتوى",
      CONTENT_BODY_TEXT_PLACEHOLDER: "أدخل النص الأساسي هنا",
      BUTTON: {
        CANCEL: "إلغاء",
        CREATE: "يخلق",
      },
    },
  },
};

export default AR_TRANSLATIONS