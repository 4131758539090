import axios from "axios";
import { EmojiClickData } from "emoji-picker-react";
import { t } from "i18next";
import environment from "../environment";
import { ISendMessageParams } from "../models/Chat";

export const export_chat = async ({ contact_id, file_type = 'xlsx', notify_email = 'yes' }) => {
  const options = {
    method: 'POST',
    url: `${environment.REACT_APP_API_GATEWAY_ENDPOINT}/message/download.bulk`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: localStorage.getItem('avc-token')
    },
    params: {
      contact_id,
      file_type,
      notify_email
    }
  };

  try {
    const { data } = await axios.request(options);
    return data;
  } catch (error) {
    console.error(error);
  }
}

export const handleEmoji = (e: EmojiClickData, inputRef: HTMLInputElement | null) => {
  if (!inputRef) return;
  const cursor = inputRef.selectionStart as number;
  const new_text = inputRef.value.slice(0, cursor) + e.emoji + inputRef.value.slice(cursor);
  inputRef.value = new_text;
  setTimeout(() => {
    inputRef?.focus();
  }, 0);
}

const media_types = ['audio', 'document', 'video', 'image'];

export const send_message = async ({ platform_id, type, preview_url, body_text, media_pid, media_caption, media_filename, template_id, template_arguments, parent_message_id }: ISendMessageParams) => {
  const options = {
    method: 'POST',
    url: `${environment.REACT_APP_API_GATEWAY_ENDPOINT}/message/send`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('avc-token')
    },
    data: {
      recipient_type: 'individual',
      to: platform_id,
      type: type,
      preview_url: preview_url,
      parent_message_id,
      // media messages
      [type]: media_types.includes(type) ? {
        id: media_pid,
        caption: media_caption,
        filename: media_filename
      } : undefined,
      // text messages
      text: type === 'text' ? {
        body: body_text
      } : undefined,
      // template messages
      template: type === 'template' ? {
        id: template_id,
        argument: template_arguments
      } : undefined,
      // location: {
      //   longitude: 0,
      //   latitude: 0,
      //   name: 'string',
      //   address: 'string'
      // },
      // interactive: {
      //   type: 'button',
      //   header: {
      //     type: 'string',
      //     text: 'string',
      //     document: {link: 'string', filename: 'string'},
      //     video: {link: 'string'},
      //     image: {link: 'string'}
      //   },
      //   body: {text: 'string'},
      //   footer: {text: 'string'},
      //   action: {
      //     button: 'string',
      //     buttons: [{type: 'string', reply: {id: 'string', title: 'string'}}],
      //     sections: [
      //       {
      //         title: 'string',
      //         rows: [{id: 'string', title: 'string', description: 'string'}]
      //       }
      //     ]
      //   }
      // },
      // contacts: [
      //   {
      //     addresses: [
      //       {
      //         city: 'string',
      //         country: 'string',
      //         country_code: 'string',
      //         state: 'string',
      //         street: 'string',
      //         type: 'string',
      //         zip: 'string'
      //       }
      //     ],
      //     birthday: 'string',
      //     emails: [{email: 'string', type: 'string'}],
      //     name: {first_name: 'string', formatted_name: 'string', last_name: 'string'},
      //     org: {company: 'string', department: 'string', title: 'string'},
      //     phones: [{phone: 'string', type: 'string', wa_id: 'string'}],
      //     urls: [{url: 'string', type: 'string'}]
      //   }
      // ]
    }
  };
  
  try {
    const { data } = await axios.request(options);
    return data;
  } catch (error) {
    console.error(error);
  }
}

export const agentAdapter = (contact: any) => ({
  type: 'contact',
  id: contact.id,
  display_name: contact.contact_profiles[0].display_name,
  avatar_name: contact.contact_profiles[0].display_name,
  image: contact.contact_profiles[0].image,
  email: contact.contact_profiles[0].email
});

export const teamAdapter = (team: any) => ({
  type: 'team',
  id: team.id,
  display_name: team.name,
  avatar_name: team.name,
});

export const getBodyOfTemplate = (message: any) => {
  const body_obj = message.body?.template?.components.find((c: any) => c.type === 'body');
  let body = body_obj?.text;
  body_obj?.parameters?.forEach((p: any, i: number) => {
    
    body = body?.replace(`{{${i + 1}}}`, p.text);
  });

  return body;
}

export const getMessageText = (message: any) => {
  if (!message) return t('CHAT.UNAVAILABLE_MESSAGE');
  if (message.type === 'template') return getBodyOfTemplate(message);

  return message.body?.text?.body || message.body?.[message.type]?.caption || (message.type ? t(`CHAT.${message.type.toUpperCase()}_MESSAGE`) : '');
}

export const splitOnFirst = (str: string, separator: string) => {
  const index = str.indexOf(separator);
  if(index !== -1) {
    return [str.substring(0, index), str.substring(index + separator.length)]; 
  }
  return [str];
}

export const attachments_types = [ undefined, 'image', 'audio', 'video', 'document' ];

export const urlPattern = /((?:https?:\/\/)?\w{2,}\.[^\s]{2,})/g;
export const phonePattern = /(\+\d+|\b\d{8,}\b)/g;

export const fixed2Digits = (num: number) => {
  return num.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false
  })
}