import gql from 'graphql-tag'

export default gql `
  fragment assignation on core_contact_agent {
    is_team
    agent {
      agent_id: id
      platform_id
      agent_profile: contact_profiles {
        name
        display_name
      }
    }
    team {
      team_id: id
      name
    }
  }
`