import { createSlice } from '@reduxjs/toolkit';
import { Dispatch } from 'redux';

// Slice
const slice = createSlice({
  name: 'contacts',
  initialState: {
    editContact: null as any,
    contactUpdating: -1,
    showImportModel: false,
    showExportModel: false,
    showCreateModel: false,
    bulkFile: null as File | null,
    bulkUpload: false,
  },
  reducers: {
    editContactChanged: (state, action) => {
      state.editContact = action.payload;
    },
    contactUpdating: (state, action) => {
      state.contactUpdating = action.payload.id;
    },
    importModelChanged: (state, action) => {
      state.showImportModel = action.payload;
    },
    exportModelChanged: (state, action) => {
      state.showExportModel = action.payload;
    },
    createModelChanged: (state, action) => {
      state.showCreateModel = action.payload;
    },
    bulkFileChanged: (state, action) => {
      state.bulkFile = action.payload;
    },
    bulkUploadChanged: (state, action) => {
      state.bulkUpload = action.payload;
    },
  },
});

export default slice.reducer;

// Actions
const { editContactChanged, contactUpdating, importModelChanged, exportModelChanged, createModelChanged, bulkFileChanged, bulkUploadChanged } = slice.actions;

export const setEditContact = (contact: any): any => (dispatch: Dispatch): void => {
  dispatch(editContactChanged(contact));
}

export const setContactUpdating = (id: number): any => (dispatch: Dispatch): void => {
  dispatch(contactUpdating({ id }));
}

export const setImportModel = (show: boolean): any => (dispatch: Dispatch): void => {
  dispatch(importModelChanged(show));
}

export const setExportModel = (show: boolean): any => (dispatch: Dispatch): void => {
  dispatch(exportModelChanged(show));
}

export const setCreateModel = (show: boolean): any => (dispatch: Dispatch): void => {
  dispatch(createModelChanged(show));
}

export const setBulkFile = (file: File | null): any => (dispatch: Dispatch): void => {
  dispatch(bulkFileChanged(file));
}

export const setBulkUpload = (status: boolean): any => (dispatch: Dispatch): void => {
  dispatch(bulkUploadChanged(status));
}
