import { createSlice } from "@reduxjs/toolkit";
import { Dispatch } from "redux";

const slice = createSlice({
  name: "auth",
  initialState: {
    showResetPasswordNotification: false as boolean,
  },
  reducers: {
    setShowResetPasswordNotification: (state) => {
      state.showResetPasswordNotification =
        !state.showResetPasswordNotification;
    },
  },
});

export default slice.reducer;

// actions
const { setShowResetPasswordNotification } = slice.actions;

export const changeShowResetPasswordNotification =
  (): any =>
  (dispatch: Dispatch): void => {
    dispatch(setShowResetPasswordNotification());
  };
