import { createSlice } from "@reduxjs/toolkit";
import { Dispatch } from 'redux';

const slice = createSlice({
    name:"createknowledge",
    initialState:{
        showCreatePopup : false,
    },
    reducers:{
        popupStatusChanged : (state, action) =>{
            state.showCreatePopup = action.payload;
        }
    }
})

export default slice.reducer;

//Action

const {popupStatusChanged} = slice.actions;

export const changePopupStatus = (status:boolean): any =>(dispatch: Dispatch): void => {
    dispatch(popupStatusChanged(status))
}