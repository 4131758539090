import React from 'react';
import { Button, ButtonBase, FormHelperText, Radio, RadioGroup, Stack, Typography } from '@mui/material';

//  utils
import { useTranslation } from 'react-i18next';

//  validations
import * as yup from 'yup';
import { useForm,  Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

export const TeamSize = ({ setTab, detailsRef }): JSX.Element => {
  const { t } = useTranslation();

  const schema = yup.object().shape({
    size: yup.string().required(),
  });

  const { control, handleSubmit, formState: { errors } } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: { size: '' }
  });

  const next = (payload: any) => {
    detailsRef.current.realm.team_size = payload.size;
    setTab(8);
  }

  const skip = () => {
    detailsRef.current.realm.team_size = "N/A";
    setTab(8);
  }

  const sizesOptions: any = t('LOGIN.SIZES', { returnObjects: true });

  return (
    <Stack alignItems="center">
      <Typography fontSize={{md:14, xs:12, sm:13}} fontWeight={500} textAlign="center" sx={{ color: 'text.secondary', mb: 2 }}>{t('LOGIN.TEAM_SIZE_SUBTITLE')}</Typography>
      <Controller name='size' control={control} render={({ field: { value, onChange } }) => (
        <RadioGroup value={value} sx={{ width: '100%', overflowY: 'auto', gap: 2, p: {md:3, sm:2, xs: 1}, boxSizing: 'border-box' }}>
          { Object.entries(sizesOptions).map(([key, text]: any) => (
            <ButtonBase key={key} onClick={() => onChange(key)} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 2, px: 3, py: {md:2, xs:1}, width: '100%', boxShadow: 7, borderRadius: 2 }}>
              <Typography>{text}</Typography>
              <Radio value={key} onClick={onChange} onMouseDown={e => e.stopPropagation()} />
            </ButtonBase>
          )) }
        </RadioGroup>
      )} />
      { errors.size && <FormHelperText data-testid="size-error" sx={{ color: 'error.main', width: '100%', boxSizing: 'border-box', paddingInlineStart: 4 }}>{ errors.size.message }</FormHelperText> }
      <Stack flexDirection="row" alignItems="center" gap={2} sx={{ mt: 2, px: 6, width: '100%', boxSizing: 'border-box' }}>
        <Button onClick={handleSubmit(next)} disableElevation variant="contained" sx={{ flex: 1, borderRadius: 2, fontSize: 15, fontWeight: 600 }}>{t('COMMON.CONTINUE')}</Button>
        <Button onClick={skip} variant="outlined" sx={{ flex: 1, borderRadius: 2, fontSize: 15, fontWeight: 600 }}>{t('COMMON.SKIP')}</Button>
      </Stack>
    </Stack>
  );
}