import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, BottomNavigation, BottomNavigationAction, CssBaseline, Paper } from '@mui/material';

//  icons
import ChatIcon from '@mui/icons-material/Chat';
import AppsIcon from '@mui/icons-material/Apps';
import SettingsIcon from '@mui/icons-material/Settings';
import ContactPageRoundedIcon from '@mui/icons-material/ContactPageRounded';

//  redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { setCurrentRoute } from '../../redux/sdk';
import { changeStatus } from '../../redux/search';

const screenIndecies = [
    "/chat",
    "/contacts",
    "/apps",
    "/settings"
]

export const BottomNavbar = (): JSX.Element => {
    //  hooks
    const dispatch = useDispatch();
    
    //  local states
    const [selectedTab, setSelectedTab] = useState(-1);

    //  redux states
    const mode = useSelector((state: RootState) => state.sdk.mode);
    const path = useSelector((state: RootState) => state.sdk.currentRoute.path);

    useEffect(() => {
        setSelectedTab(screenIndecies.findIndex(tab => path.startsWith(tab)));
    }, [path]);

    //  handlers
    const handleChange = (_: any, newValue: number) => {
        dispatch(changeStatus(false));
        dispatch(setCurrentRoute(screenIndecies[newValue]));
    };
    
    return (
        <Box data-testid='bottom-nav'>
            <CssBaseline />
            <Paper data-testid='bottom-nav-elements-container'>
                <BottomNavigation
                    data-testid='bottom-navigation'
                    showLabels
                    value={selectedTab}
                    onChange={handleChange}
                    sx={{
                        'a': { minWidth: 'auto' },
                        '.Mui-selected svg': { boxShadow: 15, p: 1, boxSizing: 'content-box', borderRadius: 2 }
                    }}
                >
                    <BottomNavigationAction data-testid='to-chat' component={Link} icon={<ChatIcon />}
                        to={ mode === "host" ? "/chat" : "" }
                    />

                    <BottomNavigationAction data-testid='to-contacts' component={Link} icon={<ContactPageRoundedIcon />}
                        to={ mode === "host" ? "/contacts" : "" }
                    />

                    <BottomNavigationAction data-testid='to-apps' component={Link} icon={<AppsIcon />}
                        to={ mode === "host" ? "/apps" : "" }
                    />

                    <BottomNavigationAction data-testid='to-settings' component={Link} icon={<SettingsIcon />}
                        to={ mode === "host" ? "/settings/workspace/account-profile" : "" }
                    />
                </BottomNavigation>
            </Paper>
        </Box>
    );
}