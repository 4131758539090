import React, { ChangeEvent, useCallback, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Autocomplete, Button, Checkbox, InputProps, Stack, TextField, Typography } from '@mui/material';

//  utils
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';

//  helpers
import { get_businesses, login, register } from '../../helpers/auth';
import { publish } from '../../helpers/events';

//  validations
import * as yup from 'yup';
import { useForm,  Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

//  redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { setAuthenticated, setCurrentRoute } from '../../redux/sdk';

const inputProps: InputProps = {
  disableUnderline: true,
  sx: { borderRadius: 2.5, backgroundColor: 'transparent !important', border: '1px solid', borderColor: 'light.gray8' }
};

const emailRegEx = new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);

export const Form = ({ currentTab, setTab }): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const mode = useSelector((state: RootState) => state.sdk.mode);
  
  const [workspaces, setWorkspaces] = useState<any[]>([]);
  
  const rememberMe = useRef<HTMLInputElement | null>(null);
  
  const isSignup = (currentTab === 'signup');

  const schema = yup.object().shape({
    name: isSignup ? yup.string().required('LOGIN.REQUIRED_NAME') : yup.string(),
    email: yup.string().email('VALIDATIONS.INVALID_EMAIL').required('LOGIN.REQUIRED_EMAIL'),
    password: yup.string().required('LOGIN.REQUIRED_PASSWORD').min(8, 'VALIDATIONS.INVALID_PASSWORD'),
    workspace: workspaces.length ? yup.string().required('LOGIN.REQUIRED_WORKSPACE') : yup.string()
  });

  const { control, getValues, setValue, setError, handleSubmit, formState: { errors } } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  });

  const checkWorkspaces = useCallback(
    debounce(async (e: ChangeEvent<HTMLInputElement>) => {
      if (isSignup || !emailRegEx.test(e.target.value)) return;
      try {
        const data = await get_businesses(e.target.value);
        setWorkspaces(data.businesses);
        if (!data.businesses.find((b: any) => b.business_id === getValues('workspace'))) {
          setValue('workspace', undefined);
        }
      } catch (error) {
        setWorkspaces([]);
        setValue('workspace', undefined);
      }
    }, 500),
    [isSignup]
  );

  const submit = async (payload: any) => {
    try {
      if (isSignup) {
        await register(payload);
        setTab(2);
      } else {
        const data = await login(payload);
        if (rememberMe.current?.checked === false) {
          window.onbeforeunload = () => {
            localStorage.removeItem('avc-token');
            localStorage.removeItem('avc-business_id');
            localStorage.removeItem('avc-user_id');
            localStorage.removeItem('avc-user_email');
            localStorage.removeItem('avc-user_details');
          }
        }
        localStorage.setItem('avc-token', data.headers.Authorization);
        localStorage.setItem('avc-business_id', getValues('workspace') as string);
        localStorage.setItem('avc-user_id', data.user_details.id);
        localStorage.setItem('avc-user_email', payload.email);
        localStorage.setItem('avc-user_details', JSON.stringify(data.user_details));
        
        window.sdk.authorize();
        publish('LOGIN', {});
        dispatch(setAuthenticated(true));

        // const home = window.screen.width <= 786 ? '/chat' : '/dashboard/overview';
        const home = '/chat';
        if (mode === 'host') navigate(home);
        dispatch(setCurrentRoute(home));
      }
    } catch (error: any) {
      const code = error.toJSON().status;
      if (code === 404) {
        setError('email', { message: 'VALIDATIONS.INVALID_EMAIL_PASSWORD' });
        setError('password', { message: 'VALIDATIONS.INVALID_EMAIL_PASSWORD' })
      } else if (code === 409) {
        setError('email', { message: 'VALIDATIONS.EMAIL_EXISTS' });
      } else if (code === 400) {
        setError('email', { message: 'VALIDATIONS.NOT_REGISTERED' });
      }
    }
  }

  return (
    <>
      <Stack alignItems="center" gap={1.5} width="100%" mt={2}>
        { isSignup &&
          <Controller name='name' control={control} render={({ field: { value, onChange, onBlur } }) => (
            <TextField label={t('LOGIN.NAME')} name='name' variant="filled" className='devias-textbox-filled' InputProps={inputProps} fullWidth value={value || ''} onChange={onChange} onBlur={onBlur} error={Boolean(errors.name)} helperText={t(errors.name?.message || '')} />
          )} />
        }
        <Controller name='email' control={control} render={({ field: { value, onChange, onBlur } }) => (
          <TextField label={t('LOGIN.EMAIL_ADDRESS')} type="email" name='email' variant="filled" className='devias-textbox-filled' InputProps={inputProps} fullWidth value={value || ''} onChangeCapture={checkWorkspaces} onChange={onChange} onBlur={onBlur} error={Boolean(errors.email)} helperText={t(errors.email?.message || '')} />
        )} />
        <Controller name='password' control={control} render={({ field: { value, onChange, onBlur } }) => (
          <TextField label={t('LOGIN.PASSWORD')} type="password" autoComplete="current-password" variant="filled" className='devias-textbox-filled' InputProps={inputProps} fullWidth value={value || ''} onChange={onChange} onBlur={onBlur} error={Boolean(errors.password)} helperText={t(errors.password?.message || '')} />
        )} />
        { workspaces.length > 0 &&
          <Controller name='workspace' control={control} render={({ field: { value, onChange, onBlur}})=>(
            <Autocomplete
              options={workspaces}
              value={value}
              fullWidth
              onChange={(_: any, newValue: any) => onChange(newValue.business_id)}
              sx={{ flex: 1, '.MuiInputBase-root': { py: 0, paddingInlineStart: 0 } }}
              disableClearable
              getOptionLabel={(business: any) => business.org_name}
              renderOption={(props, business: any) => (
                <Typography component={'li'} key={business.business_id} {...props} py={'10px !important'} mx={0.5} sx={{ borderRadius: 2 }}>{ business.org_name }</Typography>
              )}
              renderInput={(params) => <TextField onBlur={onBlur} {...params} className="devias-textbox" label={t('LOGIN.SELECT_WORKSPACE')} error={Boolean(errors.workspace)} helperText={t(errors.workspace?.message || '')} />}
            />
          )} />
        }
      </Stack>
      { !isSignup &&
        <Stack flexDirection="row" alignItems="center" width="100%" mt={1}>
          <Checkbox inputRef={rememberMe} size='small' defaultChecked />
          <Typography fontSize={{md:14, xs:12, sm:13}} fontWeight={400} color="text.secondary">{t('LOGIN.REMEMBER_ME')}</Typography>
        </Stack>
      }
      <Button onClick={handleSubmit(submit)} disableElevation variant="contained" sx={{ width: '100%', mt: {md:3, xs:2}, mb: {md:2, xs:1}, borderRadius: 2.5, fontSize: {md:15, xs:13, sm:14}, fontWeight: 600 , alignItems:{md:"flex-start", xs:"center"}}}>{t(`LOGIN.${currentTab === 'signup' ? 'CREATE_ACCOUNT' : 'LOGIN'}`)}</Button>
      <Stack sx={{ alignItems: { md:"flex-start", xs:"center" }, mt: !isSignup ? 3 : 1 }}>
        { !isSignup && <Typography onClick={() => setTab(3)} component={Link} to={""} fontSize={{md:14, xs:12, sm:13}} fontWeight={500} color="primary" sx={{ textDecoration: 'none', mt: -3 }}>{t('LOGIN.FORGOT_PASSWORD')}</Typography> }
      </Stack>
    </>
  );
}