import React, { useState } from 'react';
import { IconButton, Stack, Button, Avatar } from '@mui/material';

//  icons
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import NotesIcon from '@mui/icons-material/Notes';
import SearchIcon from '@mui/icons-material/Search';

//  redux
import { useDispatch, useSelector } from 'react-redux';
import { changeStatus } from '../../redux/search';
import { RootState } from '../../redux/store';

//  gql
import { useQuery } from '@apollo/client';
import { GET_PROFILE_IMAGE } from '../../graphql/queries/settings';

//components
import { setCurrentRoute } from '../../redux/sdk';
import { SettingsButtonDrawer } from '../Settings/SettingsButtonDrawer';
import { AgentStatus } from './AgentStatus';
import { Link } from 'react-router-dom';

export const UpperNavMoblie = () => {
  const [show, setShow] = useState(false);

  const toggleShow = () => setShow(prev => !prev);
  const dispatch = useDispatch();

  //redux state
const mode = useSelector((state: RootState) => state.sdk.mode);

  const changeScreen = (newScreen: string) => {
    dispatch(setCurrentRoute(newScreen));
  }

  const { data } = useQuery(GET_PROFILE_IMAGE, { variables: { contact_id: localStorage.getItem('avc-user_id') } });

  return (
    <Stack borderBottom={"1px solid"} p={1} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"} boxSizing={'border-box'} sx={{ zIndex: 101, backgroundColor: 'light.main', borderBottomColor: 'light.gray3' }}>
      <IconButton sx={{ p: 0.75 }} onClick={toggleShow}>
        <NotesIcon />
      </IconButton>
      <Stack flexDirection={"row"} alignItems={"center"} gap={0.5}>
        <IconButton sx={{ p: 0.75 }} onClick={() => dispatch(changeStatus(true))}>
          <SearchIcon />
        </IconButton>
        <Stack>
          <Button disabled component={Link} to={mode === 'host' ? '/settings/account/notifications' : ''} onClick={() => changeScreen('/settings/account/notifications')}>
            <NotificationsNoneOutlinedIcon color='secondary' />
          </Button>
        </Stack>
        <Button>
          <Avatar src={data?.core_contact_profile[0].image} style={{ width:'24px', height:'24px' }} />
        </Button>
        <AgentStatus />
      </Stack>
      <SettingsButtonDrawer show={show} toggleShow={toggleShow} />
    </Stack>
  )
}

