import { createTheme } from '@mui/material';

const customTheme = {
    palette: {
        action: {
            disabledBackground: 'rgba(37, 99, 235, 0.50)',
            disabled: '#FFF'
        },
        primary: {
            main: '#2563EB',
            light: '#4279EE',
            alpha1: 'rgba(37, 99, 235, 0.12)',
            alpha2: '#2563eb14',
            alpha3: 'rgba(37, 99, 235, 0.50)',
            alpha4: 'rgba(37, 99, 235, 0.1)',
            alpha5: 'rgba(37, 99, 235, 0.15)',
        },
        secondary: {
            main: "#94A3B8",
            alpha: "#94a3b840",
        },
        text: {
            main: "#111927",
            secondary: "#6C737F",
        },
        light: {
            main: '#FFF',
            secondary: '#F8F9FA',
            gray1: '#F6F6F7',
            gray2: '#1119270A',
            gray3: '#F2F4F7',
            gray4: '#F3F2F7',
            gray5: '#D2D6DB',
            gray6: '#F1F5F9',
            gray7: '#84888D',
            gray8: '#E5E7EB',
            gray9: '#11192761',
            gray10: '#F5F7F6',
            gray11: '#E6E7E9',
            gray12: '#B9B9C3',
            gray13: '#F5F6F6',
            gray14: 'rgba(217, 217, 217, 0.25)',
            gray15: '#6c737f1f',
            gray16: '#6c737f40',
            gray17: '#6c737f80',
            gray18: 'rgba(17, 25, 39, 0.08)',
            gray19: 'rgba(17, 25, 39, 0.38)',
            gray20: 'rgba(17, 25, 39, 0.25)',
            gray21: 'rgba(17, 25, 39, 0.5)',
            gray22: '#f0f0f01f',
            gray23: '#FCFCFC',
            gray24: '#F2F4F780',
        },
        dark: {
            main: '#000',
            gray1: '#415059',
            gray2: '#606D7F',
            gray3: 'rgba(0, 0, 0, 0.10)',
        },
        success: {
            main: '#0B815A',
            solid: '#15B79E',
            alpha: '#F0FDF9',
            alpha2: 'rgba(16, 185, 129, 0.12)',
        },
        error: {
            main: 'rgba(255, 0, 0, 0.50)',
            solid: '#F04438',
            solid2: 'rgba(255, 85, 85, 1)',
            alpha: '#FEF3F2',
            alpha2: '#FF5555BF',
            alpha3: '#f044381f',
            alpha4: 'rgba(255, 0, 0, 0.12)',
            alpha5: 'rgba(255, 0, 0, 0.15)',
        },
        warning: {
            main: '#FFC107',
            solid: '#F79009',
            alpha: '#FFFAEB',
            alpha2: '#F790091F',
            alpha3: 'rgba(247, 144, 9, 0.12)',
        },
        primaryBG: 'rgba(37, 99, 235, 0.12)',
        text1BG: 'rgba(108, 115, 127, 0.14)',
        sdk_status: {
            active: {
                solid: '#28C76F',
                alpha: '#28C76F1F',
                alpha2: '#28C76F15'
            },
            inactive: {
                solid: '#FF9F43',
                alpha: '#FF9F431F',
                alpha2: '#FF9F4315'
            },
            restricted: {
                solid: '#E6B54E',
                alpha: '#E6B54E1F',
                alpha2: '#E6B54E15'
            },
            offline: {
                solid: '#82868B',
                alpha: '#82868B1F',
                alpha2: '#82868B15'
            },
            deleted: {
                solid: '#EA5455',
                alpha: '#EA54551F',
                alpha2: '#EA545515'
            },
        },
    },
    shadows: [
        'none', //  0
        '0px 0px 25px rgba(37, 99, 235, 0.08)', //  1
        '0px 0px 25px 0px rgba(37, 99, 235, 0.08)', //  2
        '0px 6px 30px 0px rgba(0, 0, 0, 0.08)', //  3
        '0px 6px 30px rgba(0, 0, 0, 0.08)', //  4
        '0px 3px 14px rgba(0, 0, 0, 0.08)', //  5
        '0px 9px 46px 0px rgba(0, 0, 0, 0.08)', //  6
        '0px 0px 25px 0px rgba(0, 0, 0, 0.08)', //  7
        '0px 1px 2px 0px rgba(0, 0, 0, 0.08)',  //  8
        '0px 5px 22px 0px rgba(0, 0, 0, 0.08)', //  9
        '0px 0px 10px 0px rgba(0, 0, 0, 0.08)', //  10
        '0px 3px 14px 0px rgba(0, 0, 0, 0.08)', //  11
        '0px 1px 2px rgba(0, 0, 0, 0.08)',  //  12
        '0px 9px 46px rgba(0, 0, 0, 0.08)', //  13
        '0px 8px 25px 0px rgba(0, 0, 0, 0.08)', //  14
        '0px 0px 25px rgba(0, 0, 0, 0.10)', //  15
        '0px 0px 25px 0px rgba(0, 0, 0, 0.10)', //  16
        '0px 1px 9px rgba(0, 0, 0, 0.1)',   //  17
        '0px 0px 25px rgba(0, 0, 0, 0.06)', //  18
        '0px 0px 10px 0px rgba(0, 0, 0, 0.1)',  //  19
        '0px 9px 46px rgba(0, 0, 0, 0.15)', //  20
        '0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 5px 22px rgba(0, 0, 0, 0.04)',  //  21
        '0px 0px 25px 0px rgba(0, 0, 0, 0.06)', //  22
        '0px 7px 21px rgba(0, 0, 50, 0.2)', //  23
        '0px 0px 2px rgba(0, 0, 0, 0.25)',  //  24
    ],
    status: {
        fail: '#B42318',
    }
}

export const theme_factory = () => createTheme(customTheme);