import { SxProps } from "@mui/material";

export const PINNED: string = 'pinned';
export const NOT_PINNED: string = 'notPinned';

export const DISPLAY_NONE: string = 'none';
export const DISPLAY_BLOCK: string = 'block';

export const MEDIA_NONE: string = 'none';
export const MEDIA_IMG: string = 'Image';
export const MEDIA_VIDEO: string = 'Video';
export const MEDIA_DOCS: string = 'Docs';

export const IMAGE_TAG: string = 'IMAGE';
export const VIDEO_TAG: string = 'VIDEO';
export const DOC_TAG: string = 'DOCUMENT';

export const NONE_VALUE: string = 'none';
export const IMG_VALUE: string = 'image';
export const VIDEO_VALUE: string = 'video';
export const DOC_VALUE: string = 'document';
export const TXT_VALUE: string = 'text';
export const UNKNOWN_VALUE: string = 'unknown';

export const AUTH: string = 'AUTHENTICATION';
export const UTILITY: string = 'UTILITY';
export const MARKETING: string = 'MARKETING';

export const BUTTON_NONE: string = 'none';
export const CTA_BUTTON: string = 'CTA';
export const QUICK_REPLY_BUTTON: string = 'QUICK_REPLY';
export const URL: string = 'URL';
export const PHONE_NUMBER: string = 'PHONE_NUMBER';


export const SUBMITTED: string = 'submitted';
export const APPROVED: string = 'approved';
export const REJECTED: string = 'rejected';
export const DELETED: string = 'deleted';

export const AVAILABLE: string = 'AVAILABLE';
export const UNAVAILABLE: string = 'UNAVAILABLE';

export const OWNER: string = 'Owner';
export const MANAGER: string = 'Manager';
export const AGENT: string = 'Agent';

export const COPY_CODE: string = 'COPY_CODE';
export const AUTOFILL: string = 'ONE_TAP';

export const PDF_MIME_TYPE: string = 'application/pdf';
export const JPG_MIME_TYPE: string = 'image/jpeg';
export const PNG_MIME_TYPE: string = 'image/png';
export const MP4_MIME_TYPE: string = 'video/mp4';



export const channelsMap: any = {
  'wa': 'WhatsApp',
}

export const FEEDBACK_SATISFIED: string = 'Satisfied';
export const FEEDBACK_EXCELLENT: string = 'Excellent';
export const FEEDBACK_AWESOME: string = 'Awesome';

export const TOTAL: string = 'Total';
export const AVERAGE: string = 'Avg';

export const SHOW_IN_CHAT: string = 'showInChat';
export const DELETE_TEMPLATE: string = 'deleteTemplate';

function stringToColor(string: string) {
  let hash = 0;
  let i: number;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string?.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function stringAvatar(name: string) {
  const splitedName: string[] = name ? name.toUpperCase().split(' ') : ['U','U'];
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: splitedName?.length > 1 ? 
    `${splitedName[0][0]}${splitedName[1][0]}` :
    `${splitedName[0][0]}`
    ,
  };
}

export function getCreateMeetingBody(name: string, id: number): any {
  return {
    "meeting_title": `Meet with ${name}`,
    "guest_agent_id": id
  }
}

export const progresSteps: any[] = [
  {
    color: 'rgba(37, 99, 235, 1)',
    barColor: 'rgba(37, 99, 235, 1)',
    barBgColor: 'transperent',
    bgColor: 'rgba(37, 99, 235, 0.12)',
    label: 'Created',
    date: ' Dec 20, 2022',
    time: ' 03:02 AM'
  },
  {
    color: 'rgba(37, 99, 235, 1)',
    barColor: 'rgba(247, 144, 9, 1)',
    barBgColor: 'rgba(247, 144, 9, 0.12)',
    bgColor: 'transperent',
    label: 'Submitted',
    date: ' Dec 20, 2022',
    time: ' 03:02 AM'
  },
  {
    color: 'rgba(247, 144, 9, 1)',
    barColor: 'rgba(31, 175, 56, 1)',
    barBgColor: 'rgba(31, 175, 56, 0.12)',
    bgColor: 'rgba(247, 144, 9, 0.12)',
    label: 'Revoked',
    date: 'N/A',
    time: ''
  },
  {
    color: 'rgba(31, 175, 56, 1)',
    bgColor: 'rgba(31, 175, 56, 0.12)',
    label: 'Live',
    date: ' Dec 20, 2022',
    time: ' 03:02 AM'
  }
];

export const conversation: any[] = [
  {
    color: '#FF4369',
    label: 'All Conversation'
  },
  {
    color: '#19AEC3',
    label: 'Business Initiated'
  },
  {
    color: '#1595F0',
    label: 'User Initiated'
  },
  {
    color: '#19AEC3',
    label: 'Marketing'
  },
  {
    color: '#FFC759',
    label: 'Service'
  },
  {
    color: '#BED6E8',
    label: 'Authentication'
  },
  {
    color: '#FF8FA6',
    label: 'Utility'
  }
];

export const chartData = [
  {
    date: "Feb 2023",
    all: 6,
    business: 11,
    user: 12,
    marketing: 10,
    service: 9,
    authentication: 7,
    utility: 8
  },
  {
    date: "April 2023",
    all: 0,
    business: 1,
    user: 2,
    marketing: 4,
    service: 3,
    authentication: 5,
    utility: 6
  },
  {
    date: "June 2023",
    all: 12,
    business: 1,
    user: 10,
    marketing: 5,
    service: 9,
    authentication: 2,
    utility: 8
  }

];
//  define enum data type
export enum UPDATE_QUERY_POLICY {
  MERGE = 0,
  REPLACE = 1,
}

export const tableRowSX: SxProps = {
  display: "table-row",
  transition: "all 0.3s ease",
  ":hover": {
    backgroundColor: "light.gray1",
    cursor: "pointer",
  },
  '&.disabled': {
    pointerEvents: 'none',
    ':hover': {
      backgroundColor: 'transparent',
    }
  }
};

export const emptyError = { title: '', subtitle: '' };