import React, { useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { IconButton, Stack } from '@mui/material';

//  components
import { WorkSpace } from './WorkSpace';
import { SettingButtons } from '../../layouts/Settings/SettingButtons';
import { AccountSettings } from './Account';
import { DevToolsSettings } from './DevTools';
import { SdkRouter } from '../../layouts/Settings/SdkRouter';
import { SettingsButtonDrawer } from '../../layouts/Settings/SettingsButtonDrawer';
import { Header } from '../../layouts/Common/Header';
import Error from '../Error';

//icons 
import WebAssetIcon from '@mui/icons-material/WebAsset';

//  translations
import { useTranslation } from 'react-i18next';

//  redux
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

const Settings = (): JSX.Element => {
    const { t } = useTranslation();
    const isMobile = useMediaQuery({ maxWidth: 786 });
    
    const mode = useSelector((state: RootState) => state.sdk.mode);

    const [show, setShow] = useState(false);

    const toggleShow = () => setShow(prev => !prev);
    
    return (
        <Stack data-testid="settings-container" sx={{ width:'100%', overflowY: 'auto', height: '100%' }}>
            <Header title={t('SETTINGS.SETTINGS')} description={t('SETTINGS.SUBTITLE')} hideBackButton={true} customBtns={
                isMobile ?
                <IconButton sx={{ color: 'text.secondary', transform: 'rotate(90deg)', marginInlineEnd: 1 }} onClick={toggleShow}>
                    <WebAssetIcon data-testid='web-asst-icon'/>
                </IconButton> :
                <></>
            } />
            <Stack data-testid="buttons-setting-container" flex={1} flexDirection='row' sx={{ overflowY: 'auto' }}>
                { !isMobile && <SettingButtons /> }
                { mode === 'host' ?
                <Routes>
                    <Route path="/" element={<Navigate to='/settings/workspace/account-profile' replace />} />
                    <Route path='/account/*' element={<AccountSettings />} />
                    <Route path='/devtools/*' element={<DevToolsSettings />} />
                    <Route path='/workspace/*' element={<WorkSpace />} />
                    <Route path="*" element={<Error type={404} />} />
                </Routes> :
                <SdkRouter />
                }
            </Stack>
            <SettingsButtonDrawer show={show} toggleShow={toggleShow} />
        </Stack>
    );
}

export default React.memo(Settings);