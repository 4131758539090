import { createSlice } from '@reduxjs/toolkit';
import { Dispatch } from 'redux';

// Slice
const slice = createSlice({
  name: 'search',
  initialState: {
    opened: false,
    chatPreview: null as any,
    selected: {} as any,
    allSelected: false,
    count: [0, 0],
    
    search: '',
    type: 0,
    direction: '',
    msgType: [] as string[],
    dateRange: { from: null, to: null },

    chatStatus: '',
    tags: [],
    assignedTo: ''
  },
  reducers: {
    statusChanged: (state, action) => {
      const { opened } = action.payload;
      state.opened = opened;
      if(!opened) {
        state.chatPreview = null as any;
        state.type = 0;
        state.tags = [];
        state.search = '';
        state.direction = '';
        state.msgType = [];
        state.dateRange = { from: null, to: null };
        state.selected = {};
        state.allSelected = false;
      }
    },
    chatPreviewChanged: (state, action) => {
      state.chatPreview = action.payload;
    },
    itemToggled: (state, action) => {
      const id = action.payload;
      state.selected[id] = !state.selected[id];
      if (!state.selected[id]) state.allSelected = false;
    },
    itemChanged: (state, action) => {
      const { id, status } = action.payload;
      state.selected[id] = status;
    },
    allToggled: (state) => {
      state.allSelected = !state.allSelected;
      if(!state.allSelected) state.selected = {}
    },
    countChanged: (state, action) => {
      state.count[action.payload.type] = action.payload.count;
    },
    
    searchChanged: (state, action) => {
      state.search = action.payload;
    },
    typeChanged: (state, action) => {
      state.type = action.payload;
      state.dateRange = { from: null, to: null };
      if (action.payload === 0) {
        state.chatStatus = '';
        state.tags = [];
        state.assignedTo = '';
      } else {
        state.direction = '';
        state.msgType = [];
      }
      state.selected = {};
      state.allSelected = false;
    },
    directionChanged: (state, action) => {
      state.direction = action.payload;
    },
    msgTypeChanged: (state, action) => {
      state.msgType = action.payload;
    },
    dateRangeChanged: (state, action) => {
      if (action.payload.from && action.payload.to) {
        state.dateRange = {
          from: action.payload.from.toISOString().replace('Z', ''),
          to: action.payload.to.toISOString().replace('Z', '')
        }
      } else if (!action.payload.from && !action.payload.to) {
        state.dateRange = {
          from: action.payload.from,
          to: action.payload.to
        }
      }
    },

    chatStatusChanged: (state, action) => {
      state.chatStatus = action.payload;
    },
    tagsChanged: (state, action) => {
      state.tags = action.payload;
    },
    assignedToChanged: (state, action) => {
      state.assignedTo = action.payload;
    }
  },
});

export default slice.reducer;

// Actions
const { statusChanged, 
  chatPreviewChanged, itemToggled, itemChanged, allToggled, countChanged,
  searchChanged, typeChanged,
  directionChanged, msgTypeChanged, dateRangeChanged,
  chatStatusChanged, tagsChanged, assignedToChanged
 } = slice.actions;

export const changeStatus = (opened: boolean): any => (dispatch: Dispatch): void => {
  dispatch(statusChanged({ opened }));
}

export const setChatPreview = (previewData: any): any => (dispatch: Dispatch): void => {
  dispatch(chatPreviewChanged(previewData));
}

export const toggleItem = (id: string): any => (dispatch: Dispatch): void => {
  dispatch(itemToggled(id));
}

export const changeItem = (id: string, status: boolean): any => (dispatch: Dispatch): void => {
  dispatch(itemChanged({ id, status }));
}

export const toggleAll = (): any => (dispatch: Dispatch): void => {
  dispatch(allToggled());
}

export const setCount = (type: number, count: number): any => (dispatch: Dispatch): void => {
  dispatch(countChanged({ type, count }));
}

export const changeSearch = (search: string): any => (dispatch: Dispatch): void => {
  dispatch(searchChanged(search));
}

export const changeType = (type: number): any => (dispatch: Dispatch): void => {
  dispatch(typeChanged(type));
}

export const changeDirection = (direction: string): any => (dispatch: Dispatch): void => {
  dispatch(directionChanged(direction));
}

export const changeMsgType = (msgType: string[]): any => (dispatch: Dispatch): void => {
  dispatch(msgTypeChanged(msgType));
}

export const changeDateRange = (dateRange: any): any => (dispatch: Dispatch): void => {
  dispatch(dateRangeChanged(dateRange));
}

export const changeChatStatus = (chatStatus: string): any => (dispatch: Dispatch): void => {
  dispatch(chatStatusChanged(chatStatus));
}

export const changeTags = (tags: string[]): any => (dispatch: Dispatch): void => {
  dispatch(tagsChanged(tags));
}

export const changeAssignedTo = (assignedTo: string): any => (dispatch: Dispatch): void => {
  dispatch(assignedToChanged(assignedTo));
}