import { createSlice } from "@reduxjs/toolkit";
import { Dispatch } from 'redux';

interface flowData {
    id: number,
    template_used: string
}

const slice = createSlice({
    name:"createWhatsappFlow",
    initialState:{
        showCreatePopup : false,
        selectedJsonTemplate: 'none',
        flow: {} as flowData
    },
    reducers:{
        popupStatusChanged : (state, action) => {
            state.showCreatePopup = action.payload;
        },
        selectedJsonTemplateChanged : (state, action) => {
            state.selectedJsonTemplate = action.payload;
        },
        setFlow: (state, action) => {
            state.flow = action.payload
        }
    }
})

export default slice.reducer;

//Action

const { popupStatusChanged, selectedJsonTemplateChanged, setFlow } = slice.actions;

export const changePopupStatus = (status:boolean): any => (dispatch: Dispatch): void => {
    dispatch(popupStatusChanged(status))
}

export const changeSelectedJsonTemplate = (template: string): any =>  (dispatch: Dispatch): void => {
    dispatch(selectedJsonTemplateChanged(template))
}

export const updateFlow = (flow: any): any =>  (dispatch: Dispatch): void => {
    dispatch(setFlow(flow))
}