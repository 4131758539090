import React from 'react';
import { Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';

//  spinner
import PuffLoader from "react-spinners/PuffLoader";

export const LoadingSpinner = (): JSX.Element => {
  const theme = useTheme();

  return (
    <Stack data-testid="loading-spinner-container" alignItems='center' justifyContent='center' height={'100%'} width={'100%'}>
      <PuffLoader data-testid="puff-loading-spinner" color={theme.palette.primary.main} size={100} />
    </Stack>
  );
}
