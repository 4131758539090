import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Typography } from '@mui/material';

//  utils
import { useTranslation } from 'react-i18next';

export const AccountBlocked = (): JSX.Element => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 786 });

  return (
    <Typography sx={{ backgroundColor: 'error.solid2', color: 'light.main', textAlign: 'center', mx: isMobile ? 2 : 4, mt: isMobile ? 1 : 2, px: isMobile ? 1 : 4, py: isMobile ? 1 : 2.75, borderRadius: 2, fontSize: 14, fontWeight: 600 }}>{t('BILLING_SUBSCRIPTION.ACCOUNT_BLOCKED')}</Typography>
  )
}