import React, { useEffect, useState } from 'react'
import { handleGetToken } from '../firebase';
import axios from 'axios';

export const DeviceToken = () => {
    const serviceKey: string = 'AAAAI2ZJcDY:APA91bGyp02-DRB8zq7F9L4rwJ6m3EYcwVx0CJUXYlr0ZDiAjAmc0DvRjCT4CF2a8t5g18hCGXR32veLQM6F_M5Qd0ZCOx25jvapg5FE1CcXgJNzeN5i96BlFWC6yrNtThp5yLGKxRNq';
    const firebaseTokenPromise = handleGetToken();
    const [deviceTokn, setDeviceToken] = useState<string>('');



    useEffect(() => {
        firebaseTokenPromise.then(newToken => {
            // Assign the value to newTokenArr inside the then block
            setDeviceToken(newToken);
        });
    }, [])
    return (
        <h1>{deviceTokn}</h1>
    )
}
