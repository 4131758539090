import { createSlice } from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
import { CTAbutton, QRbutton } from '../models/TemplateButtonForms';

const CTABtns: CTAbutton[] = [];
const quickReplyBtns: QRbutton[] = [];

// Slice
const slice = createSlice({
    name: 'createTemplate',
    initialState: {
        // these data is for creating a template
        CTABtns,
        quickReplyBtns,
        templateID: '',
        langauge: '',
        bodyText: '',
        category: '',
        mediaType: '',
        numberOfVariables: 0,

        // for page preview
        dataPreview: null as any,

        // check if user inserted variables to the body text or not
        numOfInsertedVars: 0,
        payload: {} as any,
        component: [] as any[],

        uploadedImageName: '',

        // template lenght
        allTemplatesLength: 0 as number,
        deletedTemplatesLength: 0 as number
    },
    reducers: {
        // set preview page
        setDataPreview: (state, action) => {
            state.dataPreview = action.payload.data;
        },
        // set varaibles data to transfer it to the variables page template/create/2
        setVariables: (state, action) => {
            const { numOfInsertedVars, payload, component } = action.payload;
            state.numOfInsertedVars = numOfInsertedVars;
            state.payload = payload;
            state.component = component;
        },

        // CTA
        CTAButtonAdded: (state, action) => {
            if (state.CTABtns.length < 2) {
                
                if (action.payload.button) {
                    state.CTABtns.push(action.payload.button);
                } else {
                    if (state.CTABtns.length && state.CTABtns[0].type === 'PHONE_NUMBER') {
                        state.CTABtns.push({ type: 'URL', phone_number: '', url: '', text: '' });
                    } else {
                        state.CTABtns.push({ type: 'PHONE_NUMBER', phone_number: '', url: '', text: '' });
                    }
                }
            }
        },
        CTABtnUpdated: (state, action) => {
            const { index, button } = action.payload;
            state.CTABtns[index] = button;
        },
        CTABtnDeleted: (state, action) => {
            state.CTABtns.splice(action.payload.index, 1);
        },
        CTAReset: (state) => {
            state.CTABtns = [];
        },

        // Quick Reply
        quickReplyBtnAdded: (state, action) => {
            if (state.quickReplyBtns.length < 3) {
                if (action.payload.button) {
                    state.quickReplyBtns.push(action.payload.button);
                } else {
                    state.quickReplyBtns.push({ type: "QUICK_REPLY", text: '' });
                }
            }
        },
        quickReplyBtnUpdated: (state, action) => {
            const { index, text } = action.payload;
            state.quickReplyBtns[index].text = text;
        },
        quickReplyBtnDelete: (state, action) => {
            state.quickReplyBtns.splice(action.payload.index, 1);
        },
        quickReplyReset: (state) => {
            state.quickReplyBtns = [];
        },


        setTemplateID: (state, action): void => {
            state.quickReplyBtns = action.payload.templateID;
        },
        setLanguage: (state, action): void => {
            state.langauge = action.payload.langauge;
        },
        setBodyText: (state, action): void => {
            state.bodyText = action.payload.bodyText;
        },
        setCategory: (state, action): void => {
            state.category = action.payload.category;
        },
        setMediaType: (state, action): void => {
            state.mediaType = action.payload.mediaType;
        },
        setNumberOfVariables: (state, action): void => {
            state.numberOfVariables = action.payload.numberOfVariables;
        },
        setUploadedImageName: (state, action): void => {
            state.uploadedImageName = action.payload.uploadedImageName;
        },

        setAllTemplatesLength: (state, action): void => {
            state.allTemplatesLength = action.payload;
        },
        setDeletedTemplatesLength: (state, action): void => {
            state.deletedTemplatesLength = action.payload;
        }
    }
},
);

export default slice.reducer;

export const createTemplate = slice.reducer;

// Actions
const {
    // CTA
    CTAButtonAdded,
    CTABtnUpdated,
    CTABtnDeleted,
    CTAReset,

    // Quick Reply
    quickReplyBtnAdded,
    quickReplyBtnUpdated,
    quickReplyBtnDelete,
    quickReplyReset,

    // Template data regarding buttons
    setTemplateID,
    setLanguage,
    setBodyText,
    setCategory,
    setMediaType,
    setNumberOfVariables,

    setVariables,

    setDataPreview,

    setUploadedImageName,

    // lengthes
    setAllTemplatesLength,
    setDeletedTemplatesLength
} = slice.actions;

// CTA
export const addCTABtn = (button?: any): any => (dispatch: Dispatch): void => {
    dispatch(CTAButtonAdded({ button }));
}

export const updateCTABtn = (index: number, button: CTAbutton): any => (dispatch: Dispatch): void => {
    dispatch(CTABtnUpdated({ index, button }));
}

export const deleteCTABtn = (index: number): any => (dispatch: Dispatch): void => {
    dispatch(CTABtnDeleted({ index }));
}

export const resetCTA = (): any => (dispatch: Dispatch): void => {
    dispatch(CTAReset());
}

// Quick Reply
export const addQuickReplyBtn = (button?: any): any => (dispatch: Dispatch): void => {
    dispatch(quickReplyBtnAdded({ button }));
}

export const updateQuickReplyBtn = (index: number, text: string): any => (dispatch: Dispatch): void => {
    dispatch(quickReplyBtnUpdated({ index, text }));
}

export const deleteQuickReplyBtn = (index: number): any => (dispatch: Dispatch): void => {
    dispatch(quickReplyBtnDelete({ index }));
}

export const resetQuickReply = (): any => (dispatch: Dispatch): void => {
    dispatch(quickReplyReset());
}

// Data regarding buttons
export const updateTemplateID = (templateID: string): any => (dispatch: Dispatch): void => {
    dispatch(setTemplateID({ templateID }));
}

export const updateLangauge = (language: string): any => (dispatch: Dispatch): void => {
    dispatch(setLanguage({ language }));
}

export const updateBodyText = (bodyText: string): any => (dispatch: Dispatch): void => {
    dispatch(setBodyText({ bodyText }));
}

export const updateCategory = (category: string): any => (dispatch: Dispatch): void => {
    dispatch(setCategory({ category }));
}

export const updateMediaType = (mediaType: string): any => (dispatch: Dispatch): void => {
    dispatch(setMediaType({ mediaType }));
}

export const updateNumberOfVariables = (numberOfVariables: number): any => (dispatch: Dispatch): void => {
    dispatch(setNumberOfVariables({ numberOfVariables }));
}

export const updateVariables = (numOfInsertedVars: number, payload: any, component: any): any => (dispatch: Dispatch): void => {
    dispatch(setVariables({ numOfInsertedVars, payload, component }));
}

export const updateDataPreview = (data: any): any => (dispatch: Dispatch): void => {  
    dispatch(setDataPreview({data}));
}

export const updateUploadedImageName = (uploadedImageName : any): any => (dispatch: Dispatch): void => {  
    dispatch(setUploadedImageName({uploadedImageName}));
}

// setAllTemplatesLength,
// setDeletedTemplatesLength

export const updateAllTemplatesLength = (allTemplatesLength : number): any => (dispatch: Dispatch): void => {  
    dispatch(setAllTemplatesLength(allTemplatesLength));
}

export const updateDeletedTemplatesLength = (deletedTemplatesLength : number): any => (dispatch: Dispatch): void => {  
    dispatch(setDeletedTemplatesLength(deletedTemplatesLength));
}