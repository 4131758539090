import React from 'react';
import { Stack } from '@mui/material';

//  icons
import BarChartIcon from '@mui/icons-material/BarChart';
import EmojiEmotionsOutlinedIcon from '@mui/icons-material/EmojiEmotionsOutlined';
import GroupsIcon from '@mui/icons-material/Groups';

//  components
import { SettingButton } from '../../../components/Settings/SettingButton';

//  translations
import { useTranslation } from 'react-i18next';

//  redux
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';

//  helpers
import { getAssetUrl } from '../../../helpers/assets';

export const AnalyticsButtons = (): JSX.Element => {
    const { t } = useTranslation();
    const path = useSelector((state: RootState) => state.sdk.currentRoute.path);

    const isActive = (route: string): boolean => {
        return path === route;
    }
    
    return (
        <Stack data-testid='AnalyticsButtons' gap={1} flex={1} px={2} py={3} maxWidth='200px' minWidth='200px' sx={{ borderInlineEnd: '1px solid', borderColor: 'light.gray3' }}>
            <SettingButton style={{ p: 1 }} route='/dashboard/overview' text={t('DASHBOARD.OVERVIEW')} icon={<BarChartIcon />} isActive={isActive('/dashboard/overview')} />            
            <SettingButton style={{ p: 1 }} route='/dashboard/reports' text={t('DASHBOARD.CSAT_REPORTS')} icon={<EmojiEmotionsOutlinedIcon />} isActive={isActive('/dashboard/reports')} />
            <SettingButton style={{ p: 1 }} route='/dashboard/agents' text={t('DASHBOARD.AGENTS')} icon={<GroupsIcon />} isActive={isActive('/dashboard/agents')} />
            <SettingButton style={{ p: 1 }} route='/dashboard/teams' text={t('DASHBOARD.TEAMS')} icon={<img alt="DASHBOARD.TEAMS" src={getAssetUrl(isActive('/dashboard/teams') ? 'teams-active.svg' : 'teams-inactive.svg')} width={24} height={24} />} isActive={isActive('/dashboard/teams')} />
            <SettingButton style={{ p: 1 }} route='/dashboard/conversations' text={t('DASHBOARD.CONVERSATIONS')} icon={<img alt="DASHBOARD.CONVERSATIONS" src={getAssetUrl(isActive('/dashboard/conversations') ? 'message-active.svg' : 'message-inactive.svg')} width={24} height={24} />} isActive={isActive('/dashboard/conversations')} />
        </Stack>
    );
}