
import axios from "axios";
import environment from "../environment";
import { logout } from "./auth";

// const cached_queries: string[] = [];

export const fetchMessages = async ({ queryKey }) => {
  const [_, search, type, direction, msgType, { from, to }, chatStatus, assignedTo, tags, limit, offset, contact_id] = queryKey;
  if (contact_id !== undefined && !search && !msgType.length && !direction && !from) return { total: 0 };
  // if (cached_queries.includes(JSON.stringify(queryKey))) return;
  // cached_queries.push(JSON.stringify(queryKey));
  const options = {
    method: 'GET',
    url: `${environment.REACT_APP_API_GATEWAY_ENDPOINT}/search`,
    params: {
      text: search,
      type: type === 0 ? 'message' : 'contact',
      agent_id: assignedTo || undefined,
      contact_id,
      direction: direction || undefined,
      from: from?.replace('Z', ''),
      to: to?.replace('Z', ''),
      message_type: msgType ? msgType.join(',').toLowerCase() : undefined,
      // since: '500d',
      // is_team: false,
      status: chatStatus || undefined,
      tags: tags.join(',') || undefined,
      start: offset,
      limit: limit
    },
    headers: {
      'x-hasura-business-id': window.business_id,
      Accept: 'application/json',
      Authorization: localStorage.getItem('avc-token')
    }
  };

  try {
    const { data } = await axios.request(options);
    return data;
  } catch (error) {
    if (error.response.status === 401) logout('SESSION_TIMED_OUT');
    return {};
  }
}
