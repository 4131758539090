import axios from "axios";
import i18next from "i18next";
import environment from "../environment";
import { setCurrentRoute } from "../redux/sdk";
import store from "../redux/store";
import { publish } from "./events";

const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json'
}

export const isTokenExpired = (token: string): boolean => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  const { exp } = JSON.parse(jsonPayload);
  return (Date.now() >= exp * 1000);
}

export const login = async (payload: any) => {
  const options = {
    method: 'POST',
    url: `${environment.REACT_APP_API_GATEWAY_ENDPOINT}/auth/login`,
    headers,
    data: {
      business_id: payload.workspace,
      platform_type: 'avocado',
      platform_id: payload.email,
      password: payload.password
    }
  };
  
  const { data } = await axios.request(options);
  return data;
}

export const register = async (payload: any) => {
  const options = {
    method: 'POST',
    url: `${environment.REACT_APP_API_GATEWAY_ENDPOINT}/auth/register`,
    headers,
    data: {
      name: payload.name,
      platform_type: 'avocado',
      platform_id: payload.email,
      password: payload.password,
      language: i18next.language
    }
  };
  
  const { data } = await axios.request(options);
  return data;
}

export const signup = async (payload: any, token: string) => {
  const options = {
    method: 'POST',
    url: `${environment.REACT_APP_API_GATEWAY_ENDPOINT}/auth/signup`,
    headers,
    params: {
      token
    },
    data: payload
  };
  
  const { data } = await axios.request(options);
  return data;
}

export const forgot_password = async (payload: any) => {
  const options = {
    method: 'POST',
    url: `${environment.REACT_APP_API_GATEWAY_ENDPOINT}/auth/forgot.password`,
    params: {
      action: 'send_email'
    },
    headers,
    data: {
      platform_type: "avocado",
      platform_id: payload.email
    }
  };
  
  const data = await axios.request(options);
  return data;
}

export const reset_password = async (password: string, token: string) => {
  const options = {
    method: 'POST',
    url: `${environment.REACT_APP_API_GATEWAY_ENDPOINT}/auth/forgot.password`,
    params: {
      action: 'verify',
      token
    },
    headers: {
      ...headers,
      'x-avc-password': password
    },
  };
  
  const { data } = await axios.request(options);
  return data;
}

export const change_password = async (payload: any) => {
  const options = {
    method: 'POST',
    url: `${environment.REACT_APP_API_GATEWAY_ENDPOINT}/auth/change.password`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: localStorage.getItem('avc-token')
    },
    data: payload
  };
  
  const { data } = await axios.request(options);
  return data;
}

export const get_businesses = async (email: string) => {
  const options = {
    method: 'GET',
    url: `${environment.REACT_APP_API_GATEWAY_ENDPOINT}/auth/get.businesses`,
    headers: {
      'x-avc-platform-id': email,
      'x-avc-platform-type': 'avocado',
      Accept: 'application/json'
    }
  };
  
  const { data } = await axios.request(options);
  return data;
}

export const logout = async (reason?: string) => {
  localStorage.removeItem('avc-token');
  localStorage.removeItem('avc-business_id');
  localStorage.removeItem('avc-user_id');
  localStorage.removeItem('avc-user_email');
  localStorage.removeItem('avc-user_details');

  if (reason === 'SESSION_TIMED_OUT') {
    const currentScreen = store.getState().sdk.currentRoute.path;
    if (currentScreen === '/login') return;
    publish('SESSION_TIMED_OUT', {});
    await new Promise((resolve) => setTimeout(resolve, 4000));
  }
  
  window.sdk.logoutCallback?.();
  
  store.dispatch(setCurrentRoute('/login'));
  if (store.getState().sdk.mode === 'host') window.location.replace('/login');
}