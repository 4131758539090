import React from 'react';
import { Button, ButtonBase, FormHelperText, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material';

//  utils
import { useTranslation } from 'react-i18next';

//  validations
import * as yup from 'yup';
import { useForm,  Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

export const SelectRole = ({ setTab, detailsRef }): JSX.Element => {
  const { t } = useTranslation();

  const schema = yup.object().shape({
    role: yup.string().required(),
    other_role: yup.string().when("role", {
      is: 'other',
      then: () => yup.string().required("Must enter other role")
    })
  });

  const { control, handleSubmit, formState: { errors } } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: { role: '' }
  });

  const next = ({ role, other_role }: any) => {
    detailsRef.current.realm.admin_role = (role === 'other' ? other_role : role);
    setTab(7);
  }

  const skip = () => {
    detailsRef.current.realm.admin_role = "N/A";
    setTab(7);
  }
  
  const rolesOptions: any = t('LOGIN.ROLES', { returnObjects: true });

  return (
    <Stack alignItems="center">
      <Typography fontSize={{md:14, xs:12, sm:13}} fontWeight={500} textAlign="center" sx={{ color: 'text.secondary', mb: 2 }}>{t('LOGIN.ROLE_SUBTITLE')}</Typography>
      <Controller name='role' control={control} render={({ field: { value, onChange } }) => (
        <RadioGroup value={value} sx={{ width: '100%', overflowY: 'auto', gap: 2, p:{md:3, sm:2, xs: 1}, boxSizing: 'border-box' }}>
          { Object.entries(rolesOptions).map(([key, text]: any) => (
            <ButtonBase key={key} onClick={() => onChange(key)} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: {md:2, xs:1}, px: {md:3, xs:1.5}, py: {md:2, xs:1}, width: '100%', boxShadow: 7, borderRadius: 2 }}>
              <Typography>{text}</Typography>
              <Radio value={key} onClick={onChange} onMouseDown={e => e.stopPropagation()} />
            </ButtonBase>
          )) }
          <ButtonBase onClick={() => onChange('other')} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 2, px: {md:3, xs:1}, py: {md:2, xs:1}, width: '100%', boxShadow: 7, borderRadius: 2 }}>
            <Controller name='other_role' control={control} render={({ field: { value, onChange, onBlur } }) => (
              <TextField variant='standard' value={value} onChange={onChange} onBlur={onBlur} placeholder={t('LOGIN.SOMETHING_ELSE').toString()} InputProps={{ disableUnderline: true }} />
            )} />
            <Radio value={'other'} onClick={onChange} onMouseDown={e => e.stopPropagation()} />
          </ButtonBase>
        </RadioGroup>
      )} />
      { errors.role && <FormHelperText data-testid="role-error" sx={{ color: 'error.main', width: '100%', boxSizing: 'border-box', paddingInlineStart: 4 }}>{ errors.role.message }</FormHelperText> }
      { errors.other_role && <FormHelperText data-testid="other_role-error" sx={{ color: 'error.main', width: '100%', boxSizing: 'border-box', paddingInlineStart: 4 }}>{ errors.other_role.message }</FormHelperText> }
      <Stack flexDirection="row" alignItems="center" gap={2} sx={{ mt: 2, px: 6, width: '100%', boxSizing: 'border-box' }}>
        <Button onClick={handleSubmit(next)} disableElevation variant="contained" sx={{ flex: 1, borderRadius: 2, fontSize: 15, fontWeight: 600 }}>{t('COMMON.CONTINUE')}</Button>
        <Button onClick={skip} variant="outlined" sx={{ flex: 1, borderRadius: 2, fontSize: 15, fontWeight: 600 }}>{t('COMMON.SKIP')}</Button>
      </Stack>
    </Stack>
  );
}