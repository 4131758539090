import { ThemeProvider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';

//  SDK
import Avocado from './Avocado';

// graphql
import { ApolloProvider } from "@apollo/client";
import { client_factory } from './graphql/client';

// react query
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

//  theme
import { theme_factory } from './theme/theme';

// css file
import './index.css';

// redux
import { Provider, useSelector } from 'react-redux';
import store from './redux/store';

// translations
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import arTranslation from './assets/translations/ar';
import enTranslation from './assets/translations/en';

//  models
import { SdkProps } from './models/sdk';

//  logrocket
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

// Analytics
import { Analytics } from "@vercel/analytics/react";
//import { CopilotChat } from './components/Copilot/CopilotChat';
import environment from './environment';
import { subscribe } from './helpers/events';


i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: enTranslation,
    },
    ar: {
      translation: arTranslation,
    }
  },
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false
  }
});

function App(props: SdkProps) {
  LogRocket.init(environment.REACT_APP_LOGROCKET_APP_ID as string, { release: environment.REACT_APP_VERSION });
  LogRocket.identify(window.business_id, {
    mode: props.mode
  });
  setupLogRocketReact(LogRocket);

  const queryClient = new QueryClient();
  const theme = useSelector
  const [client, setClient] = useState(client_factory());

  useEffect(() => {
    subscribe('LOGIN', () => setClient(client_factory()));
  }, []);

  return (
    <>
      <Provider store={store}>
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <ApolloProvider client={client}>
              <ThemeProvider theme={theme_factory()}>
                <Avocado {...props} />
                {/* <CopilotChat /> */}
              </ThemeProvider>
            </ApolloProvider>
          </QueryClientProvider>
        </BrowserRouter>
      </Provider>
      <Analytics />      
    </>
  );
}

export default App;
