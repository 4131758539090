import React from 'react';
import { Route, Routes } from 'react-router-dom';

//  components
import { Lazy } from '../../components/Common/Lazy';

//  pages
const Profile = React.lazy(() => import('../../layouts/Settings/Account/Profile'));
const Notifications = React.lazy(() => import('../../layouts/Settings/Account/Notifications'));
const Error = React.lazy(() => import('../../pages/Error'));

export const AccountSettings = (): JSX.Element => {
    return (
        <Routes>
            <Route path='/profile' element={<Lazy component={<Profile />} />} />
            <Route path='/notifications' element={<Lazy component={<Notifications />} />} />
            <Route path="*" element={<Lazy component={<Error type={404} />} />} />
        </Routes>
    );
}