import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Stack, Typography } from '@mui/material';

//  icons
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';

//  translations
import { useTranslation } from 'react-i18next';

//  redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { navigateBack, setCurrentRoute } from '../redux/sdk';

//  models
import { ErrorPageProps } from '../models/Common';

//  helpers
import { getAssetUrl } from '../helpers/assets';

export const ErrorPage = ({ type }: ErrorPageProps): JSX.Element => {
  //  hooks
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  //  redux state
  const mode = useSelector((state: RootState) => state.sdk.mode);
  const routesStack = useSelector((state: RootState) => state.sdk.routesStack);
  
  const goBack = () => {
    if (routesStack.length === 1) {
      // const home = '/dashboard/overview';
      const home = '/chat';
      if (mode === 'host') navigate(home);
      dispatch(setCurrentRoute(home));
    } else {
      if (mode === 'host') navigate(-1);
      else dispatch(navigateBack());
    }
  }

  const handleClick = () => {
    if (type === 'RELOAD') window.location.reload();
    else goBack();
  }
  
  return (
    <Stack alignItems="center" justifyContent="center" gap={3} height="100%" width="100%">
      <img src={String(
        type === 'RELOAD' ? getAssetUrl('no-data.svg') :
        type === 401 ? getAssetUrl('unauthorized.svg') :
        type === 500 ? getAssetUrl('internal-error.svg') :
        getAssetUrl('no-data.svg')
      )} alt="Error Image" height={128} />
      <Stack gap={1} alignItems="center" textAlign="center">
        <Typography fontSize={24} fontWeight={700} lineHeight={1.2}>{
          type === 'RELOAD' ? t('ERROR.RELOAD_TITLE') :
          type === 401 ? t('ERROR.401_TITLE') :
          type === 500 ? t('ERROR.500_TITLE') :
          t('ERROR.404_TITLE')
        }</Typography>
        <Typography fontSize={16} color="text.secondary">{
          type === 'RELOAD' ? t('ERROR.RELOAD_DESCRIPTION') :
          type === 401 ? t('ERROR.401_DESCRIPTION') :
          type === 500 ? t('ERROR.500_DESCRIPTION') :
          t('ERROR.404_DESCRIPTION')
        }</Typography>
      </Stack>
      <Button disableElevation variant="contained" onClick={handleClick} endIcon={<ArrowForwardIosRoundedIcon />} sx={{ borderRadius: 2.5, fontWeight: 600 }}>
        { t(`ERROR.${type === 'RELOAD' ? 'RELOAD_BTN' : 'BACK_BTN'}`) }
      </Button>
    </Stack>
  )
}

export default ErrorPage;