import React from 'react';

//  components
import { Lazy } from '../../components/Common/Lazy';

// redux
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

//  Pages
import Settings from '../../pages/Settings/Settings';
import Analytics from '../../pages/Analytics/Analytics';

//  Lazy Pages
const ChatsPage = React.lazy(() => import('../../pages/Chats'));
const ContactsPage = React.lazy(() => import('../../pages/Contacts'));
const BroadcastPage = React.lazy(() => import('../../pages/Broadcast'));
const CreateBroadcast = React.lazy(() => import('../../pages/CreateBroadcast'));
const AppsPage = React.lazy(() => import('../../pages/Apps'));
const ManageTemplates = React.lazy(() => import('../../pages/Templates'));
const CreateTemplate = React.lazy(() => import('../../pages/CreateTemplates'));
const GreetingAndAwayMessages = React.lazy(() => import('../../pages/GreetingAndAway'));
const QuickReply = React.lazy(() => import('../../pages/QuickReply'));
const Rules = React.lazy(() => import('../../pages/Rules'));
const Tags = React.lazy(() => import('../../pages/Tags'));
const CustomAttributes = React.lazy(() => import('../../pages/CustomAttributes'));
const WhatsappIntegration = React.lazy(() => import('../../pages/WhatsappIntegration'));
const Csml = React.lazy(() => import('../../pages/Csml'));
const HubSpot = React.lazy(() => import('../../pages/HubSpot'));
const MediaLibrary = React.lazy(() => import('../../pages/MediaLibrary'));
const BillingSubscription = React.lazy(() => import('../../pages/BillingSubscription'));
const Error = React.lazy(() => import('../../pages/Error'));

export const SdkRouter = () => {
  
  const path = useSelector((state: RootState) => state.sdk.currentRoute.path);
  
  return (
    path === '/chat' ? <Lazy component={<ChatsPage />} /> :
    path === '/contacts' ? <Lazy component={<ContactsPage />} /> :
    path === '/apps' ? <Lazy component={<AppsPage />} /> :
    path.startsWith('/apps/broadcast') ? <Lazy component={<BroadcastPage />} /> :
    path === '/apps/broadcast/create' ? <Lazy component={<CreateBroadcast />} /> :
    path === '/apps/templates' ? <Lazy component={<ManageTemplates />} /> :
    path === '/apps/templates/create' ? <Lazy component={<CreateTemplate />} /> :
    path === '/apps/greeting' ? <Lazy component={<GreetingAndAwayMessages />} /> :
    path.startsWith('/settings') ? <Settings /> :
    path.startsWith('/dashboard') ? <Analytics /> :
    path === '/apps/quick-reply' ? <Lazy component={<QuickReply />} /> :
    path === '/apps/rules' ? <Lazy component={<Rules />} /> :
    path === '/apps/tags' ? <Lazy component={<Tags />} /> :
    path === '/apps/custom-attributes' ? <Lazy component={<CustomAttributes />} /> :
    path === '/whatsapp-integration' ? <Lazy component={<WhatsappIntegration />} /> :
    path === '/apps/csml' ? <Lazy component={<Csml />} /> :
    path === '/apps/hubspot' ? <Lazy component={<HubSpot />} /> :
    path === '/media-library' ? <Lazy component={<MediaLibrary />} /> :
    path === '/billing-subscription' ? <Lazy component={<BillingSubscription />} /> :
    <Lazy component={<Error type={404} />} />
  )
}
