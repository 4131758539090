export enum RuleType {
    WHEN = 'WHEN',
    IF = 'IF',
    THEN = 'THEN'
}
export interface TriggerBoxProps {
    type: RuleType;
}

export interface TriggerProps {
    index: number;
}

export interface RuleRowProps {
    rule: any;
    isLoading: boolean;
}

export interface CreateRulePopupProps {
    filter: {
        active: boolean;
        search: string;
    }
    data_length: number;
}

export interface SelectAgentProps {
    type: 'assign_chat' | 'mention';
    placeholder: string;
    index: number;
    disabled?: boolean;
}

export interface AddTagProps {
    type: string;       // 'add_tag' | 'remove_tag'
    index: number;
    disabled?: boolean;
}

export interface SnoozeConversationProps {
    index: number;
    disabled?: boolean;
}