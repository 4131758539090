import { createSlice } from '@reduxjs/toolkit';
import { Dispatch } from 'redux';

// Slice
const slice = createSlice({
  name: 'templates',
  initialState: {
    filter: {
      status: '',
      language: '',
      category: '',
      search: '',
      page: 1,
    } as any
  },
  reducers: {
    filterChanged: (state, action) => {
      state.filter = action.payload;
    },
  },
});

export default slice.reducer;

// Actions
const { filterChanged } = slice.actions;

export const changeFilter = (filter: any): any => (dispatch: Dispatch): void => {
  dispatch(filterChanged(filter));
}
