import { CustomEventName } from "../models/Common";

function subscribe(eventName: CustomEventName, listener: (data: any) => void) {
  document.addEventListener(eventName, listener);
}

function unsubscribe(eventName: CustomEventName, listener: (data: any) => void) {
  document.removeEventListener(eventName, listener);
}

function publish(eventName: CustomEventName, data: any) {
  const event = new CustomEvent(eventName, { detail: data });
  document.dispatchEvent(event);
}

export { publish, subscribe, unsubscribe};