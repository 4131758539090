import React, { createContext, useEffect, useState } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { GlobalStyles, ThemeProvider } from '@mui/material';

//  components
import { Lazy } from './components/Common/Lazy';
import { SdkRouter } from './components/Common/SdkRouter';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from './redux/store';
import { setCurrentRoute, init, setMode, navigateBack } from './redux/sdk';
import { handleGetToken } from './firebase';

//  models
import { SdkProps } from './models/sdk';

//  Pages
import Settings from './pages/Settings/Settings';
import Analytics from './pages/Analytics/Analytics';
import { Login } from './pages/Login';

//  utils
import moment from 'moment';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import 'moment/locale/ar';

//  helpers
import { isTokenExpired } from './helpers/auth';
import { NotAuthNotification } from './components/Login/NotAuthNotification';
import { MainRoutesWrapper } from './components/Common/MainRoutesWrapper';
import { DeviceToken } from './pages/DeviceToken';

//  gql
// import { client_factory } from './graphql/client';
// import { MEETING_LISTENER } from './graphql/queries/Meeting';
// import { updateCallerData, updateMeetingToken } from './redux/meeting';

//  Lazy Pages
const ChatsPage = React.lazy(() => import('./pages/Chats'));
const ContactsPage = React.lazy(() => import('./pages/Contacts'));
const BroadcastPage = React.lazy(() => import('./pages/Broadcast'));
const CreateBroadcast = React.lazy(() => import('./pages/CreateBroadcast'));
const AppsPage = React.lazy(() => import('./pages/Apps'));
const Templates = React.lazy(() => import('./pages/Templates'));
const CreateTemplate = React.lazy(() => import('./pages/CreateTemplates'));
const GreetingAndAwayMessages = React.lazy(() => import('./pages/GreetingAndAway'));
const QuickReply = React.lazy(() => import('./pages/QuickReply'));
const Rules = React.lazy(() => import('./pages/Rules'));
const Tags = React.lazy(() => import('./pages/Tags'));
const CustomAttributes = React.lazy(() => import('./pages/CustomAttributes'));
const WhatsappIntegration = React.lazy(() => import('./pages/WhatsappIntegration'));
const Csml = React.lazy(() => import('./pages/Csml'));
const HubSpot = React.lazy(() => import('./pages/HubSpot'));
const Knowledge = React.lazy(() => import('./pages/Knowledge'));
const MediaLibrary = React.lazy(() => import('./pages/MediaLibrary'));
const WhatsappFlow = React.lazy(() => import('./pages/WhatsappFlow'));
const WhatsAppFlowEditor = React.lazy(() => import('./pages/WhatsAppFlowEditor'));
const BillingSubscription = React.lazy(() => import('./pages/BillingSubscription'));
const Error = React.lazy(() => import('./pages/Error'));


function Avocado({ currentScreen, mode = 'sdk', sdk }: SdkProps) {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  moment.locale(i18n.language);

  const authenticated = useSelector((state: RootState) => state.sdk.authenticated);
  const theme = useSelector((state: RootState) => state.sdk.theme);
  const [firebaseToken, setFirebaseToken] = useState<string>('');

  // #TODO: Once we have the meeting integration ready "FULLY", we can uncomment this code
  // const apolloClient = client_factory();
  // apolloClient.subscribe({
  //   query: MEETING_LISTENER,
  //   variables: {
  //     business_id: window.business_id,
  //     agent_id: localStorage.getItem('avc-user_id')
  //   },
  // }).subscribe({
  //   next: (result: any) => {
  //     if (result?.data?.meetings?.length > 0) {
  //       const meetingToken: string = result?.data?.meetings[0].auth_token;
  //       const callerName: string = result?.data?.meetings[0].agent_host?.contact_profiles[0]?.display_name;
  //       const callerImg: string = result?.data?.meetings[0].agent_host?.contact_profiles[0]?.image;
  //       dispatch(updateCallerData({ callerName: callerName, callerImg: callerImg }));
  //       dispatch(updateMeetingToken(meetingToken));
  //     }
  //   },
  //   error: (error) => {
  //     console.log(error);
  //   },
  // });

  useEffect(() => {
    let initialScreen = currentScreen;
    const params = window.location.search;
    const params_obj = {};
    for (const entry of new URLSearchParams(params).entries()) {
      params_obj[entry[0]] = entry[1];
    }
    const token = localStorage.getItem('avc-token') as string;
    if (!token || isTokenExpired(token.split(' ')[1])) {
      initialScreen = '/login';
    } else if (currentScreen === '/login' || !initialScreen) {
      initialScreen = window.screen.width <= 786 ? '/chat' : '/dashboard/overview';
    }
    if (mode === 'host') navigate(initialScreen + params);
    dispatch(setCurrentRoute(initialScreen, params_obj));
  }, [currentScreen]);

  useEffect(() => dispatch(setMode(mode)), [mode]);

  useEffect(() => dispatch(init(sdk)), [sdk]);

  useEffect(() => {
    i18next.changeLanguage(localStorage.getItem('language') || 'en');
    document.dir = i18next.dir();
    if (mode === 'host') {
      window.addEventListener('popstate', (e) => {
        dispatch(navigateBack());
      });
    }

    // In the effect that listens for storage changes
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === 'avc-token') {
        window.location.reload();
      }
    };

    window.addEventListener('storage', handleStorageChange);

    console.log(process.env);
    if(process.env.REACT_APP_AVOCADO_ENV === 'dev') {
      console.log = () => {};
    }
    
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  if(!authenticated) return <Login />
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles
      styles={
        (theme: any) => ({
          'p, h1, h2, h3, h4, h5, h6': { color: theme.palette.text.main },
          '.MuiTableCell-root, .MuiTableRow-root, .MuiTableHead-root': { borderColor: `${theme.palette.light.gray3} !important` }
        })
      }
    />
    { mode === 'host' ?
    // HOST VIEW
      <Routes>
      <Route path='/login' element={<Login />} />
      <Route path='/*' element={
        <MainRoutesWrapper routes={
          <Routes>
            <Route path="/" element={<Navigate to='/dashboard' replace />} />
            <Route path='/chat' element={<Lazy component={<ChatsPage />} />} />
            <Route path='/contacts' element={<Lazy component={<ContactsPage />} />} />
            <Route path='/apps' element={<Lazy component={<AppsPage />} />} />
            <Route path='/apps/broadcast/*' element={<Lazy component={<BroadcastPage />} />} />
            <Route path='/apps/broadcast/create' element={<Lazy component={<CreateBroadcast />} />} />
            <Route path='/apps/templates' element={<Lazy component={<Templates />} />} />
            <Route path='/apps/templates/create/*' element={<Lazy component={<CreateTemplate />} />} />
            <Route path='/apps/greeting' element={<Lazy component={<GreetingAndAwayMessages />} />} />
            <Route path='/settings/*' element={<Settings />} />
            <Route path='/dashboard/*' element={<Analytics />} />
            <Route path='/apps/quick-reply' element={<Lazy component={<QuickReply />} />} />
            <Route path='/apps/rules' element={<Lazy component={<Rules />} />} />
            <Route path='/apps/tags' element={<Lazy component={<Tags />} />} />
            <Route path='/apps/custom-attributes' element={<Lazy component={<CustomAttributes />} />} />
            <Route path='/whatsapp-integration' element={<Lazy component={<WhatsappIntegration />} />} />
            <Route path='/apps/csml' element={<Lazy component={<Csml />} />} />
            <Route path='/apps/hubspot' element={<Lazy component={<HubSpot />} />} />
            <Route path='/apps/knowledge' element={<Lazy component={<Knowledge />} />} />
            <Route path='/media-library' element={<Lazy component={<MediaLibrary />} />} />
            <Route path='/apps/whatsapp-flow' element={<Lazy component={<WhatsappFlow />} />} />
            <Route path='/apps/whatsapp-flow/editor' element={<Lazy component={<WhatsAppFlowEditor />} />} />
            <Route path='/billing-subscription' element={<Lazy component={<BillingSubscription />} />} />
            <Route path='/device-token' element={<Lazy component={<DeviceToken />} />} />
            <Route path="*" element={<Lazy component={<Error type={404} />} />} />
          </Routes>
        } />
      } />
    </Routes>
     :
    // SDK VIEW
    authenticated ?
    <MainRoutesWrapper routes={<SdkRouter />} /> :
    <Login />
    }
    <NotAuthNotification />
    </ThemeProvider >
  );
}

export default Avocado;
