// eslint-disable-next-line import/no-anonymous-default-export
export const enviroments = {
  REACT_APP_API_GATEWAY_ENDPOINT: process.env?.REACT_APP_API_GATEWAY_ENDPOINT || "https://api.avocad0.dev/v1",
  REACT_APP_AVOCADO_ENV: process.env?.REACT_APP_AVOCADO_ENV || "dev",
  REACT_APP_HASURA_ENDPOINT: process.env?.REACT_APP_HASURA_ENDPOINT || "https://hasura.avocad0.dev/v1",
  REACT_APP_VERSION: process.env?.REACT_APP_VERSION || "v1-dev",
  REACT_APP_LOGROCKET_APP_ID: process.env?.REACT_APP_LOGROCKET_APP_ID || "svbjdz/avocado",
  REACT_APP_GM_API_KEY: process.env?.REACT_APP_GM_API_KEY || "AIzaSyAwyQZB_6ALaHelIX8tJv5NT5pilRlkt64",
  REACT_APP_360D_PARTNER_ID: process.env?.REACT_APP_360D_PARTNER_ID || "83fmVkPA",
  REACT_APP_ASSETS_ENDPOINT: process.env?.REACT_APP_ASSETS_ENDPOINT || "https://assets.avocad0.dev",
  REACT_APP_HOST_ID: process.env?.REACT_APP_HOST_ID || "186f1b43-f7e5-4fce-a898-53afae8df838",
}

export default enviroments;