import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Stack, Avatar, Tooltip, Button, ClickAwayListener, Popper, Fade, Typography, ButtonBase, Snackbar, Popover, IconButton } from '@mui/material';

//  icons
import PersonIcon from '@mui/icons-material/Person';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import SettingsIcon from '@mui/icons-material/Settings';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

//  translations
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

//  gql
import { useMutation, useQuery } from '@apollo/client';
import { GET_PROFILE_IMAGE, UPDATE_AGENT_STATUS } from '../../graphql/queries/settings';

//  helpers
import { stringAvatar } from '../../helpers/constants';
import { logout } from '../../helpers/auth';

//  redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { setCurrentRoute } from '../../redux/sdk';

//responsive
import { useMediaQuery } from 'react-responsive';

export const AgentStatus = (): JSX.Element => {
  //  hooks
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isMobile = useMediaQuery({ maxWidth: 786 });
  
  const mode = useSelector((state: RootState) => state.sdk.mode);
  
  //  local states
  const [open, setOpen] = useState(false);
  const [statusOpened, setStatusOpened] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);

  const buttonRef = useRef<HTMLButtonElement>(null);
  const statusButtonRef = useRef<HTMLButtonElement>(null);

  const { data } = useQuery(GET_PROFILE_IMAGE, { variables: { contact_id: window.contact_id } });

  const [EDIT_STATUS] = useMutation(UPDATE_AGENT_STATUS);

  const handleLogout = () => {
    setShowSnackbar(true);
    setOpen(false);
    setTimeout(logout, 1000);
  }

  const goToSettings = (path: string) => {
    dispatch(setCurrentRoute(path));
    setOpen(false);
  }

  const changeStatus = (status: string) => {
    EDIT_STATUS({
      variables: {
        contact_meta_id: data?.core_contact_meta?.[0]?.id,
        status: status.toLowerCase()
      }
    })
    setStatusOpened(false);
  }

  const isRtl = i18next.language === 'ar';
  const avatar_thumbnail = stringAvatar(data?.core_contact_profile[0].display_name);
  const status_color = `sdk_status.${data?.core_contact_meta?.[0]?.status}.solid`;

  return (
    <>
    {isMobile ? 
    <IconButton sx={{ p: 0.75 }} ref={buttonRef} onClick={()=>setOpen(true)} >
      <KeyboardArrowDownIcon />
    </IconButton> : 
    <Tooltip title={t('NAVBAR_TOOLTIP.PROFILE')} placement="right" arrow>
      <Button ref={buttonRef} onClick={() => setOpen(true)}>
        <Avatar src={data?.core_contact_profile[0].image} style={{ backgroundColor: "transparent", width:'24px', height:'24px' }} {...avatar_thumbnail} />
      </Button>
    </Tooltip>
    }

    <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={() => setOpen(false)}>
      <Popper
        open={open}
        anchorEl={buttonRef.current}
        placement={ isRtl ? "auto-start" : "auto-start" }
        sx={{ zIndex: 999, borderRadius: 2 }}
        transition
      >
        {({ TransitionProps }) => ( 
          <Fade {...TransitionProps} timeout={350}>
            <Stack sx={{ borderRadius: 2, backgroundColor: 'light.main', boxShadow: 15 }}>
              <Stack flexDirection="row" alignItems="center" gap={2} sx={{ p: 2 }}>
                <Avatar src={data?.core_contact_profile[0].image} style={{ backgroundColor:'transparent', width: 40, height: 40 }} {...avatar_thumbnail} />
                <Stack alignItems="flex-start" gap={1}>
                  <Stack flexDirection="row" alignItems="center" gap={1}>
                    <Typography fontSize={16} fontWeight={600} lineHeight="24px">{data?.core_contact_profile[0].display_name}</Typography>
                    <Box sx={{ width: 8, height: 8, borderRadius: '50%', backgroundColor: status_color, marginInlineEnd: 4 }}></Box>
                  </Stack>
                  <Button ref={statusButtonRef} endIcon={<KeyboardArrowDownIcon color="secondary" />} onClick={() => setStatusOpened(true)} sx={{
                    py: 0.5,
                    fontWeight: 600,
                    color: status_color,
                    backgroundColor: `sdk_status.${data?.core_contact_meta?.[0]?.status}.alpha`,
                    '&:hover': { backgroundColor: `sdk_status.${data?.core_contact_meta?.[0]?.status}.alpha2` }
                  }}>
                    {t('USERS_SETTINGS.AVAILABLE_OPTIONS.' + data?.core_contact_meta?.[0]?.status.toUpperCase())}
                  </Button>
                </Stack>
              </Stack>

              <Stack sx={{ borderBottom: '1px solid', borderTop: '1px solid', borderColor: 'light.gray3', p: 1 }}>
                <ButtonBase component={Link} to={mode === 'host' ? '/settings/account/profile' : ''} onClick={() => goToSettings('/settings/account/profile')} sx={{ alignItems: 'center', justifyContent: 'flex-start', gap: 2, px: 1, py: 0.5, height: 40, borderRadius: 2 }}>
                  <PersonIcon color="secondary" />
                  <Typography lineHeight="24px" fontSize={16} sx={{ color: 'text.main' }}>{t('SETTINGS.PROFILE')}</Typography>
                </ButtonBase>
                {!isMobile ? 
                <ButtonBase component={Link} to={mode === 'host' ? '/settings/workspace/account-profile' : ''} onClick={() => goToSettings('/settings/workspace/account-profile')} sx={{ alignItems: 'center', justifyContent: 'flex-start', gap: 2, px: 1, py: 0.5, height: 40, borderRadius: 2 }}>
                  <SettingsIcon color="secondary" />
                  <Typography lineHeight="24px" fontSize={16} sx={{ color: 'text.main' }}>{t('SETTINGS.SETTINGS')}</Typography>
                </ButtonBase> : <></>}
              </Stack>
              
              <ButtonBase onClick={handleLogout} data-testid="logout-button" sx={{ p: 1.75, alignItems:'center', justifyContent: 'center', gap: 1, color: 'error.main' }}>
                <LogoutRoundedIcon />
                <Typography variant='body1' fontWeight='500'>{t('SETTINGS.LOGOUT')}</Typography>
              </ButtonBase>
              <Popover
                open={statusOpened}
                anchorEl={statusButtonRef.current}
                onClose={() => setStatusOpened(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                sx={{ '.MuiPaper-root' : { boxShadow: 4, borderRadius: 2 } }}
              >
                <Stack p={1}>
                { Object.entries(t('USERS_SETTINGS.AVAILABLE_OPTIONS', { returnObjects: true })).map(([key, value]: any) => (
                  <Button key={key} sx={{ justifyContent: 'flex-start', width: '100%', minWidth: 100, borderRadius: 2, px: 2.5, color: `sdk_status.${key.toLowerCase()}.solid` }} onClick={() => changeStatus(key)}>{value}</Button>
                ))}
                </Stack>
              </Popover>
            </Stack>
          </Fade>
        )}
      </Popper>
    </ClickAwayListener>
    <Snackbar open={showSnackbar} message={t('SETTINGS.LOGGING_OUT')} onClose={() => setShowSnackbar(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />
    </>
  );
}