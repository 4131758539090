import React, { useEffect } from 'react';
import { Stack } from '@mui/system';
import { Avatar, IconButton, Typography } from '@mui/material';

// icons
import CallIcon from '@mui/icons-material/Call';
import CallEndIcon from '@mui/icons-material/CallEnd';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { updateCalleState } from '../../redux/meeting';
import { RootState } from '../../redux/store';

// toaster
import toast, { Toaster } from 'react-hot-toast';

const CallPopup = ({ name, img, T }): JSX.Element => {
    const dispatch = useDispatch();

    // handlers
    const handleAnswer = (): void => {
        dispatch(updateCalleState(true));
        toast.dismiss(T.id)
    }

    const handleCansel = (): void => {
        dispatch(updateCalleState(true));
        toast.dismiss(T.id)
    }

    return (
        <Stack minWidth={'300px'} p={3} flexDirection={'row'} alignItems={'center'}
            sx={{ backgroundColor: 'rgba(247, 251, 255, 1)' }}>
            <Avatar variant="square" src={img} sx={{ width: 100, height: 100, borderRadius: '15px' }} />
            <Stack ml={5}>
                <Typography fontSize={'32px'}>{name}</Typography>
                <Typography>Icoming call....</Typography>

                <Stack flexDirection={'row'} justifyContent={'space-between'} mt={1.5}>
                    <IconButton onClick={handleAnswer} sx={{ borderRadius: 3, backgroundColor: 'rgba(31, 175, 56, 1)' }}>
                        <CallIcon />
                    </IconButton>

                    <IconButton onClick={handleCansel} sx={{ borderRadius: 3, backgroundColor: 'error.solid2' }}>
                        <CallEndIcon />
                    </IconButton>
                </Stack>
            </Stack>
        </Stack>
    );
}

export const IncomingCallPopup = () => {
    const callerName = useSelector((state: RootState) => state.meeting.callerName);
    const callerImg = useSelector((state: RootState) => state.meeting.callerImg);

    useEffect(() => {
        if (callerName && callerImg) toast.custom((T) => <CallPopup name={callerName} img={callerImg} T={T} />);
    }, [callerName]);

    return (
        <Toaster
            position="top-right"
            reverseOrder={false}
        />
    );
}