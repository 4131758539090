import React, { MouseEvent, useState } from 'react';
import { useNavigate } from "react-router-dom";
import IconButton from '@mui/material/IconButton';

//  icons
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';

//  translations
import i18next from 'i18next';

//  models
import { BackButtonProps } from '../../models/Common';

//  redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { navigateBack } from '../../redux/sdk';

export const BackButton = ({ confirmBack, style }: BackButtonProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [arrowColor, setArrowColor] = useState<string>('rgb(50, 99, 203)')
  const mode = useSelector((state: RootState) => state.sdk.mode);

  const handleClick = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    try {
      if (confirmBack) await confirmBack();
      //  go back is confirmed
      if (mode === 'host') navigate(-1);
      else dispatch(navigateBack());
    } catch (err) {
      // go back is cancelled
    }
  }

  const updateArrowColor = (e: any): void => {
    if(e?.type === "mouseleave") setArrowColor('rgb(50, 99, 203)');
    else setArrowColor('light.main');
  }

  return (
    <IconButton onMouseEnter={updateArrowColor} onMouseLeave={updateArrowColor} data-testid="back-button" onMouseDown={e => e.stopPropagation()} onClick={handleClick} sx={{
    borderRadius: '8px', border: '1px solid', borderColor: 'primary.main', width: {md:35, xs:22}, height: {md:35, xs:22}, p: 0, marginInlineEnd: 2,
      '&:hover': {
        backgroundColor: 'rgb(38, 99, 232)',
        boxShadow: '0px 0px 5px rgba(0, 0, 250, 0.5)',
      }, ...style,
    }}>
      <ArrowBackIosNewRoundedIcon color='primary' sx={{ color:arrowColor, fontSize: '0.8rem', scale: i18next.language === 'ar' ? '-1' : '1' }} />
    </IconButton>
  )
}
