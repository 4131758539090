import gql from 'graphql-tag'
import assignation from './assignation.fragment'

export default gql `
  fragment contact on core_contact {
    contact_id: id
    platform
    platform_id
    is_blocked
    is_archived
    chat_status
    created_at
    profile: contact_profiles {
      profile_id: id
      name
      display_name
      image
    }
    meta_data: contact_meta {
      is_chat_muted
      is_chat_pinned
      unread_msgs_count
      last_message {
        id
        type
        body
        direction
        created_at
      }
      last_incoming_message_at
      last_outgoing_message_at
    }
    assignation {
      ...assignation
    }
  }
  ${assignation}
`