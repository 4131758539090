export const prepareCompiledUI = (LHS: string, OP: string, RHS: string): any => {
  return {
      operand_lhs: LHS,
      operator: OP,
      operator_rhs: RHS
  }
}

export const prepareCompiled = (compiled_ui: any[]): string => {
  let compiled = "";
  compiled_ui.forEach((rule, index) => {
    if (typeof rule === "string") {
      compiled += ` ${rule} `;
    } else {
      // if operator_rhs contains spaces, wrap it in quotes
      let rhs = rule.operator_rhs;
      if (rule.operator_rhs.includes(" ")) {
        rhs = `"${rule.operator_rhs}"`;
      }      
      compiled += `(${rule.operand_lhs} ${rule.operator} ${rhs})`;
    }
  });
  return compiled;
}

export const durationsMap: Map<string, number> = new Map([
  ['NEXT_15_MINUTES', 15],
  ['NEXT_HOUR', 60],
  ['NEXT_3_HOURS', 180],
  ['NEXT_6_HOURS',  360],
  ['NEXT_DAY',  1440],
]);

export const durationsMapReversed: Map<number, string> = new Map([
  [15, 'NEXT_15_MINUTES'],
  [60, 'NEXT_HOUR'],
  [180, 'NEXT_3_HOURS'],
  [360, 'NEXT_6_HOURS'],
  [1440, 'NEXT_DAY'],
]);

export const getRandomColor = (): string => {
  let letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export const isBright = (hexColor: string): boolean => {
  hexColor = hexColor.replace("#", "");

  const r = parseInt(hexColor.substr(0, 2), 16);
  const g = parseInt(hexColor.substr(2, 2), 16);
  const b = parseInt(hexColor.substr(4, 2), 16);

  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  return luminance > 0.5;
}

export const random50colors: any[] = [];
for (let i = 0; i < 50; i++) {
  const color = getRandomColor();
  random50colors.push({ color: color, fontColor: isBright(color) ? '#111927' : '#fff' });
}


const _shouldRemove = ({ where }: any, enabled: boolean) => where.enabled._eq === enabled;

const _shouldAdd = ({ where }: any, item: any, enabled: boolean) => {
  const enabled_check = where.enabled._eq === enabled;
  const search = where._or[0].name._iregex.toLowerCase();
  const search_check = (item.name.toLowerCase().includes(search) || item.description.toLowerCase().includes(search));
  
  return enabled_check && search_check;
}

export const shouldRemove = (enabled: boolean) => {
  return (json_filter: any) => _shouldRemove(json_filter, enabled);
}

export const shouldAdd = (enabled: boolean) => {
  return (json_filter: any, item: any) => _shouldAdd(json_filter, item, enabled);
}

export const filterAdapter = (filter: any) => ({
  business_id: filter.where.business_id._eq,
  search: filter.where._or[0].name._iregex,
  enabled: filter.where.enabled._eq
});