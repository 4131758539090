//  redux
import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

//  sdk
import sdk from './sdk';

//  logrocket
import LogRocket from 'logrocket';

//  reducers
import auth from './auth';
import chatsPage from './chatsPage';
import createTemplate from './createTemplate';
import settings from './settings';
import search from './search';
import templates from './templates';
import rules from './rules';
import broadcast from './broadcast';
import quickReply from './quickReply';
import analytics from './analytics';
import customAttributes from './customAttribute';
import contacts from './contacts';
import tags from './tags';
import knowledge from './knowledge';
import mediaLibrary from './mediaLibrary';
import createWhatsappFlow from './whatsappFlow';
import meeting from './meeting';
import billing from './billing';

const reducer = combineReducers({
  // here we will be adding reducers
  sdk,
  auth,
  chatsPage,
  createTemplate,
  meeting,
  settings,
  search,
  templates,
  rules,
  broadcast,
  quickReply,
  analytics,
  customAttributes,
  contacts,
  tags,
  knowledge,
  mediaLibrary,
  createWhatsappFlow,
  billing,
});

export default configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }).concat(LogRocket.reduxMiddleware()),
})

export type RootState = ReturnType<typeof reducer>;