import SDK from "../sdk/sdk";

export interface SdkProps {
  currentScreen: string;
  sdk: SDK;
  mode: string;
}

export const enum Mode {
  HOST = 'host',
  SDK = 'sdk',
}

export interface ChatList {
  search?: string;
  pinned_chats?: boolean;
  sort_order?: "asc" | "desc";
  limit?: number;
  offset?: number;
}

export interface ChatListByStatusOpts extends ChatList {
  status: 'unanswered' | 'unassigned';
}

export interface ChatListByAgentOpts extends ChatList {
  agent_id: number;
}

export interface ChatHistoryOpts {
  contact_id: number;
  limit?: number;
  offset?: number;
}

export interface SearchOpts {
  text?: string;
  contact_id?: number;
  type: 'message' | 'contact' | 'agent';  
  direction?: 'in' | 'out';
  message_type?: 'text' | 'image' | 'video' | 'document';
  assigned_to?: number;
  from?: string;
  to?: string;
  tags?: string[];
  status?: 'unanswered' | 'unread' | 'assigned' | 'unassigned' | 'closed' | 'snoozed' | 'archived';
  limit?: number;
  offset?: number;
}

export interface ProfileOpts {  
  contact_data: {
    wa_opted_out?: boolean,
  },
  contact_profile_data: {
    image?: string,
    name?: string,
    display_name?: string,
    email?: string,
    country?: string,
    address?: string,
    language?: string
  }
}

export interface AvcAuth {
  headers: {
    Authorization: string;
    "x-hasura-allowed-roles": string[];
    "x-hasura-business-id": string;
    "x-hasura-default-role": string;
    "x-hasura-user-id": number;
  };
  success: boolean;
  user_details: User;
}

interface User {
  display_name: string;
  id: number;
  image: string;
  language: string;
  name: string;
  email: string;
}

export interface LoginOpts {
  soundNotification: boolean;
  desktopNotification: boolean;
  emailNotification: boolean;
  newMessageNotificationStrategy: {
    insideChatScreen: 'context' | 'agent' | 'team';
    outsideChatScreen: 'all' | 'agent' | 'team';
  }
}

export enum SDKEvent {
  NewMessage = 'new_message',
  ChatSelected = 'chat_selected',
  ChatAssigned = 'chat_assigned'
}

export interface InitOptions
{
  host_id: string;
  token: string;
  locale?: 'en' | 'ar';
  theme?: Theme;
  isMobile?: boolean;
  onReady?: (modules_enabled: string[]) => void;
  onError?: (error: Error) => void;
}

export interface Theme {
  tile?: string;
  logo?: string;
  primary?: string;
  meta?: string;
  palette?: ThemePallete;
  status?: ThemeStatus;
}

interface ThemeStatus {
  success: string;
  successBG: string;
  fail: string;
  failBG: string;
}

export interface ThemePallete {
  action: {
    disabledBackground: string;
    disabled: string;
  };
  primary: {
    main: string;
    secondary: string;
  };
  secondary: {
    main: string
  };
  text: {
    main: string;
    secondary: string;
  };        
  error: {
    main: string
  };
  warning: {
    main: string
  };
  primaryBG: string;
  text1BG: string;
}

export enum SdkProperties {
  user = 'user',
  token = 'token',
  host_id = 'host_id',
  modules_enabled = 'modules_enabled',
  locale = 'locale',
  theme = 'theme',
  isReady = 'isReady',
  isLoggedin = 'isLoggedin',
  isInitialized = 'isInitialized',
  isDestroyed = 'isDestroyed',
  isMobile = 'isMobile'
}