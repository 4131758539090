import { createSlice } from '@reduxjs/toolkit';
import { Dispatch } from 'redux';

// Slice
const slice = createSlice({
  name: 'quickReply',
  initialState: {
    showCreatePopup: false,
    editQuickReply: null as any,
  },
  reducers: {
    popupStatusChanged: (state, action) => {
      state.showCreatePopup = action.payload.status;
    },
    editQuickReplyChanged: (state, action) => {
      state.editQuickReply = action.payload;
    }
  },
});

export default slice.reducer;

// Actions
const { popupStatusChanged, editQuickReplyChanged } = slice.actions;

export const changePopupStatus = (status: boolean): any => (dispatch: Dispatch): void => {
  dispatch(popupStatusChanged({ status }));
}

export const changeEditQuickReply = (quickReply: any): any => (dispatch: Dispatch): void => {
  dispatch(editQuickReplyChanged(quickReply));
}