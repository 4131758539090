import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';

//  icons
import PermMediaRoundedIcon from '@mui/icons-material/PermMediaRounded';
import ChatIcon from '@mui/icons-material/Chat';
import SearchIcon from '@mui/icons-material/Search';
import AppsIcon from '@mui/icons-material/Apps';
import SettingsIcon from '@mui/icons-material/Settings';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import CreditCardIcon from '@mui/icons-material/CreditCard';

//  helpers
import { toggleButtonGroupStyle, inactiveIcon, iconLinkStyle, activeIcon, ToggleButton } from '../../helpers/chatViewLayoutStyles';
import { getAssetUrl } from '../../helpers/assets';

//  redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { changeStatus } from '../../redux/search';
import { setCurrentRoute } from '../../redux/sdk';

//  translations
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

//  components
// import { VoiceChatMenu } from './VoiceChatMenu';
import { AgentStatus } from './AgentStatus';

let prevTab = '';

export const LeftSideNav = (): JSX.Element => {
  //  hooks
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();

  //  local states
  const [currentTap, setCurrentTab] = useState<string | null>(null)

  //  redux states
  const searchOpened = useSelector((state: RootState) => state.search.opened);
  const path = useSelector((state: RootState) => state.sdk.currentRoute.path);
  const mode = useSelector((state: RootState) => state.sdk.mode);

  //  handlers
  const handleChange = (_: any, newTab: string) => {
    if (newTab === 'Search') dispatch(changeStatus(!searchOpened));
    else {
      if (searchOpened) dispatch(changeStatus(false));
      setCurrentTab(newTab);
      prevTab = newTab;
    }
  };

  const changeScreen = (newScreen: string) => {
    dispatch(setCurrentRoute(newScreen));
  }

  useEffect(() => {
    if (searchOpened) {
      setCurrentTab('Search');
    } else {
      setCurrentTab(prevTab);
    }
  }, [searchOpened]);

  useEffect(() => {
    let ignore = false;
    const path = location.pathname.split('/');
    let tab = (path[1].charAt(0).toUpperCase() + path[1].slice(1)) || 'Dashboard';
    if (!ignore)  {
      if (tab === 'Broadcast' || tab === 'Templates' || tab === 'Quick-replies' || tab === 'Rules' || tab === 'Greeting') tab = 'Apps';
      prevTab = tab;
      setCurrentTab(tab);
    }
    return () => { ignore = true; }
  },[]);

  useEffect(() => {
    if (!path) return;
    let currentTap = '';
    if (path.startsWith('/dashboard')) currentTap = 'Dashboard';
    else if (path.startsWith('/media-library')) currentTap = 'Media-library';
    else if (path.startsWith('/chat')) currentTap = 'Chat';
    else if (path.startsWith('/contacts')) currentTap = 'Contacts';
    else if (path.startsWith('/search')) currentTap = 'Search';
    else if (path.startsWith('/settings')) currentTap = 'Settings';
    else if (path.startsWith('/apps')) currentTap = 'Apps';
    else if (path.startsWith('/billing-subscription')) currentTap = 'Billing';
    setCurrentTab(currentTap);
  }, [path]);

  const toggleLanguage = ()=> {
    const newLanguage = i18next.language === "en" ? 'ar' : 'en';
    i18next.changeLanguage(newLanguage);
    localStorage.setItem('language', newLanguage);
    document.dir = i18next.dir();
  }

  return (
    <Box sx={{ width: 72, backgroundColor: "light.gray3", boxShadow: 24, zIndex: 999 }}>
      <Stack alignItems="center" justifyContent="space-between" sx={{ height: "100%", overflowY: 'auto' }}>
        <Stack alignItems={"center"} spacing={2} sx={{marginTop: 1.5}}>
          
          <Box  sx={{ display: "flex", justifyContent: "center", width: 35, height: 35, borderRadius: 1.5, border: "1px solid #E6E7EB", backgroundColor: "#F8FAFB",mt: 3 }} p={.6}>
            <img alt='avocado icon' src={getAssetUrl('mottasl_blue.png')} style={{  maxWidth: '100%' }} />
          </Box>          
          <ToggleButtonGroup orientation='vertical' sx={{ gap: 1 }} value={currentTap} exclusive>

            {/* Hidden for now */}
            {/* <ToggleButton onClick={handleChange} aria-label='Dashboard' style={toggleButtonGroupStyle} value='Dashboard'>
              <Tooltip placement={ i18next.language === 'ar' ? 'left' : 'right' } title={t('NAVBAR_TOOLTIP.REPORTS')} arrow>
                <Link to={mode === 'host' ? '/dashboard/overview' : ''} style={iconLinkStyle} onClick={() => changeScreen('/dashboard/overview')}>
                  <DashboardIcon sx={ currentTap === 'Dashboard' ? activeIcon : inactiveIcon } />
                </Link>
              </Tooltip>
            </ToggleButton> */}

            <ToggleButton onClick={handleChange} aria-label='Search' style={toggleButtonGroupStyle} value='Search'>
              <Tooltip placement={ i18next.language === 'ar' ? 'left' : 'right' } title={t('NAVBAR_TOOLTIP.SEARCH')} arrow>
                <Box style={iconLinkStyle}>
                  <SearchIcon sx={ currentTap === 'Search' ? activeIcon : inactiveIcon } />
                </Box>
              </Tooltip>
            </ToggleButton>

            <ToggleButton onClick={handleChange} aria-label='Chat' style={toggleButtonGroupStyle} value='Chat'>
              <Tooltip placement={ i18next.language === 'ar' ? 'left' : 'right' } title={t('NAVBAR_TOOLTIP.CHAT')} arrow>
                <Link to={mode === 'host' ? '/chat' : ''} style={iconLinkStyle} onClick={() => changeScreen('/chat')}>
                  <ChatIcon sx={ currentTap === 'Chat' ? activeIcon : inactiveIcon} />
                </Link>
              </Tooltip>
            </ToggleButton>

            <ToggleButton onClick={handleChange} aria-label='Media-library' style={toggleButtonGroupStyle} value='Media-library'>
              <Tooltip placement={ i18next.language === 'ar' ? 'left' : 'right' } title={t('NAVBAR_TOOLTIP.MEDIA_LIBRARY')} arrow>
                <Link to={mode === 'host' ? '/media-library' : ''} style={iconLinkStyle} onClick={() => changeScreen('/media-library')}>
                  <PermMediaRoundedIcon sx={ currentTap === 'Media-library' ? activeIcon : inactiveIcon } />
                </Link>
              </Tooltip>
            </ToggleButton>

            <ToggleButton onClick={handleChange} aria-label='Contacts' style={toggleButtonGroupStyle} value='Contacts'>
              <Tooltip placement={ i18next.language === 'ar' ? 'left' : 'right' } title={t('NAVBAR_TOOLTIP.CONTACTS')} arrow>
                <Link to={mode === 'host' ? '/contacts' : ''} style={iconLinkStyle} onClick={() => changeScreen('/contacts')}>
                  <ContactPageIcon sx={ currentTap === 'Contacts' ? activeIcon : inactiveIcon} />
                </Link>
              </Tooltip>
            </ToggleButton>

            <ToggleButton onClick={handleChange} aria-label='Apps' style={toggleButtonGroupStyle} value='Apps'>
              <Tooltip placement={ i18next.language === 'ar' ? 'left' : 'right' } title={t('NAVBAR_TOOLTIP.APPS')} arrow>
                <Link to={mode === 'host' ? '/apps' : ''} style={iconLinkStyle} onClick={() => changeScreen('/apps')}>
                  <AppsIcon sx={ currentTap === 'Apps' ? activeIcon : inactiveIcon} />
                </Link>
              </Tooltip>
            </ToggleButton>

            <ToggleButton onClick={handleChange} aria-label='Settings' style={toggleButtonGroupStyle} value='Settings'>
              <Tooltip placement={ i18next.language === 'ar' ? 'left' : 'right' } title={t('NAVBAR_TOOLTIP.SETTINGS')} arrow>
                <Link to={mode === 'host' ? '/settings/workspace/account-profile' : ''} style={iconLinkStyle} onClick={() => changeScreen('/settings/workspace/account-profile')}>
                  <SettingsIcon sx={ currentTap === 'Settings' ? activeIcon : inactiveIcon} />
                </Link>
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>

          {/* <IconButton id='open-copilot'>            
            <Tooltip placement={ i18next.language === 'ar' ? 'left' : 'right' } title={t('NAVBAR_TOOLTIP.COPILOT')} arrow>
              <SmartToyIcon sx={inactiveIcon} />
            </Tooltip>
          </IconButton> */}
        </Stack>

        <Stack alignItems="center" gap={2.5} py={2.5}>
          {/* <VoiceChatMenu /> */}
          <ToggleButtonGroup orientation='vertical' sx={{ gap: 1 }} value={currentTap} exclusive>
            <ToggleButton onClick={handleChange} aria-label='Billing' style={toggleButtonGroupStyle} value='Billing'>
              <Tooltip placement={ i18next.language === 'ar' ? 'left' : 'right' } title={t('BILLING_SUBSCRIPTION.TITLE')} arrow>
                <Link to={mode === 'host' ? '/billing-subscription' : ''} style={iconLinkStyle} onClick={() => changeScreen('/billing-subscription')}>
                  <CreditCardIcon sx={ currentTap === 'Billing' ? activeIcon : inactiveIcon} />
                </Link>
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
          
          <Tooltip title={i18next.language == "ar" ? "اللغة الإنجليزية" : "English Language"} placement="right" arrow>
            <Button onClick={toggleLanguage}>
              <Avatar alt="Language" src={i18next.language === "ar" ? getAssetUrl('saudi-flag.png') : getAssetUrl('english.webp')} sx={{ width:'24px', height:'24px' }} />
            </Button>
          </Tooltip>
          
          <Tooltip title={t('NAVBAR_TOOLTIP.NOTIFICATIONS')} placement="right" arrow>
            <Button component={Link} to={mode === 'host' ? '/settings/account/notifications' : ''} onClick={() => changeScreen('/settings/account/notifications')}>
              <NotificationsNoneOutlinedIcon color='secondary' />
            </Button>
          </Tooltip>
          
          <AgentStatus />
        </Stack>
      </Stack>
    </Box>
  );
}