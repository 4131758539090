import React, { MouseEvent, useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';

//  utils
import i18next from 'i18next';

//  helpers
import { getAssetUrl } from '../../helpers/assets';

const langs = {
  'ar': {
    text: 'عربى',
    flag: getAssetUrl('arabic.webp')
  },
  'en': {
    text: 'English',
    flag: getAssetUrl('english.webp')
  }
}

export const LanguageButton = (): JSX.Element => {

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [lang, setLang] = useState('en');
  
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const changeLang = (lang: string) => {
    setLang(lang);
    i18next.changeLanguage(lang);
    document.dir = i18next.dir();
    localStorage.setItem('language', lang)
    handleClose();
  }

  return (
    <>
      <Button onClick={handleClick} sx={{ gap: 1.5, color: 'text.secondary', p: 1 }}>
        <img src={langs[lang].flag} width={30} />
        {langs[lang].text}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{ '.MuiPaper-root': { boxShadow: 9, borderRadius: 2, minWidth: 150 } }}
      >
        { Object.entries(langs).map(([code, lang]) => (
          <MenuItem key={code} onClick={() => changeLang(code)} sx={{ gap: 1.5 }}>
            <img src={lang.flag} width={30} />
            {lang.text}
          </MenuItem>
        )) }
      </Menu>
    </>
  );
}