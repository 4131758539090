import React from 'react';

interface TabPanelProps {
  child: React.ReactNode;
  dir: string;
  value: number;
  currentView: number;
}

export const TabPanel = ({ child, dir, value, currentView }: TabPanelProps) => {
  return (
    <div role="tabpanel" dir={dir} hidden={value !== currentView}>
      { child }
    </div>
  );
}
