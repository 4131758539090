import gql from 'graphql-tag'

export default gql `
  fragment message on core_message {
    id
    type
    body
    direction
    level
    is_msg_read
    parent_message: message {
      id
      type
      body
      direction
      message_media {
        id
        filename
        filename_url
      }
    }
    sent_by_agent: agent {
      id
      platform_id
      contact_profiles {
        name
        display_name
        image
      }
    }
    message_mentions {
      contact_id
      id
      resolved
      contact {
        id
        contact_profiles {
          display_name
          email
          image
          role
        }
        contact_meta {
          status
          last_seen
        }
      }
    }
    media: message_media {
      id
      mime_type
      filename_url
      caption
      filename
    }
    statuses: message_statuses_all(
      order_by: { created_at: asc } 
    ) {
      status
      created_at
      error
      error_code
    }
    created_at
  }
`