import React from 'react';

//  components
import { Lazy } from '../../components/Common/Lazy';

// redux
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

//  Lazy Pages
const Conversations = React.lazy(() => import('../../pages/Analytics/Conversations'));
const Overview = React.lazy(() => import('../../pages/Analytics/Overview'));
const CSATreports = React.lazy(() => import('../../pages/Analytics/CSAT'));
const Agents = React.lazy(() => import('../../pages/Analytics/Agents'));
const Teams = React.lazy(() => import('../../pages/Analytics/Teams'));
const Error = React.lazy(() => import('../../pages/Error'));

export const SdkRouter = () => {
  
  const path = useSelector((state: RootState) => state.sdk.currentRoute.path);
  
  return (
    path === '/dashboard/overview' ? <Lazy component={<Overview />} /> :
    path === '/dashboard/conversations' ? <Lazy component={<Conversations />} /> :
    path === '/dashboard/reports' ? <Lazy component={<CSATreports />} /> :
    path === '/dashboard/agents' ? <Lazy component={<Agents />} /> :
    path === '/dashboard/teams' ? <Lazy component={<Teams />} /> :
    <Lazy component={<Error type={404} />} />
  )
}
