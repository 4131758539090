import { createSlice } from '@reduxjs/toolkit';
import { Dispatch } from 'redux';

const slice = createSlice({
  name: 'billing',
  initialState: {
    accountBlocked: false,
  },
  reducers: {
    accountBlockedChanged: (state, action) => {
      state.accountBlocked = action.payload;
    }
  },
});

export default slice.reducer;

// Actions
const { accountBlockedChanged } = slice.actions;

export const changeAccountBlocked = (status: boolean): any => (dispatch: Dispatch): void => {
  dispatch(accountBlockedChanged(status));
}
