import React from 'react';
import { Button, FormHelperText, Stack, TextField, Typography } from '@mui/material';

// Phone Input
import PhoneInput from '@waleed-hesham/react-phone-input-2';

//  utils
import { Trans, useTranslation } from 'react-i18next';

//  validations
import * as yup from 'yup';
import { useForm,  Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

const inputProps = {
  disableUnderline: true,
  sx: { borderRadius: 2.5, backgroundColor: 'transparent !important', border: '1px solid', borderColor: 'light.gray8' }
};

export const PhoneNumber = ({ setTab, detailsRef }): JSX.Element => {
  const { t } = useTranslation();

  const schema = yup.object().shape({
    phone: yup.string().required(),
    email: yup.string().email().required()
  });

  const { control, handleSubmit, formState: { errors } } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  });

  const next = (payload: any) => {
    detailsRef.current.identity = payload.phone;
    detailsRef.current.email = payload.email;
    setTab(6);
  }

  return (
    <Stack alignItems="center" px="1px">
      <Typography fontSize={{md:14, xs:12, sm:13}} fontWeight={500} textAlign="center" sx={{ color: 'text.secondary', mb: {md:4, xs:2} }}>
        <Trans i18nKey={'LOGIN.PHONE_NUMBER_SUBTITLE'} components={[<br />]} />
      </Typography>
      <Controller name='phone' control={control} render={({ field: { value, onChange, onBlur } }) => (
        <PhoneInput
          inputProps={{ 'aria-label': "phone", name: "phoneNumber" }}
          data-testid='contact-number'
          specialLabel=''
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          country={'eg'}
          placeholder="123 456 789"
          inputStyle={{ paddingLeft: 60, borderRadius: 8, width:"100%", height: "47px", direction: 'ltr' }}
          dropdownStyle={{ width: 270, maxHeight: 150, top: 32 }}
          buttonStyle={{ borderRadius: '8px 0 0 8px', height: '45px', border: 'none', top: '1px', left: '1px' }}
        />
      )} />
      { errors.phone && <FormHelperText data-testid="phone-error" sx={{ color: 'error.main', width: '100%', boxSizing: 'border-box', px: '14px' }}>{ errors.phone.message }</FormHelperText> }
      <Controller name='email' control={control} render={({ field: { value, onChange, onBlur } }) => (
        <TextField label={t('LOGIN.EMAIL_ADDRESS')} type="email" name='email' size='small' variant="filled" sx={{ mt: 1.5 }} InputProps={inputProps} fullWidth value={value || ''} onChange={onChange} onBlur={onBlur} error={Boolean(errors.email)} helperText={errors.email?.message} />
      )} />
      <Button onClick={handleSubmit(next)} disableElevation variant="contained" sx={{ mt: 2, px: 3.5, borderRadius: 2, fontSize: 15, fontWeight: 600 }}>{t('COMMON.CONTINUE')}</Button>
    </Stack>
  );
}