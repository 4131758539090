import React from 'react';
import { Route, Routes } from 'react-router-dom';

//  components
import { Lazy } from '../../components/Common/Lazy';

//  pages
const AccountProfile = React.lazy(() => import('./AccountProfile'));
const Users = React.lazy(() => import('./Users'));
const Teams = React.lazy(() => import('./Teams'));
const Error = React.lazy(() => import('../../pages/Error'));

export const WorkSpace = (): JSX.Element => {
    return (
        <Routes>
            <Route path='/account-profile' element={<Lazy component={<AccountProfile />} />} />
            <Route path='/users' element={<Lazy component={<Users />} />} />
            <Route path='/teams' element={<Lazy component={<Teams />} />} />
            <Route path="*" element={<Lazy component={<Error type={404} />} />} />
        </Routes>
    )
}
