import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, Button, ButtonBase, Stack, Typography } from '@mui/material';

import toast, { Toast, Toaster } from 'react-hot-toast';

// gql
import { useSubscription } from '@apollo/client';
import { NEW_MESSAGE_AGENT_CONTEXT, NEW_MESSAGE_GLOBAL_CONTEXT, NEW_MESSAGE_TEAM_CONTEXT } from '../../graphql/subscriptions/chats';

// Axios
import axios from 'axios';

//  helpers
import { getAssetUrl } from '../../helpers/assets';
import { getMessageText } from '../../helpers/chat';
import { overflowTextByLines } from '../../helpers/styles';
import { publish } from '../../helpers/events';

//  utils
import { useTranslation } from 'react-i18next';

//  redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { setCurrentRoute } from '../../redux/sdk';
import { handleGetToken } from '../../firebase';

const ToastNotification = ({ T, new_message }): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const mode = useSelector((state: RootState) => state.sdk.mode);

  const goToChat = (T: Toast, message: any) => {
    toast.dismiss(T.id);
    dispatch(setCurrentRoute('/chat', { chatId: message.contact.contact_id }));
    if (mode === 'host') navigate(`/chat?chatId=${message.contact.contact_id}`);
  }

  return (
    <Stack flexDirection="row" alignItems="center" sx={{
      backgroundColor: 'light.main',
      boxShadow: " 0 0 0 0px rgb(59 130 246 / 0.5),  0 0 0 1px #FFF, 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
      borderRadius: '0.5rem',
      width: 450,
      opacity: T.visible ? 1 : 0,
      transition: "opacity 100ms ease-in-out"
    }}>
      <ButtonBase sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: "center", gap: 1.5, flex: 1, p: 2, borderEndStartRadius: '0.5rem', borderStartStartRadius: '0.5rem' }} onClick={() => goToChat(T, new_message.new_message[0])}>
        <Avatar src={new_message.new_message[0].contact.details[0].image || getAssetUrl('broken-image.png')} alt={new_message.new_message[0].contact.details[0].display_name} />
        <Stack gap={0.5}>
          <Typography sx={{ fontSize: '0.875rem', fontWeight: 500, lineHeight: '1.25rem', color: 'rgb(17 24 39)', textAlign: 'start' }}>{new_message.new_message[0].contact.details[0].display_name}</Typography>
          <Typography sx={{ fontSize: '0.875rem', lineHeight: '1.25rem', color: 'rgb(107 114 128)', textAlign: 'start', ...overflowTextByLines(1) }}>{getMessageText(new_message.new_message[0])}</Typography>
        </Stack>
      </ButtonBase>
      <Button variant='text' sx={{ p: 2, height: '100%', borderInlineStart: '1px solid rgb(229 231 235)', borderStartEndRadius: '0.5rem', borderEndEndRadius: '0.5rem', fontSize: '0.875rem', fontWeight: 500, lineHeight: '1.25rem' }} onClick={() => toast.dismiss(T.id)}>{t('COMMON.CLOSE')}</Button>
    </Stack>
  )
}

export const NewMsgNotification = (): JSX.Element => {
  const dispatch = useDispatch();
  
  const currentRoute = useSelector((state: RootState) => state.sdk.currentRoute);
  const viewAs = useSelector((state: RootState) => state.chatsPage.viewAs);
  const chatListType = useSelector((state: RootState) => state.chatsPage.chatListType);
  const soundNotifications = useSelector((state: RootState) => state.settings.soundNotifications);
  const insideChatNotifications = useSelector((state: RootState) => state.settings.insideChat);
  const outsideChatNotifications = useSelector((state: RootState) => state.settings.outsideChat);
  
  const notTeamChats = isNaN(parseInt(chatListType));
  const insideChat = currentRoute.path === '/chat';

  const firstRenderGlobal = useRef(true);
  const firstRenderAgent = useRef(true);
  const firstRenderTeam = useRef(true);


  const serviceKey: string = 'AAAAI2ZJcDY:APA91bGyp02-DRB8zq7F9L4rwJ6m3EYcwVx0CJUXYlr0ZDiAjAmc0DvRjCT4CF2a8t5g18hCGXR32veLQM6F_M5Qd0ZCOx25jvapg5FE1CcXgJNzeN5i96BlFWC6yrNtThp5yLGKxRNq';
  const playNotificationAudio = useCallback(
    () => {
      const audio = new Audio(getAssetUrl('whatsapp_notification.mp3'));
      audio.addEventListener('error', (error) => {
        console.error('Error loading audio file:', error);
      });
      audio.load();
      audio.play();
    },
    [],
  );

  const showOsNotification = useCallback(
    (message) => {
      Notification.requestPermission().then(perm => {
        if(perm === 'granted') {
          navigator.serviceWorker.ready.then(function(registration) {
            registration.showNotification(message?.new_message[0]?.body?.text?.body);
          });

          const firebaseTokenPromise = handleGetToken();
          firebaseTokenPromise.then(newToken => {
            // Assign the value to newTokenArr inside the then block
            console.log("newToken111 : ", newToken);

            let deviceToken = newToken;

            // Now, you can send the axios.post request inside this block
            axios.post("https://fcm.googleapis.com/fcm/send", {
              "notification": {
                "title": message.new_message[0].contact.details[0].display_name,
                "body": message?.new_message[0]?.body?.text?.body
              },
              "to": deviceToken
            }, {
              headers: {
                Authorization: `key=${serviceKey}`,
                "Content-Type": "application/json"
              } 
            });
          });

        } else {
          console.log('Permission for notifications denied');
        }
        

         
    });
    },
    [],
  )


  const { data: new_message_global } = useSubscription(NEW_MESSAGE_GLOBAL_CONTEXT, {
    variables: {
      business_id: window.business_id
    }
  });

  const { data: new_message_agent } = useSubscription(NEW_MESSAGE_AGENT_CONTEXT, {
    variables: {
      business_id: window.business_id,
      agent_id: !insideChat || (insideChat && (insideChatNotifications === 'AGENT' || (insideChatNotifications === 'CONTEXT' && chatListType === 'assigned'))) ? localStorage.getItem('avc-user_id') : viewAs?.id
    },
    skip: !((insideChat && (insideChatNotifications === 'AGENT' || (insideChatNotifications === 'CONTEXT' && (chatListType === 'assigned' || chatListType === 'inbox')))) || (!insideChat && outsideChatNotifications === 'AGENT'))
  });

  const { data: new_message_team } = useSubscription(NEW_MESSAGE_TEAM_CONTEXT, {
    variables: {
      business_id: window.business_id,
      team_id: chatListType
    },
    skip: !(insideChat && (insideChatNotifications === 'CONTEXT' && !notTeamChats))
  });

  useEffect(() => {
    if (!new_message_global) {
      firstRenderGlobal.current = true;
      return;
    }
    if (firstRenderGlobal.current) {
      firstRenderGlobal.current = false;
      return;
    }
    if (!new_message_global.new_message[0]) return;
    if (insideChat && new_message_global.new_message[0].contact.contact_id == currentRoute.params.chatId) {
      publish('NEW_MESSAGE_IN_CHAT', new_message_global.new_message[0]);
      return;
    }

    console.log('new_message_global', new_message_global);
    
    if (!((insideChat && (insideChatNotifications === 'ALL' || (insideChatNotifications === 'CONTEXT' && (chatListType === 'chat-queue' || chatListType === 'unanswered' || chatListType === 'unassigned')))) || (!insideChat && outsideChatNotifications === 'ALL'))) return;
    
    if (soundNotifications[2]) {
      showOsNotification(new_message_global);
    }

    if (soundNotifications[1] || (soundNotifications[0] && (new_message_global.new_message[0].contact.chat_status === 'unassigned' || (new_message_global.new_message[0].contact.chat_status === 'assigned' && new_message_global.new_message[0].contact.assignation?.[0]?.agent_id == localStorage.getItem('avc-user_id'))))) {
      playNotificationAudio();
    }

    toast.custom((T) => <ToastNotification T={T} new_message={new_message_global} />, {
      id: new_message_global.new_message[0].contact.contact_id
    });
  }, [new_message_global]);

  useEffect(() => {
    if (!new_message_agent) {
      firstRenderAgent.current = true;
      return;
    }
    if (firstRenderAgent.current) {
      firstRenderAgent.current = false;
      return;
    }
    if (!new_message_agent.new_message[0]) return;
    if (new_message_agent.new_message[0].contact.contact_id == currentRoute.params.chatId) {
      publish('NEW_MESSAGE_IN_CHAT', new_message_agent.new_message[0]);
      return;
    }

    console.log('new_message_agent', new_message_agent);

    if (soundNotifications[2]) {
      showOsNotification(new_message_global);
    }

    if (soundNotifications[1] || (soundNotifications[0] && (new_message_agent.new_message[0].contact.chat_status === 'unassigned' || (new_message_agent.new_message[0].contact.chat_status === 'assigned' && new_message_agent.new_message[0].contact.assignation?.[0]?.agent_id == localStorage.getItem('avc-user_id'))))) {
      playNotificationAudio();
    }

    toast.custom((T) => <ToastNotification T={T} new_message={new_message_agent} />, {
      id: new_message_agent.new_message[0].contact.contact_id
    });
  }, [new_message_agent]);

  useEffect(() => {
    if (!new_message_team) {
      firstRenderTeam.current = true;
      return;
    }
    if (firstRenderTeam.current) {
      firstRenderTeam.current = false;
      return;
    }
    if (!new_message_team.new_message[0]) return;
    if (new_message_team.new_message[0].contact.contact_id == currentRoute.params.chatId) {
      publish('NEW_MESSAGE_IN_CHAT', new_message_team.new_message[0]);
      return;
    }

    console.log('new_message_team', new_message_team);

    if (soundNotifications[2]) {
      showOsNotification(new_message_global);
    }

    if (soundNotifications[1] || (soundNotifications[0] && (new_message_team.new_message[0].contact.chat_status === 'unassigned' || (new_message_team.new_message[0].contact.chat_status === 'assigned' && new_message_team.new_message[0].contact.assignation?.[0]?.agent_id == localStorage.getItem('avc-user_id'))))) {
      playNotificationAudio();
    }

    toast.custom((T) => <ToastNotification T={T} new_message={new_message_team} />, {
      id: new_message_team.new_message[0].contact.contact_id
    });
  }, [new_message_team]);

  useEffect(() => {
    firstRenderAgent.current = true;
    firstRenderTeam.current = true;
  }, [viewAs, chatListType]);

  return (
    <Toaster
      position="top-right"
      reverseOrder={false}
      toastOptions={{
        duration: 1000 * 5
      }}
    />
  );
}