import { createSlice } from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
import { Item, Section } from '../helpers/ChatTypes';
import { Button } from '../helpers/ChatTypes';
import { ChannelMessage } from '../models/Chat';
import { MediaItem } from '../models/MediaLibrary';


// Initial button, because if the user is using interactive button
// He must has at least one button
let buttons: Button[] = [
  {
    index: 1,
    title: '',
    payload: ''
  }
]

// Slice
const slice = createSlice({
  name: 'chatsPage',
  initialState: {
    contactInfo: false,
    showChatsTeams: true,
    showNewChat: false,
    quickMessagesAnchor: null as any,
    showInteractiveList: false,
    sections: [] as Section[],
    buttons,
    selectedSection: -1 as number,
    numOfInterativeButtons: 1 as number,
    previewAttachment: null as MediaItem | null,
    chatListType: 'inbox',
    viewAs: null as any,
    showGoDownButton: false,
    replyMessage: null as any,
    quickReplyMessage: null as ChannelMessage | null,
    goToMessage: null as any,
    is_older_than_24h: true
  },
  reducers: {
    contactInfoChanged: (state, action) => {
      state.contactInfo = action.payload;
    },
    setShowQuickMessages: (state, action) => {
      state.quickMessagesAnchor = action.payload;
    },
    showChatsTeamsChanged: (state, action) => {
      state.showChatsTeams = action.payload.showChatsTeams;
    },
    showNewChatChanged: (state) => {
      state.showNewChat = !state.showNewChat;
    },
    setShowInteractiveList: (state) => {
      state.showInteractiveList = !state.showInteractiveList;
    },
    setSections: (state, action) => {
      const newSection = action.payload;
      state.sections = [...state.sections, newSection];
      setSelectedSection(state.sections[state.sections.length -1]);
    },
    setItems: (state, action) => {
      const { item } = action.payload;
      const sectionIndex: number = action.payload.sectionIndex || 0;

      const newSections = [...state.sections];

      const newItemsArray =
        (newSections[sectionIndex]?.items && [...newSections[sectionIndex].items]) || [];

      if (newItemsArray.length < 3) {
        newItemsArray.push(item);

        newSections[sectionIndex] = {
          ...newSections[sectionIndex],
          items: newItemsArray,
        };

        return {
          ...state,
          sections: newSections,
        };
      }
    },
    editItems: (state, action) => {
      const { sectionIndex, itemIndex, updatedItem } = action.payload;

      const updatedSections = state.sections.map((section: Section, sIndex: number) => {
        if (sIndex === sectionIndex) {
          const updatedItems = section.items.map((item: Item, iIndex: number) => {
            if (iIndex === itemIndex) {
              return {
                ...item,
                ...updatedItem,
              };
            }
            return item;
          });

          return {
            ...section,
            items: updatedItems,
          };
        }
        return section;
      });

      return {
        ...state,
        sections: updatedSections,
      };
    },
    deleteItem: (state, action) => {
      const { sectionIndex, itemToDelete } = action.payload;

      const newSections = [...state.sections];

      const sectionToUpdate = newSections[sectionIndex];
      if (!sectionToUpdate) {
        return state; // Section not found, return current state
      }

      const newItemsArray = sectionToUpdate.items.filter(
        (item, index) => index !== itemToDelete
      );

      newSections[sectionIndex] = {
        ...sectionToUpdate,
        items: newItemsArray,
      };

      return {
        ...state,
        sections: newSections,
      };
    },
    setSelectedSection: (state, action) => {
      const { sectionIndex } = action.payload;
      state.selectedSection = sectionIndex;
    },
    addNewButton: (state, action) => {
      if (state.buttons.length < 3) {
        state.buttons.push(action.payload.button);
        console.log('the new button is ', action.payload.button);

      }
    },
    deleteButton: (state, action): any => {
      state.buttons.splice(action.payload.index, 1);
      // console.log(state.buttons);
    },
    updateButton: (state, action) => {
      const { index, button } = action.payload;
      state.buttons[index] = button;
    },
    previewAttachmentChanged: (state, action) => {
      state.previewAttachment = action.payload;
    },
    chatListTypeChanged: (state, action) => {
      state.chatListType = action.payload;
    },
    viewAsChanged: (state, action) => {
      state.viewAs = action.payload;
    },
    showGoDownButtonChanged: (state, action) => {
      state.showGoDownButton = action.payload;
    },
    replyMessageChanged: (state, action) => {
      state.replyMessage = action.payload;
    },
    quickReplyMessageChanged: (state, action) => {
      state.quickReplyMessage = action.payload;
    },
    goToMessageChanged: (state, action) => {
      state.goToMessage = action.payload;
    },
    olderThan24HourChanged: (state, action) => {
      state.is_older_than_24h = action.payload;
    }
  },
});

export default slice.reducer;

// Actions
const {
  contactInfoChanged,
  setShowQuickMessages,
  showChatsTeamsChanged,
  showNewChatChanged,
  setShowInteractiveList,
  setSections,
  setItems,
  editItems,
  deleteItem,
  setSelectedSection,
  addNewButton,
  deleteButton,
  updateButton,
  previewAttachmentChanged,
  chatListTypeChanged,
  viewAsChanged,
  showGoDownButtonChanged,
  replyMessageChanged,
  quickReplyMessageChanged,
  goToMessageChanged,
  olderThan24HourChanged
} = slice.actions;

export const toggleContactInfo = (contactInfo: boolean): any => (dispatch: Dispatch): void => {
  dispatch(contactInfoChanged(contactInfo));
}

export const changeShowQuickMessages = (anchoeEl: any): any => (dispatch: Dispatch): void => {
  dispatch(setShowQuickMessages(anchoeEl));
}

export const changeShowChatsTeams = (showChatsTeams: boolean): any => (dispatch: Dispatch): void => {
  dispatch(showChatsTeamsChanged({ showChatsTeams }));
}

export const toggleShowNewChat = (): any => (dispatch: Dispatch): void => {
  dispatch(showNewChatChanged());
}

export const updateShowInteractiveList = (): any => (dispatch: Dispatch): void => {
  dispatch(setShowInteractiveList());
}

export const updateSections = (section: Section): any => (dispatch: Dispatch): void => {
  dispatch(setSections(section));
}

export const addItemToSection = (sectionIndex: number, item: Item): any => (dispatch: Dispatch): void => {
  dispatch(setItems({ sectionIndex, item }));
}

export const updateSelectedSection = (sectionIndex: number): any => (dispatch: Dispatch): void => {
  dispatch(setSelectedSection({ sectionIndex }));
}

export const AddNewButton = (button: Button): any => (dispatch: Dispatch): void => {
  dispatch(addNewButton({ button }));
}

export const DeleteButton = (index: number): any => (dispatch: Dispatch): void => {
  dispatch(deleteButton({ index }));
}

export const UpdateButton = (index: number, button: Button): any => (dispatch: Dispatch): void => {
  dispatch(updateButton({ index, button }));
}

export const DeleteItem = (sectionIndex: number, itemToDelete: number): any => (dispatch: Dispatch): void => {
  dispatch(deleteItem({ sectionIndex, itemToDelete }));
}

export const UpdateItem = (sectionIndex: number, itemIndex: number, updatedItem: Item): any => (dispatch: Dispatch): void => {
  dispatch(editItems({ sectionIndex, itemIndex, updatedItem }));
}

export const setPreviewAttachment = (attachment: any): any => (dispatch: Dispatch): void => {
  dispatch(previewAttachmentChanged(attachment));
}

export const setChatListType = (newType: string): any => (dispatch: Dispatch): void => {    
  dispatch(chatListTypeChanged(newType));
}

export const setViewAs = (agent: any): any => (dispatch: Dispatch): void => {    
  dispatch(viewAsChanged(agent));
}

export const setShowGoDownButton = (status: boolean): any => (dispatch: Dispatch): void => {    
  dispatch(showGoDownButtonChanged(status));
}

export const setReplyMessage = (message: any): any => (dispatch: Dispatch): void => {    
  dispatch(replyMessageChanged(message));
}

export const setQuickReplyMessage = (message: any): any => (dispatch: Dispatch): void => {    
  dispatch(quickReplyMessageChanged(message));
}

export const setGoToMessage = (message_id: any): any => (dispatch: Dispatch): void => {    
  dispatch(goToMessageChanged(message_id));
}

export const setOlderThan24Hour = (status: boolean): any => (dispatch: Dispatch): void => {    
  dispatch(olderThan24HourChanged(status));
}