import { createSlice } from '@reduxjs/toolkit';
import { Dispatch } from 'redux';

const slice = createSlice({
  name: 'tags',
  initialState: {
    showCreatePopup: false,
    editTag: null as any,
  },
  reducers: {
    createStatusChanged: (state, action) => {
      state.showCreatePopup = action.payload;
    },
    editTagChanged: (state, action) => {
      state.editTag = action.payload;
    }
  },
});

export default slice.reducer;

// Actions
const { createStatusChanged, editTagChanged } = slice.actions;

export const changeCreateStatus = (status: boolean): any => (dispatch: Dispatch): void => {
  dispatch(createStatusChanged(status));
}

export const setEditTag = (tag: any): any => (dispatch: Dispatch): void => {
  dispatch(editTagChanged(tag));
}