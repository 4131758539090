import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import SDK from './sdk/sdk';
import { inject } from '@vercel/analytics';
import { swDev } from './swDev';
inject();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const sdk = new SDK();
sdk.authorize();

const initial_path =
  window.location.pathname !== '/' ? window.location.pathname :
  window.screen.width <= 786 ? '/chat' :
  '/chat';
  // '/dashboard/overview';

root.render(
  // <React.StrictMode>
    <App currentScreen={initial_path} mode='host' sdk={sdk} />
  // </React.StrictMode>
);
swDev();

export { default as SDK } from './sdk/sdk';
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// serviceWorkerRegistration.register();