/*firebase daniel start*/
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyDHZA4Vgtog7UD3ACXNekqb4ikhJvnRRFU",
  authDomain: "avc-notifications-f8df6.firebaseapp.com",
  projectId: "avc-notifications-f8df6",
  storageBucket: "avc-notifications-f8df6.appspot.com",
  messagingSenderId: "152039944246",
  appId: "1:152039944246:web:f80f2465244a707afd6897",
};
const fapp = initializeApp(firebaseConfig);
const messaging = getMessaging(fapp);

export const handleGetToken = async () => {
  const token = await getToken(messaging, {
    vapidKey:
      "BDtKeaZXZra7rdJiz6YMKeCWPyFrxhSOh6RP5JTnwY__42fiYWhgyBUyW5G-TqeLdzUWtZ-Cs5VhUxTN6V04at4",
  });  

  return token;
};

onMessage(messaging, (payload) => {
  console.log("Message received. ", payload);
  // ...
});

/*firebase daniel end*/
