import { createSlice } from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
import { MediaItem } from '../models/MediaLibrary';

// Slice
const slice = createSlice({
  name: 'mediaLibrary',
  initialState: {
    selectedFile: null as File | null,
    uploadingFile: {
      file: null as File | null,
      title: '',
      caption: '',
    },
    mediaDetails: null as MediaItem | null,
    mediaView: null as MediaItem | null,
  },
  reducers: {
    selectedFile: (state, action) => {
      state.selectedFile = action.payload.file;
    },
    uploadingFile: (state, action) => {
      state.uploadingFile.file = action.payload.file;
    },
    fileData: (state, action) => {
      state.uploadingFile.title = action.payload.title;
      state.uploadingFile.caption = action.payload.caption;
    },
    mediaDetails: (state, action) => {
      state.mediaDetails = action.payload.media;
    },
    mediaView: (state, action) => {
      state.mediaView = action.payload.media;
    }
  },
});

export default slice.reducer;

const { selectedFile, uploadingFile, fileData, mediaDetails, mediaView } = slice.actions

// Actions
export const selectFile = (file: File | null): any => (dispatch: Dispatch): void => {
  dispatch(selectedFile({ file }));
}

export const uploadFile = (file: File | null): any => (dispatch: Dispatch): void => {
  dispatch(uploadingFile({ file }));
}

export const setFileData = (title: string, caption: string): any => (dispatch: Dispatch): void => {
  dispatch(fileData({ title, caption }));
}

export const setMediaDetails = (media: MediaItem | null): any => (dispatch: Dispatch): void => {
  dispatch(mediaDetails({ media }));
}

export const setMediaView = (media: MediaItem | null): any => (dispatch: Dispatch): void => {
  dispatch(mediaView({ media }));
}
