import React from 'react';

//  components
import { Lazy } from '../../components/Common/Lazy';

// redux
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

//  Lazy Pages
const AccountProfile = React.lazy(() => import('../../pages/Settings/AccountProfile'));
const Users = React.lazy(() => import('../../pages/Settings/Users'));
const Teams = React.lazy(() => import('../../pages/Settings/Teams'));
const Channels = React.lazy(() => import('./DevTools/Channels'));
const MessageLogs = React.lazy(() => import('./DevTools/MessageLogs'));
const Webhooks = React.lazy(() => import('./DevTools/Webhooks_APIs'));
const Profile = React.lazy(() => import('./Account/Profile'));
const Notifications = React.lazy(() => import('./Account/Notifications'));
const Error = React.lazy(() => import('../../pages/Error'));

export const SdkRouter = () => {
  //  redux states
  const path = useSelector((state: RootState) => state.sdk.currentRoute.path);
  
  return (
    path === '/settings/workspace/account-profile' ? <Lazy component={<AccountProfile />} /> :
    path === '/settings/workspace/users' ? <Lazy component={<Users />} /> :
    path === '/settings/workspace/teams' ? <Lazy component={<Teams />} /> :
    path === '/settings/devtools/channels' ? <Lazy component={<Channels />} /> :
    path === '/settings/devtools/message-logs' ? <Lazy component={<MessageLogs />} /> :
    path === '/settings/devtools/webhooks' ? <Lazy component={<Webhooks />} /> :
    path === '/settings/account/profile' ? <Lazy component={<Profile />} /> :
    path === '/settings/account/notifications' ? <Lazy component={<Notifications />} /> :
    <Lazy component={<Lazy component={<Error type={404} />} />} />
  )
}
